// libraries.
import { Button, Modal } from 'react-bootstrap';
// import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// hooks.
import {
  MenuActions,
  modalOptions,
  useMenuContext,
} from 'context/menu.context';

export const ModalCtaUseTerminal = () => {
  const navigate = useNavigate();
  const { _menuId } = useParams();
  const [{ modal }, dispatch] = useMenuContext();

  const showModal = modal.includes(modalOptions['cta-use-terminal']);
  const closeModal = () => dispatch(MenuActions.closeModal());
  const { t } = useTranslation();

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      className="mobile-modal"
    >
      <Modal.Header closeButton className="px-4">
        <Modal.Title className="lead fw-bold">
          {t('Comienza a usar tu Punto de Venta')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <p className="pb-3">
          🎉 {t('¡Genial! Has creado tu primer producto')}.
        </p>
        <p>
          {t('Meniu es la App perfecta para gestionar y controlar tus ventas')}.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column gap-2 px-4">
        <Button
          variant="dark"
          className="w-100 fw-bold bg-dark"
          onClick={() => {
            closeModal();
            navigate(`/menu-editor/${_menuId}?tab=terminal`);
          }}
        >
          {t('Haz una venta de prueba')}
        </Button>
        <Button
          variant="light"
          className="w-100 fw-bold bg-light"
          onClick={() => {
            window.open(`${process.env.NX_CLIENT_URL_}/${_menuId}`, '_blank');
          }}
        >
          {t('Ver menú en línea')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
