// context
import { TipActions, useTipContext } from '../context';

interface Props {
  percentage: number;
}

/**
 * Percentage option tip button
 * @param percentage is the number of the option
 * @returns component
 */
export const PercentageTip = ({ percentage }: Props) => {
  const [{ percentageSelected }, dispatch] = useTipContext();
  const isTheSamePercentage = percentageSelected === percentage;

  const setTipByPercentageSelected = () => {
    dispatch(TipActions.setPercentageSelected(percentage));
    dispatch(TipActions.setTipValues());
  };

  return (
    <button
      type="button"
      className={`fw-semibold p-2 rounded-2 flex-fill text-center border-0 ${
        isTheSamePercentage ? 'custom-shadow bg-white' : ''
      }`}
      onClick={setTipByPercentageSelected}
    >
      {percentage}%
    </button>
  );
};
