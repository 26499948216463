import { useState } from 'react';
import { Container, Card } from 'react-bootstrap';

import { useAdminPromoters } from 'apiClient/api';
import { Button, Table } from 'react-bootstrap';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';

export const Ambassadors = () => {
  const { data: promoters } = useAdminPromoters();

  const [showReferrals, setShowReferrals] = useState<Record<string, boolean>>(
    {}
  );

  const toggleShowReferrals = (id: string) => {
    setShowReferrals({
      ...showReferrals,
      [id]: !showReferrals[id],
    });
  };

  if (!promoters) return null;

  return (
    <section>
      <Container fluid>
        <h1 className="my-4">Referidos</h1>
        <Card className="border-0 shadow-sm">
          <Card.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th>Promoter ID</th>
                  <th>Nombre</th>
                  <th>No. de referidos</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {promoters?.map((promoter: any) => (
                  <>
                    <tr key={promoter._id}>
                      <td>{promoter.promoterShortId}</td>
                      <td>{promoter.name}</td>
                      <td>{promoter.referrals.length}</td>
                      <td>
                        <Button
                          onClick={() => toggleShowReferrals(promoter._id)}
                          variant="link"
                          className="p-0 fw-semibold text-dark text-decoration-none"
                        >
                          Mostrar{' '}
                          <ChevronDown
                            className="p-0"
                            style={{ width: '20px', height: '20px' }}
                          />
                        </Button>
                      </td>
                    </tr>
                    {showReferrals[promoter._id] && (
                      <tr>
                        <td colSpan={4}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Referral ID</th>
                                <th>Email</th>
                                <th>Name</th>
                                <th>intervalo</th>
                                <th>Suscripción activa</th>
                                <th>Fecha de suscripción</th>
                                <th>Fecha de pago</th>
                                <th>Cantidad a pagar</th>
                                <th>Valor Suscripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {promoter.referrals.map(
                                (referral: any, i: number) => (
                                  <tr key={referral._id}>
                                    <td>{i + 1}</td>
                                    <td>{referral._id}</td>
                                    <td>{referral.email}</td>
                                    <td>
                                      {referral.name} {referral.lastName}
                                    </td>
                                    <td>{referral.interval}</td>
                                    <td>{referral.isActive ? 'Sí' : 'No'}</td>
                                    <td>
                                      {
                                        referral.suscriptionStartDate?.split(
                                          'T'
                                        )[0]
                                      }
                                    </td>
                                    <td>{referral.nextPaymentDate || '--'}</td>
                                    <td>{referral.paymentAmount}</td>
                                    <td>{referral.suscriptionAmount}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};
