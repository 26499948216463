import { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { spanishSpeakingcountries } from './countriesInfo';
import { FormTextFloating } from './formFloating';
import { useFormContext } from 'react-hook-form';
import { useSetCountry } from './hooks/useSetCountry';

export function PhoneControl({
  text = {
    loading: 'Cargando',
    placeholder: 'Teléfono*',
    error: 'Ingresa tu teléfono',
  },
  hasDefaultValue = false,
}: {
  text?: {
    loading: string;
    placeholder: string;
    error: string;
  };
  hasDefaultValue?: boolean;
}) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [countries, setCountries] = useState<any>(spanishSpeakingcountries);

  const { currentCountry, isLoading } = useSetCountry();

  const { ref, ...rest } = register('phone', {
    required: true,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const phoneInputRef = useRef<HTMLInputElement | null>(null);

  const handleDropdownFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownBlur = () => {
    setIsDropdownOpen(false);
  };

  const handleDropdownChange = (e: any) => {
    handleDropdownBlur();
    setValue('code', e.target.value, { shouldDirty: true });
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (currentCountry && countries[currentCountry]?.code && !hasDefaultValue) {
      setValue('code', countries[currentCountry]?.code, { shouldDirty: true });
    }
  }, [currentCountry]);

  return (
    <Container fluid className="p-0 m-0">
      <Row className="p-0 m-0">
        <Col lg={5} sm={5} xs={5} className="m-0 p-0 pe-2">
          <Form.Select
            {...register('code')}
            onFocus={handleDropdownFocus}
            onChange={handleDropdownChange}
            onBlur={handleDropdownBlur}
            className="p-10"
          >
            {Object.keys(countries).map((phone, key) => {
              return (
                <option
                  value={countries[phone].code}
                  key={key}
                  onClick={handleDropdownChange}
                >
                  {isDropdownOpen
                    ? `${countries[phone].name} ${countries[phone].code}`
                    : `${phone} ${countries[phone].code}`}
                </option>
              );
            })}
          </Form.Select>
          <small className="text-muted pt-2">
            {isLoading && text.loading + '...'}
          </small>
        </Col>
        <Col lg={7} sm={7} xs={7} className="m-0 p-0">
          <FormTextFloating
            {...rest}
            className=""
            id="company_phone_input"
            placeholder={text.placeholder}
            type="number"
            isInvalid={!!errors.phone}
            inputMode="tel"
            maxLength="15"
            error={errors.phone && text.error}
            ref={(e) => {
              ref(e);
              phoneInputRef.current = e;
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default PhoneControl;
