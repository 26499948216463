import { useTranslation } from 'react-i18next';
import { useOrderForBilling } from '../hooks/order.hook';

export const AmountOwed = ({
  amountTotalToPay,
}: {
  amountTotalToPay: number | string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between text-danger">
      <div>{t('Monto adeudado')}</div>
      <div className="fw-bold">{amountTotalToPay}</div>
    </div>
  );
};
