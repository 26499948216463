import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { ReactComponent as CheckLarge } from 'assets/check-large.svg';

function Success() {
  const navigate = useNavigate();

  const handleClick = () => {
    const menuId = localStorage.getItem('menuIdPayment');
    if (menuId) {
      navigate(`/menu-editor/${menuId}?tab=configuration`);
      localStorage.removeItem('menuIdPayment');
    } else {
      navigate(`/dashboard`);
    }
  };

  return (
    <section className="py-5">
      <Container>
        <Row>
          <Col lg={{ span: 4, offset: 4 }}>
            <Card className="shadow-sm border-0">
              <Card.Body className="text-center">
                <CheckLarge stroke="#6C757D" className="mb-3" />
                {/* <h3 className="fw-bold mb-3">$75.00 MXN</h3> */}
                <p className="fw-bold text-success">Pago exitoso</p>
                <small className="d-block text-muted mb-4">
                  Muchas gracias, hemos recibido tu pago.
                </small>
                <Button
                  variant="dark"
                  className="w-100 fw-bold"
                  onClick={handleClick}
                >
                  Continuar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function Canceled() {
  return <Navigate to="/"></Navigate>;
}

export function Payment() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  if (params.get('success')) return <Success />;
  else return <Canceled />;
  // if (params.get('canceled')) return <Canceled />;
  // return null
}

export default Payment;
