import { Spinner } from 'react-bootstrap';

function Loading(props: any) {
  return (
    <div className="d-flex text-center align-items-center justify-content-center h-100">
      <Spinner animation="grow" {...props} />
    </div>
  );
}
export default Loading;
