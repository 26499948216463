import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function FormContainer({ children }: any) {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-start justify-content-sm-center flex-column mt-3 mt-sm-0 vh-100">
        <h3 className="fw-bold mb-3">
          Meniu<span className="text-primary">.</span>
        </h3>
        {children}
        <div className="mt-3">
          <a
            href={`${process.env.NX_LANDING_URL_}/legal/privacy-policy`}
            target="_blank"
            rel="noreferrer"
            className="text-secondary text-decoration-none me-2"
          >
            <small>{t('Aviso de privacidad')}</small>
          </a>

          <a
            href={`${process.env.NX_LANDING_URL_}/legal/terms-and-conditions`}
            target="_blank"
            rel="noreferrer"
            className="text-secondary text-decoration-none"
          >
            <small>{t('Términos y condiciones')}</small>
          </a>
        </div>
      </div>
    </Container>
  );
}
export default FormContainer;
