import { Cover, CoverSection } from '@meniu/data';

function CoverSectionItem({
  section,
  setWifi,
}: {
  section: CoverSection;
  setWifi: any;
}) {
  if (section.type === 'separator') {
    return (
      <div className="d-flex gap-3 text-center align-items-center">
        <div className="border-top w-100"></div>
        <span className="fw-semibold w-100">{section.name}</span>
        <div className="border-top w-100"></div>
      </div>
    );
  }
  if (section.type === 'link') {
    return (
      <a
        href={section.href}
        target="_blank"
        rel="noreferrer"
        className="custom-shadow border-0 fw-bold d-block p-3 text-decoration-none text-dark text-center bg-white rounded-3"
      >
        {section.name}
      </a>
    );
  }
  if (section.type === 'menu') {
    return (
      <a
        href={section.href}
        target="_blank"
        rel="noreferrer"
        className="custom-shadow border-0 fw-bold d-block p-3 text-decoration-none text-dark text-center bg-white rounded-3"
      >
        {section.name}
      </a>
    );
  }
  if (section.type === 'wifi') {
    return (
      <div
        className="custom-shadow border-0 fw-bold d-block p-3 text-decoration-none text-dark text-center bg-white rounded-3 pointer"
        onClick={() =>
          setWifi({ password: section.password, network: section.network })
        }
      >
        <span>Wifi</span>
      </div>
    );
  }
  if (section.type === 'whatsapp') {
    return (
      <a
        rel="noreferrer"
        href={`https://api.whatsapp.com/send/?phone=${section.code}${section.phone}&text=Hola&type=phone_number&app_absent=0`}
        target="_blank"
        className="custom-shadow border-0 fw-bold d-block p-3 text-decoration-none text-dark text-center bg-white rounded-3"
      >
        {section.name}
      </a>
    );
  }
  return null;
}

export const CoverComponent = ({
  cover,
  footerText,
  defaultImage,
  setWifi = () => {},
  maxElements,
}: {
  footerText: string;
  cover: Cover;
  defaultImage: string;
  setWifi?: any;
  maxElements?: number;
}) => {
  const { image, name, description, sections } = cover;
  return (
    <>
      <div
        className="d-flex flex-column align-items-center gap-4 w-100"
        style={{ maxWidth: 680 }}
      >
        <div
          className="rounded-4 custom-shadow mb-3 w-100 bg-white"
          style={{
            maxHeight: 120,
            maxWidth: 120,
            width: 120,
            height: 120,
            backgroundImage: `url(${image || defaultImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>

        <div className="d-flex flex-column align-items-center gap-2 w-100 text-center">
          <h2 className="fw-bold">{name}</h2>
          <small className="text-muted">{description}</small>
        </div>
        <div className="d-flex flex-column gap-3 w-100">
          {sections
            ?.filter((f, index) => index < (maxElements || sections.length))
            .map((section, key) => (
              <CoverSectionItem section={section} key={key} setWifi={setWifi} />
            ))}
        </div>
      </div>

      <div className="text-center d-flex flex-column gap-1 mt-5">
        <small className="d-block">{footerText}</small>
        <a
          href="https://meniuapp.com/"
          target="_blank"
          className="d-block fw-bold small text-dark"
          rel="noreferrer"
        >
          meniuapp.com
        </a>
      </div>
    </>
  );
};
export default CoverComponent;
