import { useEffect } from 'react';
import { TipActions, TipProvider, useTipContext } from './context';
import { PropsTips, TipsOnChangeArgs } from './interfaces';
import { PercentageTip } from './components/PercentageTip';
import { PercentageTipOther } from './components/PercentageTipOther';
import { OtherTip } from './components/OtherTip';
import { FinalTip } from './components/FinatlTip';

export const Tips = ({
  initialPercentage,
  price,
  onChange,
  locale,
  currency,
}: {
  initialPercentage?: number;
  price: number;
  onChange?: (args: TipsOnChangeArgs) => void;
  locale?: string;
  currency?: string;
}) => {
  const percentageDefault = initialPercentage || 0;

  const [state, dispatch] = useTipContext();

  useEffect(() => {
    dispatch(TipActions.setPercentageSelected(percentageDefault));
    dispatch(TipActions.setCurrentAmount(price));
    locale && dispatch(TipActions.setLocale(locale));
    currency && dispatch(TipActions.setCurrency(currency));
    return () => {
      dispatch(TipActions.clearTipState());
    };
  }, [percentageDefault, price, locale, currency]);

  useEffect(() => {
    onChange && onChange({ ...state });
  }, [state.tipAmount]);

  return (
    <>
      <div className="d-flex gap-2 bg-light p-2 rounded-3 mb-2">
        <PercentageTip percentage={0} />
        <PercentageTip percentage={10} />
        <PercentageTip percentage={15} />
        <PercentageTipOther />
      </div>
      <OtherTip />
    </>
  );
};

export const UiTips = ({
  title,
  initialPercentage,
  price,
  onChange,
  locale,
  currency,
}: PropsTips) => {
  title = title || 'Propina';

  const priceTipFormat = Number(price.toString().replaceAll(',', '.'));

  return (
    <TipProvider>
      <div>
        <FinalTip />
        <Tips
          initialPercentage={initialPercentage}
          price={priceTipFormat}
          onChange={onChange}
          locale={locale}
          currency={currency}
        />
      </div>
    </TipProvider>
  );
};
