import { OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';

export const Tooltip = ({
  message,
  children,
  placement,
}: {
  message: string;
  children: any;
  placement?: Placement;
}) => {
  const renderTooltip = (props: any) => (
    <TooltipBootstrap id="button-tooltip" {...props}>
      {message}
    </TooltipBootstrap>
  );

  return (
    <OverlayTrigger
      placement={placement || 'right'}
      delay={{ show: 0, hide: 200 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
