import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Navbar,
  Container,
  Nav,
  Offcanvas,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserActions, useApp, useAppState } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { ReactComponent as PersonIcon } from 'assets/person-outline-icon.svg';
import { ReactComponent as PersonOutlineIcon } from 'assets/person-outline-icon.svg';
import { ReactComponent as CartIcon } from 'assets/cart-outline.svg';
import { ReactComponent as NotificationIcon } from 'assets/notifications-outline.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/log-out-outline.svg';
import { ReactComponent as CloseIcon } from 'assets/close-outline.svg';
import { ReactComponent as FeedbackIcon } from 'assets/feedback-icon.svg';
import { ReactComponent as GridIcon } from 'assets/grid-outline.svg';
import { ReactComponent as PeopleIcon } from 'assets/people-outline.svg';
import { ReactComponent as CashIcon } from 'assets/money-outline.svg';
import { ReactComponent as ExitIcon } from 'assets/close-outline.svg';

import { ReactComponent as LayersIcon } from 'assets/layers-icon.svg';
import { ReactComponent as VideoIcon } from 'assets/video-outline.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/whatsapp-icon-lg.svg';

import { ReactComponent as Chevron } from 'assets/chevron-back-outline.svg';
import { ReactComponent as Crown } from 'assets/crown.svg';
import { ReactComponent as ChevronForward } from 'assets/chevron-forward.svg';
import { ReactComponent as OrderIcon } from 'assets/order-icon.svg';

import { ReactComponent as GooglePlayIcon } from 'assets/google-play.svg';
import clsx from 'clsx';
import {
  useGetNotifications,
  useNotification,
  useProfile,
} from 'apiClient/api';
import useMediaQuery from 'hooks/useMediaQuery';
import { ReactComponent as FaChevronLeft } from 'assets/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './changeLang';
import { PlanBadge } from './planTag';
import useVerifyPro from 'hooks/useVerifyPro';
import { DateComponent } from './date.component';
import { trialDays } from '@meniu/ui';
import { Notification } from '@meniu/data';
import { useQueryClient } from 'react-query';
const btnClass = 'fs-5 fs-lg-6 text-start p-0 border-0 text-dark fw-semibold';

const NavText = ({
  path,
  location,
  navigate,
  name,
  userRoles,
  rol,
  href,
  className,
  hideOnRol,
  onClick,
}: {
  path?: string;
  location?: any;
  navigate?: any;
  name?: any;
  userRoles?: Array<string>;
  rol?: string;
  href?: string;
  className?: string;
  hideOnRol?: string;
  onClick?: any;
}) => {
  if (rol?.length && !userRoles?.includes(rol)) return null;

  return (
    <Navbar.Text className={clsx('text-dark p-0 d-grid', className)}>
      <Button
        onClick={() => (onClick ? onClick(true) : navigate(path, href))}
        variant="default"
        className={clsx(
          btnClass,
          location.pathname === path ? 'text-muted' : '',
          hideOnRol && userRoles && userRoles?.includes(hideOnRol) && 'd-none'
        )}
      >
        {name}
      </Button>
      <hr className="border-top mb-0" />
    </Navbar.Text>
  );
};

const CustomToggle = forwardRef(
  ({ children, onClick }: { children: any; onClick: any }, ref: any) => (
    <div
      className="border d-flex align-items-center justify-content-center shadow-sm rounded-3 bg-white p-2 pointer"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

const RemainingDaysTrial = ({
  remainingDaysTrial,
}: {
  remainingDaysTrial: number | undefined;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (remainingDaysTrial === undefined) return null;
  return (
    <div
      className="d-flex gap-2 bg-white custom-shadow rounded-2 px-sm-2 px-1 py-1 pointer"
      onClick={() => navigate('/profile/subscriptions')}
    >
      <small className="d-none d-sm-block small fw-semibold text-nowrap">
        {t('Período de prueba')}:
      </small>
      <small className="d-block d-sm-none small fw-semibold text-nowrap">
        {t('Prueba')}:
      </small>
      <small className="small text-nowrap">
        {remainingDaysTrial} {t('días')}
      </small>
    </div>
  );
};

const MenuButton = ({
  to,
  label,
  children,
  navigateTo,
  href,
}: {
  to: string;
  children: any;
  label: string;
  href?: string;
  navigateTo: any;
}) => {
  return (
    <div
      onClick={() => navigateTo(to, href)}
      className={clsx(
        'd-flex flex-column gap-1 align-items-center justify-content-center bg-white custom-shadow flex-fill py-3 rounded-4 w-100 pointer'
      )}
    >
      {children}
      <p className="fw-semibold text-center">{label}</p>
    </div>
  );
};

const Notifications = ({
  notifications,
  setViewNotifications,
  handleCloseOffcanvas,
  handleGoToOrders,
  navigateTo,
}: {
  notifications: Notification[];
  setViewNotifications: any;
  handleCloseOffcanvas: any;
  handleGoToOrders: any;
  navigateTo: any;
}) => {
  const { t } = useTranslation();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <Nav className="justify-content-end flex-grow-1 gap-3 ps-3 ps-lg-0">
      <div>
        <Button
          variant="default"
          className="d-flex flex-row justify-content-center align-items-center gap-2 ps-0"
          onClick={() => setViewNotifications(false)}
        >
          <FaChevronLeft style={{ transform: 'rotate(90deg)' }} />
          <h5>{t('Regresar')}</h5>
        </Button>
      </div>

      {notifications.length ? (
        notifications.map((notification, key) => (
          <NavText
            userRoles={user.roles}
            key={key}
            onClick={() => {
              handleCloseOffcanvas();
              handleGoToOrders(
                notification.menu,
                notification.order,
                notification._id
              );
            }}
            name={
              <div className="d-flex justify-content-between w-100 align-items-center">
                <div className="d-flex align-items-center align-items-center gap-3">
                  <span role="img" aria-label="order">
                    <OrderIcon style={{ width: '32px', height: '32px' }} />
                  </span>
                  <div className="d-flex flex-column">
                    <p className="fw-semibold fs-5">{notification.message}</p>
                    <small className="text-muted fs-6">
                      <DateComponent
                        value={notification.createdAt}
                        format={'PP - H:mm'}
                      />
                    </small>
                  </div>
                </div>
                <div>
                  <Chevron style={{ transform: 'rotate(180deg)' }} />
                </div>
              </div>
            }
            location={location}
            navigate={navigateTo}
          />
        ))
      ) : (
        <Dropdown.Item className="d-flex gap-3 py-3 px-4">
          <small>{t('No tienes notificaciones')}</small>
        </Dropdown.Item>
      )}
    </Nav>
  );
};

export function NavBarComponent({ hideButtons }: { hideButtons?: boolean }) {
  const [expanded, setExpanded] = useState(false);
  const [viewNotifications, setViewNotifications] = useState(false);
  const [{ user, demo }, dispatch] = useApp();
  const queryClient = useQueryClient();
  const { data } = useProfile();
  const navigate = useNavigate();

  const location = useLocation();
  const handleCloseOffcanvas = () => {
    setExpanded(false);
    setViewNotifications(false);
  };
  const { data: notifications = [], isLoading: isLoadingNotifications } =
    useGetNotifications();

  const { mutate } = useNotification();

  const handleOpenOffcanvas = () => setExpanded(true);

  const navigateTo = (url: string, href?: string) => {
    if (href) window.open(href, '_blank');
    else navigate(url);
    handleCloseOffcanvas();
  };

  const handleCreateAccount = () => {
    dispatch(UserActions.setModal(modalOptions.register));
    handleCloseOffcanvas();
  };

  const handleGoToLogin = () => {
    dispatch(UserActions.setModal(modalOptions.loginWarning));
    handleCloseOffcanvas();
  };
  const handleGoToProfile = () => {
    navigate('/profile');
    handleCloseOffcanvas();
  };
  const handleGoToSubscriptions = () => {
    navigate('/profile/subscriptions');
    handleCloseOffcanvas();
  };
  const handleGoToLogout = () => {
    if (location.pathname === '/buy') {
      queryClient.clear();
      dispatch(UserActions.logout());
      handleCloseOffcanvas();
    } else {
      navigate('/logout');
      handleCloseOffcanvas();
    }
  };
  const handleGoToOrders = (
    menuId?: string,
    orderId?: string,
    notificationId?: string
  ) => {
    if (notificationId) {
      mutate(notificationId);
    }
    navigate(`/menu-editor/${menuId}?tab=orders&order=${orderId}`, {
      replace: true,
    });
  };

  const isMD = useMediaQuery('(max-width: 991.9px)');

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!isMD) {
      handleCloseOffcanvas();
    }
  }, [isMD]);

  const expand = 'lg';

  const navs = useMemo(
    () => [
      /* {
        name: t('Quiero ser Pro'),
        path: '/profile/subscriptions',
        hideOnRol: 'user_pro',
      }, */
      // { name: t('Admin'), path: '/admin', rol: 'admin' },
      // { name: t('Dashboard'), path: '/' },
      // {
      //   name: t('Tutoriales'),
      //   path: '/tutorial',
      // },
      {
        name: t('Notificaciones'),
        onclick: 'notifications',
        className: 'd-xs-block d-lg-none',
      },
      {
        name: t('Mi perfil'),
        path: '/profile',
        className: 'd-xs-block d-lg-none',
      },
      {
        name: t('Cerrar sesión'),
        path: '/logout',
        className: 'd-xs-block d-lg-none',
      },
    ],
    [t]
  );

  const { isPro } = useVerifyPro();

  const handleOpenFeedback = () =>
    dispatch(UserActions.setModal(modalOptions['send-feedback']));

  if (hideButtons) {
    return (
      <Navbar
        className="bg-white border-bottom d-flex flex-column justify-content-center"
        expand={expand}
        fixed="top"
        collapseOnSelect={true}
        expanded={expanded}
      >
        <Container fluid>
          <div className="d-flex align-items-center">
            <Link
              to="/"
              className="text-decoration-none d-flex justify-content-between align-items-center gap-2"
            >
              {isPro && <Crown style={{ width: '20px', height: '20px' }} />}
              <Navbar.Brand className="fw-bold me-2">
                Meniu<span className="text-primary">.</span>
              </Navbar.Brand>
            </Link>
          </div>
          <div className="d-flex gap-3">
            <ChangeLanguage />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/GWrt8xh8bdTkqUth9"
              className="p-1 border rounded-pill"
            >
              <ExitIcon />
            </a>
            {/* <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-logout">{t('Salir')}</Tooltip>}
            >
              <Button
                variant="default"
                size="sm"
                className="border-0 d-flex gap-2 align-items-center"
                onClick={handleGoToLogout}
                hidden={!user.forcePlan}
              >
                <span className="d-block d-sm-none fw-semibold">
                  {t('Salir')}
                </span>
                <LogoutIcon />
              </Button>
            </OverlayTrigger> */}
          </div>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar
      className="bg-light d-flex flex-column justify-content-center"
      expand={expand}
      fixed="top"
      collapseOnSelect={true}
      expanded={expanded}
    >
      <Container fluid>
        <div className="d-flex align-items-center">
          <Link
            to="/"
            className="text-decoration-none d-flex justify-content-between align-items-center gap-2"
          >
            {isPro && <Crown style={{ width: '20px', height: '20px' }} />}
            <Navbar.Brand className="fw-bold me-2">
              Meniu<span className="text-primary">.</span>
            </Navbar.Brand>
          </Link>
          <RemainingDaysTrial remainingDaysTrial={data?.remainingDaysTrial} />
        </div>

        <div className="d-flex align-items-center gap-3">
          {demo ? (
            <>
              <small
                className="fw-semibold d-block d-lg-none lh-1"
                onClick={handleCreateAccount}
              >
                {t('Crear cuenta')}
              </small>
              <div
                className="border-end d-lg-none d-block"
                style={{ height: 24 }}
              ></div>
            </>
          ) : null}
          <div className="d-block d-lg-none">
            <ChangeLanguage asDropDown showText={false} />
          </div>
          <Navbar.Toggle
            className="border p-1 shadow-sm rounded-2 bg-white"
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={handleOpenOffcanvas}
          />
        </div>

        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="top"
        >
          <Offcanvas.Header>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-${expand}`}
              className="d-flex justify-content-between align-items-center w-100 px-0 px-lg-3"
            >
              <Link to="/" className="text-decoration-none w-100">
                <Navbar.Brand className="fw-bold fs-3 text-dark">
                  Meniu<span className="text-primary">.</span>
                </Navbar.Brand>
              </Link>
              <Button
                className="p-1 border shadow-sm rounded-3"
                variant="default"
                size="sm"
              >
                <CloseIcon
                  onClick={handleCloseOffcanvas}
                  height={24}
                  width={24}
                />
              </Button>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            {viewNotifications ? (
              <Notifications
                notifications={notifications}
                handleCloseOffcanvas={handleCloseOffcanvas}
                handleGoToOrders={handleGoToOrders}
                navigateTo={navigateTo}
                setViewNotifications={setViewNotifications}
              />
            ) : (
              <Nav className="justify-content-end flex-grow-1 gap-3">
                {demo ? (
                  <>
                    <Navbar.Text className="text-dark p-0 d-grid">
                      <Button
                        variant="default"
                        onClick={handleGoToLogin}
                        className={btnClass}
                      >
                        {t('Inicia sesión')}
                      </Button>
                    </Navbar.Text>
                    <Navbar.Text className="text-dark p-0 d-grid">
                      <Button
                        variant="default"
                        onClick={handleCreateAccount}
                        className={btnClass}
                      >
                        {t('Crear cuenta')}
                      </Button>
                    </Navbar.Text>
                    <div className="border-end h-100 d-lg-block d-none"></div>
                    <ChangeLanguage asDropDown />
                  </>
                ) : (
                  <>
                    <div className="pt-1 d-block d-lg-none d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <MenuButton
                          to="/"
                          label={t('Mis menús')}
                          navigateTo={navigateTo}
                        >
                          <GridIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>

                        <MenuButton
                          to="/covers"
                          label={t('Portadas')}
                          navigateTo={navigateTo}
                        >
                          <LayersIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>
                      </div>

                      <div className="d-flex gap-3">
                        <MenuButton
                          to="/reservations"
                          label={t('Reservaciones')}
                          navigateTo={navigateTo}
                        >
                          <CalendarIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>
                        <MenuButton
                          to="/clients"
                          label={t('Clientes')}
                          navigateTo={navigateTo}
                        >
                          <PeopleIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>
                      </div>
                      <div className="d-flex gap-3">
                        <MenuButton
                          to="/tutorial"
                          label={t('Tutoriales')}
                          navigateTo={navigateTo}
                        >
                          <VideoIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>
                        <MenuButton
                          to="/referals"
                          label={t('Referidos')}
                          navigateTo={navigateTo}
                        >
                          <CashIcon style={{ height: 32, width: 32 }} />
                        </MenuButton>
                      </div>
                    </div>

                    {navs.map((props, key) => (
                      <NavText
                        userRoles={user.roles}
                        key={key}
                        onClick={props.onclick && setViewNotifications}
                        {...props}
                        location={location}
                        navigate={navigateTo}
                      />
                    ))}

                    {!isMD && (
                      <div className="d-flex gap-2">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="border d-flex align-items-center justify-content-center shadow-sm rounded-3 bg-white p-2">
                            <ChangeLanguage asDropDown showText={false} />
                          </div>
                        </div>
                        <Dropdown drop="start">
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-notifications"
                          >
                            <div className="position-relative">
                              <NotificationIcon />
                              <div
                                className="bg-danger position-absolute top-0 end-0 rounded-pill"
                                style={{ width: '6px', height: '6px' }}
                                hidden={notifications.every(
                                  (n) => n.read === true
                                )}
                              />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="mt-4 shadow-sm border overflow-auto"
                            style={{ maxHeight: '400px' }}
                          >
                            <Dropdown.Header
                              className="border-bottom text-dark sticky-top bg-white"
                              style={{ top: '-10px' }}
                            >
                              <p className="fw-semibold">
                                {notifications.length} {t('Notificaciones')}
                              </p>
                            </Dropdown.Header>
                            {notifications.length ? (
                              notifications.map((notification, index) => (
                                <Dropdown.Item
                                  eventKey={index + 1}
                                  key={index + 1}
                                  className="d-flex gap-3 p-3 align-items-center"
                                  onClick={() =>
                                    handleGoToOrders(
                                      notification.menu,
                                      notification.order,
                                      notification._id
                                    )
                                  }
                                >
                                  <div className="d-flex flex-row align-items-center gap-2">
                                    <span role="img" aria-label="order">
                                      <OrderIcon
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      />
                                    </span>{' '}
                                    <div className="d-flex flex-column">
                                      <small className="fw-semibold text-dark">
                                        {notification.message}
                                      </small>
                                      <small className="small-xs text-muted">
                                        <DateComponent
                                          value={notification.createdAt}
                                          format={'PP - H:mm'}
                                        />
                                      </small>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              ))
                            ) : (
                              <Dropdown.Item className="d-flex gap-3 py-3 px-4">
                                <small>{t('No tienes notificaciones')}</small>
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown drop="start">
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-profile"
                          >
                            <PersonIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="mt-4 shadow-sm border-0 pb-0">
                            <Dropdown.Header className="m-0 d-flex justify-content-between align-items-end">
                              <small>{t('Cuenta')}</small>
                              {isPro && <PlanBadge />}
                            </Dropdown.Header>
                            <Dropdown.Item
                              eventKey="1"
                              className="d-flex gap-2 py-3 px-4 align-items-center justify-content-start"
                              onClick={handleGoToProfile}
                            >
                              <div className="border rounded-pill p-2 d-flex justify-content-center align-items-center">
                                <PersonOutlineIcon
                                  style={{ width: '20px', height: '20px' }}
                                  className="m-1"
                                />
                              </div>
                              <div
                                className="d-flex flex-column text-truncate"
                                style={{ maxWidth: '200px' }}
                              >
                                <span className="text-truncate lh-sm fw-semibold text-dark">
                                  {user.name}
                                </span>
                                <small className="small-xs text-muted">
                                  {user.email}
                                </small>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider className="border border-top-0 m-0" />
                            <Dropdown.Item
                              eventKey="2"
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={handleGoToSubscriptions}
                            >
                              <CartIcon />
                              {t('Suscripción')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={() =>
                                window.open(
                                  `https://calendly.com/meniuapp/plan-business`,
                                  '_blank'
                                )
                              }
                            >
                              <CalendarIcon style={{ width: '24px' }} />
                              {t('Agendar demo')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={() =>
                                window.open(
                                  `https://play.google.com/store/apps/details?id=com.meniuapp.mobile`,
                                  '_blank'
                                )
                              }
                            >
                              <GooglePlayIcon style={{ width: '24px' }} />
                              {t('Descargar App')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={handleOpenFeedback}
                            >
                              <FeedbackIcon style={{ width: '24px' }} />
                              {t('Feedback App')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={() =>
                                window.open(
                                  `https://www.meniuapp.com/afiliados`,
                                  '_blank'
                                )
                              }
                            >
                              <CashIcon style={{ width: '24px' }} />
                              {t('Quiero ganar dinero')}
                            </Dropdown.Item>
                            <Dropdown.Divider className="border border-top-0 m-0" />
                            <Dropdown.Item
                              eventKey="3"
                              className="d-flex gap-3 py-3 px-4 align-items-center text-dark"
                              onClick={handleGoToLogout}
                            >
                              <LogoutIcon style={{ width: '24px' }} />
                              {t('Cerrar sesión')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </>
                )}
              </Nav>
            )}
          </Offcanvas.Body>

          <Link
            to="/profile/subscriptions"
            className="text-decoration-none"
            hidden={isPro}
            onClick={handleCloseOffcanvas}
          >
            <div className="bg-light d-flex d-sm-none px-3 py-3 gap-5 align-items-center border-top">
              <div className="d-flex flex-column">
                <h4 className="fs-6 fw-bold mb-2 text-dark">
                  {t('Prueba Meniu PRO gratis')}
                </h4>
                <p className="text-muted small">
                  {t(
                    'Disfruta de todas las funciones premium por {{trialDays}} días',
                    {
                      trialDays: trialDays,
                    }
                  )}
                </p>
              </div>
              <ChevronForward style={{ minWidth: '32px' }} />
            </div>
          </Link>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavBarComponent;
