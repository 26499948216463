import { UserActions, useApp } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { useCallback } from 'react';
import { sendAnalytics } from '@utils';
import { User } from '@meniu/data';

type AnalyticsFeatureMap = {
  [key in string]: string;
};

export const analyticsFeatureMap: AnalyticsFeatureMap = {
  'analytics-modal': 'analytics-modal',
  'custom-domain-modal': 'custom-domain-modal',
  'logo-modal': 'logo-modal',
  'whatsapp-modal': 'whatsapp-modal',
  'payment-modal': 'payment-modal',
  'delivery-modal': 'send-option-modal',
  'order-modal': 'order-modal',
  'mobile-menu-option': 'mobile-menu-option',
  'enable-whatsapp-modal': 'enable-whatsapp-modal',
  'set-delivery-type': 'set-delivery-type',
  'extra-fonts': 'extra-fonts',
  'configure-stripe-feature': 'configure-stripe-feature',
};
export interface ModalTrack {
  message?: string;
  analyticsFeaturesPro: keyof AnalyticsFeatureMap;
}

export function useVerifyPro(menuUser?: User) {
  const [
    {
      modal,
      user: { roles, _id, email },
    },
    dispatch,
  ] = useApp();

  const isProModal = useCallback(
    ({ message = '', analyticsFeaturesPro = '' }: ModalTrack) => {
      if (
        !roles?.includes('user_pro') &&
        !menuUser?.roles?.includes('user_pro')
      ) {
        const analyticsName = analyticsFeatureMap[analyticsFeaturesPro];
        if (analyticsName) {
          sendAnalytics(analyticsName, { userId: email || '' });
        }
        dispatch(
          UserActions.setModal([...modal, modalOptions['buy-pro']], message)
        );
        return false;
      } else {
        return true;
      }
    },
    [roles, _id]
  );

  return {
    isProModal,
    isPro: roles?.includes('user_pro') || menuUser?.roles?.includes('user_pro'),
  };
}

export default useVerifyPro;
