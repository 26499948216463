import { ReactNode } from 'react';

export enum ShowIn {
  'development' = 'development',
  'production' = 'production',
}

interface EnvironmentIndicatorProps {
  showIn?: ShowIn;
  children: ReactNode;
}

const EnvironmentIndicator: React.FC<EnvironmentIndicatorProps> = ({
  showIn,
  children,
}) => {
  if (!showIn) return <>{children}</>;
  const env = process.env.NX_ENVIRONMENT_INDICATOR;
  return <>{env === showIn && children}</>;
};

export default EnvironmentIndicator;
