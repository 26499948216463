import { RecoverDTO } from '@meniu/data';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoverPassword } from 'apiClient/api';
import { emailPattern } from '@utils';
import { useTranslation } from 'react-i18next';

export function Forgot() {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<RecoverDTO>({ mode: 'all' });

  const { mutate, data } = useRecoverPassword();

  const doSubmit = (values: RecoverDTO) => {
    mutate(values, {
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const { t } = useTranslation();

  if (data?.success) {
    return <div>{t('Revisa tu correo')}</div>;
  }

  return (
    <Form onSubmit={handleSubmit(doSubmit)}>
      <h4>{t('Forgot')}</h4>
      <Form.Group>
        <Form.Label>{t('Email')}</Form.Label>
        <Form.Control
          placeholder={t('email') || ''}
          type="text"
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
        />
      </Form.Group>
      <Form.Group>
        <Button type="submit" disabled={!isValid}>
          {t('Recover')}
        </Button>
      </Form.Group>
    </Form>
  );
}

export default Forgot;
