// libraries.
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ui.
import FormTextFloating from '../../../../formFloating';

// context
import { TipActions, useTipContext } from '../context';

/**
 * Inputs to select the tip amount or percentage.
 * @returns component
 */
export const OtherTip = () => {
  const [percentageValue, setPercentageValue] = useState<string>('');
  const [amountValue, setAmountValue] = useState<string>('');

  const [{ percentageSelected }, dispatch] = useTipContext();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(TipActions.setPercentageManual(null));
    };
  }, []);

  if (percentageSelected !== null) return null;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <FormTextFloating
        type="number"
        placeholder={t('Porcentaje')}
        min={0}
        onChange={(e) => {
          setPercentageValue(e.target.value);
          setAmountValue('');

          const percentage = Number(e.target.value);
          dispatch(TipActions.setPercentageManual(percentage));
          dispatch(TipActions.setTipValuesFromPercentageCustom());
        }}
        value={percentageValue}
        onFocus={() => {
          setAmountValue('');
          dispatch(TipActions.clearTipWithAmount());
        }}
      />{' '}
      <div>o</div>
      <FormTextFloating
        type="number"
        placeholder={t('Cantidad')}
        min={0}
        onChange={(e) => {
          setAmountValue(e.target.value);
          setPercentageValue('');

          const tipAmount = Number(e.target.value);

          dispatch(TipActions.setTipAmount(tipAmount));
          dispatch(TipActions.setTipWithAmount());
        }}
        value={amountValue}
        onFocus={() => {
          setPercentageValue('');
          dispatch(TipActions.setPercentageManual(null));
          dispatch(TipActions.clearTipWithAmount());
        }}
      />
    </div>
  );
};
