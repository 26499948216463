// Libraries.
import styled from 'styled-components';

export const WrapperTab = styled.div`
  .tab {
  }

  .tab-active {
    background-color: #ffffff;
    box-shadow: 0 8px 24px rgba(18,7,143,.04), 0 1px 2px rgba(14,9,72,.08), 0 0 0 1px rgba(14,9,72,.04), inset 0 0 3px rgba(19,39,108,.04), inset 0 -4px 1px rgba(19,39,109,.06);
  }
`;
