import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateDemoMenu } from 'apiClient/api';
import { useApp, UserActions } from 'context/global.context';
import Loading from 'shared/loading.component';
import Cookies from 'js-cookie';

function Demo() {
  const { mutate } = useCreateDemoMenu();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [{ isLogged, demo }, dispatch] = useApp();

  const referredBy = searchParams.get('promoter');

  useEffect(() => {
    if (isLogged && !demo) {
      navigate(`/`, { replace: true });
    } else if (demo) {
      navigate(`/menu-editor/${demo}`, { replace: true });
    } else {
      mutate(
        {
          utm: Cookies.get('utm'),
          sessionId: Cookies.get('sessionId'),
          referredBy: referredBy || undefined,
        },
        {
          onSuccess: ({ menu, access_token }) => {
            if (menu._id) {
              dispatch(
                UserActions.setToken({ access_token, success: true }, menu._id)
              );
              navigate({
                pathname: `/menu-editor/${menu._id}`,
                search: referredBy ? `?promoter=${referredBy}` : '',
              });
            }
          },
        }
      );
    }
  }, []);

  return <Loading></Loading>;
}

export default Demo;
