import { Menu } from '@meniu/data';
import {
  useAcceptInviteToMenu,
  useGetInvitationsToMenu,
  useGetMyInvites,
  useGetMyManagedMenus,
} from 'apiClient/api';
import { useAppState } from 'context/global.context';
import { useMemo } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RestaurantIcon } from 'assets/restaurant-outline.svg';

const MenuCardAsWaiter = ({ menu }: { menu: Menu }) => {
  const { t } = useTranslation();
  const Status = useMemo(() => {
    if (menu.blocked)
      return (
        <div className="fw-semibold rounded-1 badge-danger badge bg-primary p-2">
          <p className="text-danger">{t('Suspendido')}</p>
        </div>
      );

    if (menu.isPublished)
      return (
        <div className="fw-semibold rounded-1 badge-success badge bg-primary p-2">
          <p className="text-success">{t('Publicado')}</p>
        </div>
      );

    return (
      <div className="fw-semibold rounded-1 badge bg-light p-2">
        <p className="text-muted">{t('No publicado')}</p>
      </div>
    );
  }, [menu.isPublished, menu.blocked]);

  const navigate = useNavigate();

  const { user } = useAppState();
  const handleGoToMenu = () => navigate(`/menu-editor/${menu._id}`);

  const limitPermissions =
    menu?.users?.find((u) => u._id === user._id)?.roles || [];

  return (
    <Col xl={{ span: 4 }} lg={4} className="d-flex flex-column gap-4 mt-4">
      <Card className="rounded-4 bg-white pointer border-0 custom-shadow p-4 gap-4">
        <Card.Header className="bg-white border-0 d-flex justify-content-between p-0 align-items-center">
          <div className="bg-light p-3 rounded-4">
            <RestaurantIcon width="32px" height="32px" />
          </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column gap-2 p-0 text-start">
          <h3 className="fw-bold text-break">{menu.name}</h3>

          <div className="d-flex gap-2">
            {Status}

            {menu.enableWhatsapp ? (
              <div className="badge-success fw-semibold badge text-success rounded-2 p-2">
                {t('Menú con envíos')}
              </div>
            ) : (
              <div className="badge-success fw-semibold badge text-success rounded-2 p-2">
                {t('Menú de lectura')}
              </div>
            )}
          </div>
          <div className="border-bottom mt-3 border-light"></div>
        </Card.Body>
        <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3 d-flex flex-column">
          <Button
            className="text-dark text-decoration-none bg-light fw-semibold w-100 rounded-4"
            variant="link"
            onClick={handleGoToMenu}
          >
            {t('Ver menú')}
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

const InviteItem = ({ menu, inviteId }: { menu: Menu; inviteId: string }) => {
  const { mutate } = useAcceptInviteToMenu();

  const { t } = useTranslation();

  const handleAcceptInvitation = () => {
    mutate({
      menuId: menu._id!,
      _id: inviteId,
    });
  };

  return (
    <Col xl={{ span: 4 }} lg={4} className="d-flex flex-column gap-4 mt-4">
      <Card className="rounded-4 bg-white pointer border-0 custom-shadow p-4 gap-4">
        <Card.Header className="bg-white border-0 d-flex justify-content-between p-0 align-items-center">
          <div className="bg-light p-3 rounded-4">
            <RestaurantIcon width="32px" height="32px" />
          </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column gap-2 p-0 text-start">
          <h3 className="fw-bold text-break">{menu.name}</h3>
          <div className="badge badge-warning fw-semibold rounded-2 p-2">
            {t('Invitación pendiente por aceptar')}
          </div>
          <div className="border-bottom mt-3 border-light"></div>
        </Card.Body>
        <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3">
          <Button
            className="text-dark text-decoration-none bg-light fw-semibold w-100 rounded-4"
            variant="link"
            onClick={handleAcceptInvitation}
          >
            {t('Aceptar invitación')}
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export function ManagedMenus() {
  const { t } = useTranslation();

  const { user } = useAppState();

  const { data: menus, isLoading } = useGetMyManagedMenus({ page: 1 });

  const { data: invites } = useGetMyInvites();

  return (
    <Container fluid className="pt-4">
      <Row>
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <h3 className="fw-bold">{t('Menús compartidos')}</h3>
          </div>
        </Col>
      </Row>

      <Row>
        {invites?.map((invite, index) => {
          const menu = invite?.menu as Menu;
          if (!menu) return null;
          return (
            <InviteItem
              menu={menu}
              inviteId={invite._id!}
              key={`invite-${index}`}
            />
          );
        })}
        {isLoading
          ? null
          : menus?.docs?.map((menu, index) => (
              <MenuCardAsWaiter menu={menu} key={`card-${index}`} />
            ))}
      </Row>
    </Container>
  );
}

export default ManagedMenus;
