import { Container, Row, Col, Card } from 'react-bootstrap';
import { PurchaseButton } from './PurchaseButton';

export function Plans() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Body className="p-3">
              <h4 className="mb-3">Basic</h4>
              <div className="d-grid">
                <PurchaseButton
                  variant="dark"
                  priceId={`${process.env.NX_PRO_PLAN}`}
                  text="Comprar Plan Business"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="dark" text="white">
            <Card.Body className="p-3">
              <h4 className="mb-3">Pro</h4>
              <div className="d-grid">
                <PurchaseButton
                  variant="dark"
                  priceId={`${process.env.NX_PRO_PLAN}`}
                  text="Comprar Plan Business"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="dark" text="white">
            <Card.Body className="p-3">
              <h4 className="mb-3">Premium</h4>
              <div className="d-grid">
                <PurchaseButton
                  variant="dark"
                  priceId={`${process.env.NX_PRO_PLAN}`}
                  text="Comprar Plan Business"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Plans;
