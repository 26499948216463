// Libraries.
import { AnchorHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

// Components.
import { UserActions, useApp, useAppDispatch } from 'context/global.context';

// Icons.
import { ReactComponent as LockClosedIcon } from 'assets/lock-closed-outline.svg';
import { ReactComponent as DocumentLockIcon } from 'assets/document-lock-outline.svg';
import { ReactComponent as GridIcon } from 'assets/grid-outline.svg';
import { ReactComponent as InformationIcon } from 'assets/information-circle-outline.svg';
import { ReactComponent as MailIcon } from 'assets/mail-outline.svg';
import { ReactComponent as CartIcon } from 'assets/cart-outline.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-outline.svg';
import { usePatchUser } from 'apiClient/api';
import { Link, useNavigate } from 'react-router-dom';
import ContactUs from './modals/contact.modal';
import ChangePassword from './modals/change-password.modal';
import { FormTextFloating, PhoneControl } from '@meniu/ui';
import { emailPattern } from '@utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from 'shared/changeLang';
import { modalOptions } from 'context/menu.context';

function ProfileCard({
  children,
  header,
  dark,
}: {
  children: ReactNode;
  header: string;
  dark?: boolean;
}) {
  return (
    <Card
      className="border-0 w-100 shadow-sm"
      bg={dark ? 'dark' : 'white'}
      text={!dark ? 'dark' : 'white'}
    >
      <Card.Body className="p-4 pb-3 mt-2">
        <h4 className="fw-bold mb-4">{header}</h4>
        {children}
      </Card.Body>
    </Card>
  );
}

interface MeniuLink extends AnchorHTMLAttributes<any> {
  to: string;
}

const LinkMeniu = (props: MeniuLink) => (
  <a
    href={props.to}
    className={`${props.className} text-dark text-decoration-none`}
    target="_blank"
    rel="noreferrer"
  >
    {props.children}
  </a>
);

function MyAccount({
  setOpenContactUs,
  setOpenChangePassword,
}: {
  setOpenContactUs: any;
  setOpenChangePassword: any;
}) {
  const { t } = useTranslation();

  const handleOpenChangePassword = () => setOpenChangePassword(true);

  const handleOpenContactUs = () => setOpenContactUs(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleGoToSubscriptions = () => {
    navigate('subscriptions');
  };
  const dispatch = useAppDispatch();
  const handleOpenFeedback = () =>
    dispatch(UserActions.setModal(modalOptions['send-feedback']));

  return (
    <ProfileCard header={t('Mi cuenta')}>
      <ListGroup variant="flush" className="">
        <ListGroup.Item
          as={Link}
          to="/"
          className="pb-4 px-0 border-bottom text-dark"
        >
          <GridIcon className="me-2" />
          {t('Dashboard')}
        </ListGroup.Item>

        <ListGroup.Item
          as={'span'}
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenFeedback}
        >
          <InformationIcon className="me-2" />
          <span>{t('Ayúdanos a mejorar')}</span>
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenContactUs}
        >
          <MailIcon className="me-2" />
          {t('Contactanos')}
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenChangePassword}
        >
          <LockClosedIcon className="me-2" />
          {t('Cambiar contraseña')}
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleGoToSubscriptions}
        >
          <CartIcon className="me-2" />
          {t('Mi suscripción')}
        </ListGroup.Item>

        <ListGroup.Item
          as={LinkMeniu}
          to={`${process.env.NX_LANDING_URL_}/legal/privacy-policy`}
          className="py-4 px-0"
        >
          <DocumentLockIcon className="me-2" />
          {t('Aviso de privacidad')}
        </ListGroup.Item>
        <ListGroup.Item
          as={LinkMeniu}
          to="https://forms.gle/j5b6UzqLHJVnzgaWA"
          className="py-4 px-0"
        >
          <TrashIcon className="me-2" />
          {t('Eliminar mi cuenta')}
        </ListGroup.Item>
      </ListGroup>
    </ProfileCard>
  );
}

export function Premium() {
  const { t } = useTranslation();
  return (
    <ProfileCard header="Plan business">
      <p>
        {t('Si te interesa conocer más envíanos un mensaje a')}{' '}
        <a
          href={`https://wa.me/525521949216?text=${encodeURIComponent(
            t('Hola, quiero una solución a la medida!').toString()
          )}`}
          className=" text-dark"
          target={'_blank'}
          rel={'noreferrer'}
        >
          55 2194 9216
        </a>
      </p>
    </ProfileCard>
  );
}

const UserProfileForm = ({ disableEmail }: { disableEmail: boolean }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Form.Group>
        <FormTextFloating
          placeholder={t('Nombre')}
          type="text"
          isInvalid={!!errors.name}
          error={errors.name && t('Ingresa tu nombre')}
          {...register('name')}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Apellido')}
          type="text"
          isInvalid={!!errors.lastname}
          error={errors.lastname && t('Ingresa tu apellido')}
          {...register('lastname')}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Nombre de tu negocio*')}
          type="text"
          isInvalid={!!errors.company}
          error={errors.company && t('Ingresa nombre del negocio')}
          {...register('company', { required: true })}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Correo*')}
          type="text"
          disabled={disableEmail}
          isInvalid={!!errors.email}
          error={errors.email && t('Ingresa tu correo')}
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
        />
      </Form.Group>
      <PhoneControl
        hasDefaultValue={true}
        text={{
          placeholder: t('Teléfono'),
          loading: t('Cargando'),
          error: t('Ingresa tu teléfono'),
        }}
      />
      <Form.Group>
        <ChangeLanguage className="form form-control" />
      </Form.Group>
    </>
  );
};
function ProfileEdit() {
  const [{ user }] = useApp();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { isDirty, errors, isValid },
  } = useFormContext();

  const { mutate, isLoading } = usePatchUser();
  const doSubmit = (data: any) => {
    mutate(data, {
      onSuccess: (result) => {
        reset(result);
        toast.success('Perfil actualizado');
      },
    });
  };

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        company: user.company,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        code: user.code,
      });
      clearErrors();
    }
  }, [user]);

  return (
    <ProfileCard header={t('Mi perfil')}>
      <Form
        className="d-flex flex-column gap-3"
        onSubmit={handleSubmit(doSubmit)}
      >
        <UserProfileForm disableEmail={true} />
        <div className="d-grid mt-3 mb-2">
          <Button
            variant="dark"
            className="fw-bold"
            disabled={!isDirty || isLoading || !isValid}
            type="submit"
          >
            {t('Guardar cambios')}
          </Button>
        </div>
      </Form>
    </ProfileCard>
  );
}

export function Profile() {
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const methods = useForm({ mode: 'all' });
  return (
    <Container>
      <Row className="mt-5 gap-3 gap-lg-0">
        <Col sm="12" md="12" lg="6">
          <FormProvider {...methods}>
            <ProfileEdit />
          </FormProvider>
        </Col>
        <Col sm="12" md="12" lg="6">
          <MyAccount
            setOpenContactUs={setOpenContactUs}
            setOpenChangePassword={setOpenChangePassword}
          />
        </Col>
      </Row>
      <ContactUs
        openContactUs={openContactUs}
        setOpenContactUs={setOpenContactUs}
      />
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
      />
    </Container>
  );
}

export default Profile;
