/**
 * this function formats a number to a currency
 * @param number is the number to be formatted
 * @param locale is the locale to be used
 * @param currency is the currency to be used
 * @param showSymbol is a boolean to show the currency symbol
 * @returns
 */
export const toCurrency = (
  number: number,
  locale?: string,
  currency?: string,
  showSymbol = false
) => {
  try {
    const formated = `${new Intl.NumberFormat(locale || 'es-MX', {
      style: 'currency',
      currency: currency || 'MXN',
    }).format(number)} ${showSymbol && currency ? currency : ''}`;

    return formated;
  } catch (e) {
    return number;
  }
};

export function getCurrencySymbol(locale: string, currency: string): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(1);
  const currencySymbol = parts.find((part) => part.type === 'currency')?.value;

  if (!currencySymbol) {
    throw new Error(
      `Could not determine symbol for currency ${currency} with locale ${locale}`
    );
  }

  return currencySymbol;
}
