// libraries.
import { ImmerReducer } from 'immer-reducer';

export class TipInitialState {
  percentageManual!: number | null;
  percentageSelected!: number | null;
  tipWithAmount!: number;
  tipAmount!: number;
  currentAmount!: number;
  locale!: string;
  currency!: string;
}

export const tipInitialState: TipInitialState = {
  percentageManual: null,
  percentageSelected: 0,
  tipWithAmount: 0,
  tipAmount: 0,
  currentAmount: 0,
  locale: '',
  currency: '',
};

export class TipReducer extends ImmerReducer<typeof tipInitialState> {
  /*
   * Set the percentage selected
   * @param percentage is the percentage to set
   */
  setPercentageSelected(percentage: number | null) {
    this.draftState.percentageSelected = percentage;
  }

  /*
   * Set the percentage manual
   * @param percentage is the percentage to set
   */
  setPercentageManual(percentage: number | null) {
    this.draftState.percentageManual = percentage;
  }

  /*
   * Set the current amount
   * @param amount is the amount to pay
   */
  setCurrentAmount(amount: number) {
    this.draftState.currentAmount = amount;
  }

  /*
   * Set tip amount directly (if the user pay in cash we set the tip directly)
   * @param amount is the amount of the tip
   */
  setTipAmount(amount: number) {
    this.draftState.tipAmount = amount;
  }

  clearTipWithAmount() {
    this.draftState.tipWithAmount = 0;
  }

  setTipWithAmount() {
    const amount = this.draftState.currentAmount + this.draftState.tipAmount;
    this.draftState.tipWithAmount = amount;
  }

  /*
   * Set tip values from the state
   */
  setTipValues() {
    const amount = this.draftState.currentAmount;

    const tipAmount =
      this.draftState.percentageSelected &&
      amount * (this.draftState.percentageSelected / 100);

    const tipWithAmount = (tipAmount || 0) + amount;

    this.draftState.tipWithAmount = tipWithAmount || 0;
    this.draftState.tipAmount = tipAmount || 0;
  }

  /*
   * Set tip values from percentage custom
   * @param percentageCustom is the percentage of the tip
   */
  setTipValuesFromPercentageCustom() {
    const amount = this.draftState.currentAmount;
    const percentageManual = this.draftState.percentageManual;
    const tipAmount = percentageManual && amount * (percentageManual / 100);

    const tipWithAmount = (tipAmount || 0) + amount;

    this.draftState.tipWithAmount = tipWithAmount || 0;
    this.draftState.tipAmount = tipAmount || 0;
  }

  /*
   * Clear the tip state
   */
  clearTipState() {
    this.draftState = tipInitialState;
  }

  /*
   * Set the locale
   * @param currency is the locale to set
   */
  setLocale(locale: string) {
    this.draftState.locale = locale;
  }

  /*
   * Set the currency
   * @param currency is the currency to set
   */
  setCurrency(currency: string) {
    this.draftState.currency = currency;
  }
}
