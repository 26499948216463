import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { RegisterDTO } from '@meniu/data';
import { useGoogleLogin, useRegister } from 'apiClient/api';
import { toast } from 'react-toastify';
import { CreatePassword } from './CreatePassword';
import { loginOptions } from '../auth.page';
import usersImage from '../assets/users.png';
import { ReactComponent as Stars } from '../assets/stars.svg';
import { CreateUser } from './CreateUser';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { ReactComponent as GoogleIcon } from '../assets/google.svg';
import { UserActions, useApp } from 'context/global.context';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useListenLogin } from 'hooks/useListenLogin';
import { totalRestaurants } from '@meniu/ui';

const FormRegister = ({
  doSubmit,
  step,
  isLoading,
  nameRestaurant,
}: {
  step: number;
  doSubmit: any;
  isLoading: boolean;
  nameRestaurant?: string;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();
  return (
    <Form
      onSubmit={handleSubmit(doSubmit)}
      className="d-flex gap-3 flex-column"
      autoComplete="off"
    >
      {
        {
          [loginOptions.user]: <CreateUser nameRestaurant={nameRestaurant} />,
          [loginOptions.passwords]: <CreatePassword />,
        }[step]
      }

      <Form.Group className="d-block w-100 mt-2">
        <Button
          type="submit"
          variant="dark"
          data-test="register-modal-btn"
          className="fw-bold w-100"
          disabled={isLoading || (step === loginOptions.passwords && !isValid)}
        >
          {
            {
              [loginOptions.user]: t('Continuar'),
              [loginOptions.passwords]: t('Crear cuenta'),
            }[step]
          }
        </Button>
      </Form.Group>
    </Form>
  );
};

export const RegisterForm = ({
  step,
  setStep,
  setEmail,
  isModal,
  nameRestaurant,
}: {
  step: number;
  setStep: any;
  setEmail: any;
  isModal?: boolean;
  nameRestaurant?: string;
}) => {
  const location = useLocation();
  const methods = useForm<RegisterDTO>({ mode: 'all' });

  const { i18n, t } = useTranslation();

  const { mutate: registerMutation, isLoading } = useRegister();

  const { mutate: mutateGoogleLogin } = useGoogleLogin();

  const [{ auth, demo }, dispatch] = useApp();

  const doSubmit = (values: RegisterDTO) => {
    if (step < 1) {
      setStep(step + 1);
    } else {
      registerMutation(
        {
          ...values,
          utm: Cookies.get('utm'),
          sessionId: Cookies.get('sessionId'),
          variant: Cookies.get('variant'),
        },
        {
          onSuccess: () => {
            setStep(2);
            setEmail(values.email);
          },
          onError: (error: any) => {
            if (error.message === 'UserExists') {
              toast.error(t('El usuario ya existe'));
            }
          },
        }
      );
    }
  };

  const handleLogin = (googleResponse: CredentialResponse) => {
    mutateGoogleLogin(
      {
        googleResponse: {
          clientId: googleResponse.clientId,
          credential: googleResponse.credential,
        },
        demo: demo,
        jwt: auth.access_token,
        utm: Cookies.get('utm'),
        sessionId: Cookies.get('sessionId'),
        variant: Cookies.get('variant'),
      },
      {
        onSuccess: (response) => {
          dispatch(UserActions.setToken(response));
        },
      }
    );
  };

  const handleMultiLogin = () => {
    const message = {
      type: 'signIn',
      data: 'googleSignIn',
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const promoterParam = searchParams.get('promoter');
    if (promoterParam) {
      methods.setValue('referredBy', promoterParam);
    }
  }, [location]);

  useListenLogin();

  return (
    <div className="d-flex flex-column flex-lg-row gap-4 bg-white">
      {isModal && (
        <div className="d-none d-lg-flex justify-content-center align-items-end popup-image rounded-4 position-relative">
          <div className="d-flex flex-column gap-2 justify-content-center mb-4">
            <p className="d-inline-block px-3 shadow-sm bg-white text-dark py-2 fw-semibold rounded-2">
              +{`${totalRestaurants} ${t('restaurantes ya')}`} 😍
            </p>
            <p
              className="d-inline-block px-3 shadow-lg bg-white text-dark py-2 fw-semibold align-self-end rounded-2"
              style={{ transform: 'rotate(2deg)' }}
            >
              {t('confían en nosotros')}
            </p>
          </div>
        </div>
      )}
      <div className="align-self-center align-items-center d-flex flex-column">
        <div className="mb-4 text-center">
          <h4 className="fw-bold mb-1">{t('Crea tu cuenta, es gratis')}</h4>

          <small className="d-flex d-lg-none align-items-center">
            <img
              src={usersImage}
              alt="Users image"
              style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
            />
            <div className="d-flex flex-column gap-1 align-items-start justify-content-center">
              <Stars />
              <p className="text-start">
                {`${totalRestaurants}`} {t('clientes ya confían')}
              </p>
            </div>
          </small>
        </div>
        {window.ReactNativeWebView ? (
          <Button
            variant="dark"
            className="fw-bold d-block w-100"
            onClick={handleMultiLogin}
            disabled={isLoading}
          >
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <GoogleIcon height="20px" width={'20px'} />
              <span>{t('Iniciar sesión con Google')}</span>
            </div>
          </Button>
        ) : (
          <GoogleLogin
            size="large"
            ux_mode="popup"
            context="signup"
            width={'300'}
            locale={i18n.language}
            theme="filled_black"
            state_cookie_domain=".meniuapp.com"
            onSuccess={handleLogin}
          />
        )}

        <div className="py-2 d-flex align-items-center w-100">
          <hr className="flex-grow-1 border" />
          <div className="px-3">
            <small className="small-xs">{t('O regístrate con tu email')}</small>
          </div>
          <hr className="flex-grow-1 border" />
        </div>
        <FormProvider {...methods}>
          <FormRegister
            step={step}
            doSubmit={doSubmit}
            isLoading={isLoading}
            nameRestaurant={nameRestaurant}
          />
        </FormProvider>
      </div>
    </div>
  );
};
