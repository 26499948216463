import React, {
  useContext,
  createContext,
  ReactNode,
  useReducer,
  useEffect,
} from 'react';
import {
  ImmerReducer,
  createActionCreators,
  createReducerFunction,
} from 'immer-reducer';
import { Order, Product, Room, Section, Table } from '@meniu/data';

import { useQueryState } from 'hooks/useQueryState';

export enum modalOptions {
  'view-share-payment-link' = 'view-share-payment-link',
  'edit-delivery-types' = 'edit-delivery-types',
  'unsaved-changes-product' = 'unsaved-changes-product',
  'unsaved-changes' = 'unsaved-changes',
  'send-feedback' = 'send-feedback',
  'allow-notifications' = 'allow-notifications',
  'edit-schedules-menu' = 'edit-schedules-menu',
  'edit-whatsapp-menu' = 'edit-whatsapp-menu',
  'edit-menu' = 'edit-menu',
  'publish-menu' = 'publish-menu',
  'published-menu' = 'published-menu',
  'edit-section' = 'edit-section',
  'edit-product' = 'edit-product',
  'delete-product' = 'delete-product',
  'preview-menu' = 'preview-menu',
  'share-menu' = 'share-menu',
  'delete-section' = 'delete-section',
  'delete-menu' = 'delete-menu',
  'delete-table' = 'delete-table',
  'delete-room' = 'delete-room',
  'add-product-to-order' = 'add-product-to-order',
  'create-order' = 'create-order',
  'payment-order' = 'payment-order',
  'register' = 'register',
  'loginWarning' = 'loginWarning',
  'order-details' = 'order-details',
  'edit-address-menu' = 'edit-address-menu',
  'edit-payments-menu' = 'edit-payments-menu',
  'plan-adquired' = 'plan-adquired',
  'upload-products' = 'upload-products',
  'upload-logo' = 'upload-logo',
  'buy-pro' = 'buy-pro',
  'edit-currency-menu' = 'edit-currency-menu',
  'change-alias' = 'change-alias',
  'set-table-terminal' = 'set-table-terminal',
  'complete-menu' = 'complete-menu',
  'upload-products-ai' = 'upload-products-ai',
  'create-table' = 'create-table',
  'create-client' = 'create-client',
  'promo-pro' = 'promo-pro',
  'receipt-modal-terminal' = 'receipt-modal-terminal',
  'upload-images' = 'upload-images',
  'create-payment-link' = 'create-payment-link',
  'view-payment-link' = 'view-payment-link',
  'payment-order-global' = 'payment-order-global',
  'add-extra-for-product' = 'add-extra-for-product',
  'cta-use-terminal' = 'cta-use-terminal',
}

class SettingsType {
  sectionEdited!: Section;
  productEdited!: Product;
  modal!: Array<modalOptions>;
  preview!: boolean;
  menuId!: string;
  order!: Order;
  newExtrasForProduct: Array<any> = [];
  unsavedChanges!: boolean;
  positionTable!: any;
  room!: Room;
  table!: Table;
  paymentLinkId?: string;
}

const sectionInitialValues = { _id: '', name: '' };
const productInitialValues = { _id: '', description: '', name: '', price: -1 };
const tableInitialValue = { _id: '', tableNumber: 0, numberOfPeople: 0 };

const initialState: SettingsType = {
  productEdited: { ...productInitialValues },
  sectionEdited: { ...sectionInitialValues },
  modal: [],
  preview: false,
  menuId: '',
  order: {
    name: '',
    active: true,
    passcode: '',
    tableId: '',
  },
  newExtrasForProduct: [],
  unsavedChanges: false,
  positionTable: {},
  room: {},
  paymentLinkId: '',
  table: { ...tableInitialValue },
};

interface ModalConfig {
  section?: Section;
  product?: Product;
  menuId?: string;
  order?: any;
  positionTable?: any;
  room?: Room;
  table?: Table;
  paymentLinkId?: string;
}

const addParam = (search: string, param: string, value: string) => {
  const urlParams = new URLSearchParams(search);
  urlParams.append(param, value);
  return Object.fromEntries(urlParams);
};

const removeParam = (search: string, param: string) => {
  const urlParams = new URLSearchParams(search);
  urlParams.delete(param);
  return Object.fromEntries(urlParams);
};

// const closeParams = () => {
//   console.log('aaaaa');

//   const [search, setSeatch] = useSearchParams();
//   setSeatch(removeParam(search.get('search') || '', 'modal'), {
//     replace: true,
//   });
// };

class MenuReducer extends ImmerReducer<typeof initialState> {
  setModal(name: Array<modalOptions> | modalOptions, props?: ModalConfig) {
    this.draftState.modal = Array.isArray(name) ? name : [name];
    if (props?.product) {
      this.draftState.productEdited = props.product;
    }
    if (props?.section) {
      this.draftState.sectionEdited = props.section;
    }
    if (props?.menuId) {
      this.draftState.menuId = props.menuId;
    }
    if (props?.order) {
      this.draftState.order = props.order;
    }
    if (props?.positionTable) {
      this.draftState.positionTable = props.positionTable;
    }
    if (props?.room) {
      this.draftState.room = props.room;
    }
    if (props?.table) {
      this.draftState.table = props.table;
    }
    if (props?.paymentLinkId) {
      this.draftState.paymentLinkId = props.paymentLinkId;
    }
  }
  setProductEdited(product: Product) {
    this.draftState.productEdited = product;
  }
  closeThisModal(modal: string) {
    this.draftState.modal = this.draftState.modal.filter((m) => m !== modal);
    // closeParams();
  }
  closeModal() {
    this.draftState.modal = [];
    this.draftState.productEdited = productInitialValues;
    this.draftState.sectionEdited = sectionInitialValues;
    this.draftState.menuId = '';
    this.draftState.order = {
      name: '',
      active: true,
      passcode: '',
      tableId: '',
    };
    this.draftState.newExtrasForProduct = [];
    this.draftState.positionTable = {};
    this.draftState.room = {};
    this.draftState.paymentLinkId = '';
    this.draftState.table = { ...tableInitialValue };
    // closeParams();
  }
  resetProductEdited() {
    this.draftState.productEdited = productInitialValues;
  }
  setUnsaved() {
    this.draftState.unsavedChanges = true;
  }
  setSaved() {
    this.draftState.unsavedChanges = false;
  }
  previewOrder() {
    this.draftState.preview = !this.draftState.preview;
  }
  updateNewExtrasForProduct(newExtras: Array<any>) {
    this.draftState.newExtrasForProduct = newExtras;
  }
  setRoom(room: Room) {
    this.draftState.room = room;
  }
  cleanTable() {
    this.draftState.table = { ...tableInitialValue };
  }

  setPositionTable(positionTable: any) {
    this.draftState.positionTable = positionTable;
  }
}

export const MenuStateContext =
  createContext<typeof initialState>(initialState);

export const MenuReducerFunction = createReducerFunction(MenuReducer);

export const MenuActions = createActionCreators(MenuReducer);

const MenuDispatchContext = createContext({});

function MenuProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [queryModal, setQueryModal] = useQueryState('modal');

  const [state, dispatch] = useReducer(MenuReducerFunction, {
    ...initialState,
    modal: queryModal || initialState.modal,
  });

  useEffect(() => {
    setQueryModal(state.modal);
  }, [state.modal]);

  return (
    <MenuStateContext.Provider value={{ ...state }}>
      <MenuDispatchContext.Provider value={dispatch}>
        {children}
      </MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  );
}

function useMenuState() {
  const context = useContext<typeof initialState>(MenuStateContext);
  if (context === undefined) {
    throw new Error('useMenuState must be used within a MenuProvider');
  }
  return context;
}

function useMenuDispatch() {
  const context = useContext(MenuDispatchContext);
  if (context === undefined) {
    throw new Error('useMenuDispatch must be used within a MenuProvider');
  }
  return context as React.Dispatch<unknown>;
}

function useMenuContext() {
  return [useMenuState(), useMenuDispatch()] as [
    typeof initialState,
    React.Dispatch<unknown>
  ];
}

export { MenuProvider, useMenuContext, useMenuState, useMenuDispatch };
