import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';

import { useTranslation } from 'react-i18next';
import { format as formatParser } from 'date-fns';

export const localeSelector = (locale = '') => {
  switch (locale) {
    case 'es':
      return es;
    case 'en':
      return en;
    case 'pt':
      return pt;
    default:
      return es;
  }
};

export const DateComponent = ({
  value,
  format,
}: {
  value: any;
  format?: string;
}) => {
  const { i18n } = useTranslation();
  return value ? (
    <>
      {formatParser(new Date(value), format || 'PPp', {
        locale: localeSelector(i18n.language),
      })}
    </>
  ) : null;
};
