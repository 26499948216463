import { Button, Modal } from 'react-bootstrap';
import { modalOptions } from 'context/menu.context';
import { UserActions, useApp } from 'context/global.context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function WarningModal() {
  const [{ modal }, dispatch] = useApp();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(UserActions.closeModal());
  };

  const handleClick = () => {
    handleClose();
    navigate('/logout');
  };

  return (
    <Modal
      show={modal?.includes(modalOptions.loginWarning)}
      onHide={handleClose}
      centered={true}
      contentClassName="p-2"
      className="mobile-modal"
    >
      <Modal.Header className="d-flex pt-3 pb-0">
        <span className="lead fw-bold">{t('¿Ya tienes una cuenta?')}</span>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <span>
          {t('Si inicias sesión se borrará el menú demo')}.
          {t('Te recomendamos inciar sesión si ya tenias un menú creado')}.
        </span>
        <div className="d-flex flex-column gap-3">
          <Button variant="dark" className="fw-bold" onClick={handleClick}>
            {t('Iniciar sesión')}
          </Button>
          <Button
            variant="link text-dark"
            className="fw-bold"
            onClick={handleClose}
          >
            {t('Cancelar')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WarningModal;
