import { ReactNode, createContext, useContext, useReducer } from 'react';
import { TipReducer, tipInitialState } from './class-tip';
import { createActionCreators, createReducerFunction } from 'immer-reducer';

export const TipStateContext =
  createContext<typeof tipInitialState>(tipInitialState);

export const TipReducerFunction = createReducerFunction(TipReducer);

export const TipActions = createActionCreators(TipReducer);

const TipDispatchContext = createContext({});

function TipProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [state, dispatch] = useReducer(TipReducerFunction, {
    ...tipInitialState,
  });

  return (
    <TipStateContext.Provider value={{ ...state }}>
      <TipDispatchContext.Provider value={dispatch}>
        {children}
      </TipDispatchContext.Provider>
    </TipStateContext.Provider>
  );
}

function useTipState() {
  const context = useContext<typeof tipInitialState>(TipStateContext);
  if (context === undefined) {
    throw new Error('useTipState must be used within a TipProvider');
  }
  return context;
}

function useTipDispatch() {
  const context = useContext(TipDispatchContext);
  if (context === undefined) {
    throw new Error('useTipDispatch must be used within a TipProvider');
  }
  return context as React.Dispatch<unknown>;
}

function useTipContext() {
  return [useTipState(), useTipDispatch()] as [
    typeof tipInitialState,
    React.Dispatch<unknown>
  ];
}

export { TipProvider, useTipContext, useTipState, useTipDispatch };
