import { TipActions, useTipContext } from '../context';
import { useTranslation } from 'react-i18next';

/**
 * Button to enable the user to input a custom tip
 * @returns component
 */
export const PercentageTipOther = () => {
  const [{ percentageSelected }, dispatch] = useTipContext();

  const percentageSelectedIsNull = percentageSelected === null;

  const setPercentageSelectedByOther = () => {
    dispatch(TipActions.setPercentageSelected(null));
    dispatch(TipActions.setTipValues());
  };
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={`fw-semibold p-2 rounded-2 flex-fill text-center border-0 ${
        percentageSelectedIsNull ? 'shadow-sm bg-white' : ''
      }`}
      onClick={setPercentageSelectedByOther}
    >
      {t('Otro')}
    </button>
  );
};
