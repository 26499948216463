import React from 'react';
import { Button } from 'react-bootstrap';
import { useCreateStripePayment } from 'apiClient/api';
import { useAppState } from 'context/global.context';
import { useParams } from 'react-router-dom';
import { sendAnalytics } from '@utils';

interface PurchaseButtonProps {
  variant: string;
  priceId: string;
  className?: string;
  text: string;
  onClick?: () => any;
}

export const PurchaseButton: React.FC<PurchaseButtonProps> = ({
  variant,
  priceId,
  className,
  text,
  onClick,
}) => {
  const { _menuId } = useParams();

  const { mutate, isLoading } = useCreateStripePayment();
  const { user } = useAppState();

  const handleClick = (price: string) => {
    if (isLoading) return;
    sendAnalytics('purchase-button', { userId: user.email });
    if (_menuId) {
      localStorage.setItem('menuIdPayment', _menuId);
    }
    mutate(
      { email: user.email || '', price },
      {
        onSuccess: ({ url }) => {
          onClick?.();
          window.location.href = url;
        },
      }
    );
  };

  return (
    <Button
      disabled={isLoading}
      variant={variant}
      className={className}
      onClick={() => handleClick(priceId)}
    >
      {text}
    </Button>
  );
};
