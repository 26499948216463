// libraries
import { useQueryClient } from 'react-query';
import produce from 'immer';

// libs.
import { Menu } from '@meniu/data';

// ui.
import { createMongoId } from '@meniu/ui';
import { MenuActions, useMenuContext } from 'context/menu.context';

export const useExtrasContext = ({ menuId }: { menuId?: string }) => {
  const queryClient = useQueryClient();

  const menuData = queryClient.getQueryData<Menu>(['menu', menuId]);
  const [{ newExtrasForProduct }, dispatch] = useMenuContext();

  const setQueryData = (data: Menu) =>
    queryClient.setQueryData(['menu', menuId], data);

  const addExtraToMenuAndProduct = (
    sectionId?: string,
    productId?: string,
    extra?: any
  ) =>
    setQueryData(
      produce((draft: Menu) => {
        extra._id = createMongoId();

        draft.extras?.push(extra);

        const section = draft.sections?.find(
          (section: any) => section._id === sectionId
        );
        if (section) {
          const product = section?.products?.find(
            (product: any) => product._id === productId
          );
          if (product) {
            if (!product.extras) {
              product.extras = [];
            }
            product.extras.push(extra);
          }
        }

        if (!sectionId || !productId) {
          dispatch(
            MenuActions.updateNewExtrasForProduct([
              ...newExtrasForProduct,
              extra,
            ])
          );
        }
      })
    );

  const removeExtraFromMenu = (extraId: string) => {
    dispatch(MenuActions.setUnsaved());
    setQueryData(
      produce((draft: Menu) => {
        draft.extras = draft.extras?.filter(
          (extra: any) => extra._id !== extraId
        );
      })
    );
  };

  const removeExtraFromProduct = (
    sectionId?: string,
    productId?: string,
    extraId?: string
  ) => {
    dispatch(MenuActions.setUnsaved());
    setQueryData(
      produce((draft: Menu) => {
        const section = draft.sections?.find(
          (section: any) => section._id === sectionId
        );
        if (section) {
          const product = section?.products?.find(
            (product: any) => product._id === productId
          );
          if (product && product.extras) {
            product.extras = product.extras.filter(
              (extra: any) => extra._id !== extraId
            );
          }
        }

        if (!sectionId || !productId) {
          const updatedExtras = newExtrasForProduct.filter(
            (extra: any) => extra._id !== extraId
          );
          dispatch(MenuActions.updateNewExtrasForProduct(updatedExtras));
        }
      })
    );
  };

  const updateProductExtra = (
    sectionId?: string,
    productId?: string,
    extraId?: string,
    newExtra?: any
  ) => {
    dispatch(MenuActions.setUnsaved());
    setQueryData(
      produce((draft: Menu) => {
        newExtra._id = extraId;

        const section = draft.sections?.find(
          (section: any) => section._id === sectionId
        );
        if (section) {
          const product = section?.products?.find(
            (product: any) => product._id === productId
          );
          if (product && product.extras) {
            const extraIndex = product?.extras?.findIndex(
              (extra: any) => extra._id === extraId
            );
            if (extraIndex !== -1) {
              product.extras[extraIndex] = newExtra;
            }
          }
        }

        if (!sectionId || !productId) {
          const updatedExtras = [...newExtrasForProduct];
          const extraIndex = updatedExtras.findIndex(
            (extra: any) => extra._id === extraId
          );
          if (extraIndex !== -1) {
            updatedExtras[extraIndex] = newExtra;
            dispatch(MenuActions.updateNewExtrasForProduct(updatedExtras));
          }
        }
      })
    );
  };

  // const addExtraToProduct = (
  //   sectionId: string,
  //   productId?: string,
  //   extraId?: string
  // ) => {
  //   setQueryData(
  //     produce((draft: Menu) => {
  //       const extraToBeAdded = draft.extras?.find(
  //         (extra: any) => extra._id === extraId
  //       );
  //       if (!extraToBeAdded) return;

  //       const section = draft.sections?.find(
  //         (section: any) => section._id === sectionId
  //       );
  //       if (section) {
  //         const product = section?.products?.find(
  //           (product: any) => product._id === productId
  //         );
  //         if (product) {
  //           if (!product.extras) {
  //             product.extras = [];
  //           }
  //           product.extras.push(extraToBeAdded);
  //         } else {
  //           debugger;
  //           dispatch(
  //             MenuActions.updateNewExtrasForProduct([
  //               ...newExtrasForProduct,
  //               extraToBeAdded,
  //             ])
  //           );
  //         }
  //       }
  //     })
  //   );
  // };

  const addExtraToProduct = (
    sectionId?: string,
    productId?: string,
    extraId?: string
  ) => {
    const extraToBeAdded = menuData?.extras?.find(
      (extra: any) => extra._id === extraId
    );
    if (!extraToBeAdded) return;

    dispatch(MenuActions.setUnsaved());

    setQueryData(
      produce((draft: Menu) => {
        const section = draft.sections?.find(
          (section: any) => section._id === sectionId
        );
        if (section) {
          const product = section?.products?.find(
            (product: any) => product._id === productId
          );
          if (product) {
            if (!product.extras) {
              product.extras = [];
            }
            product.extras.push(extraToBeAdded);
          }
        }
      })
    );

    if (!productId || !sectionId) {
      dispatch(
        MenuActions.updateNewExtrasForProduct([
          ...newExtrasForProduct,
          extraToBeAdded,
        ])
      );
    }
  };

  const getProductExtras = (sectionId?: string, productId?: string) => {
    const menuData = queryClient.getQueryData<Menu>(['menu', menuId]);
    if (!menuData) return [];

    if (!productId || !sectionId) {
      return newExtrasForProduct || [];
    }

    const section = menuData?.sections?.find(
      (section: any) => section._id === sectionId
    );

    if (!section) return [];

    const product = section?.products?.find(
      (product: any) => product._id === productId
    );
    if (!product) return [];

    return product.extras || [];
  };

  const getMenuDataExtras = (sectionId?: string, productId?: string) => {
    const menuData = queryClient.getQueryData<Menu>(['menu', menuId]);
    if (!menuData || !menuData.extras) return [];

    let productExtrasIds: any = [];
    const newExtrasIds: any = newExtrasForProduct
      ? newExtrasForProduct.map((extra: any) => extra._id)
      : [];

    if (sectionId && productId) {
      const section = menuData?.sections?.find(
        (section: any) => section._id === sectionId
      );

      if (section) {
        const product = section?.products?.find(
          (product: any) => product._id === productId
        );
        if (product && product.extras) {
          productExtrasIds = product.extras.map((extra: any) => extra._id);
        }
      }
    }

    const menuDataExtras = menuData.extras.filter(
      (extra: any) =>
        !productExtrasIds.includes(extra._id) &&
        !newExtrasIds.includes(extra._id)
    );

    return menuDataExtras;
  };

  return {
    menuData,
    addExtraToMenuAndProduct,
    removeExtraFromMenu,
    removeExtraFromProduct,
    updateProductExtra,
    addExtraToProduct,
    getProductExtras,
    getMenuDataExtras,
  };
};
