import { DonutProgress } from './styles';

interface InterfaceDonut {
  percentage: number;
  title: string;
  handleClick?: () => void;
}

export const CtaDonut = ({
  percentage,
  title,
  handleClick,
}: InterfaceDonut) => {
  return (
    <div
      className="position-fixed bottom-0 end-0 p-3 pointer d-none d-md-block"
      style={{ zIndex: 100 }}
      onClick={handleClick}
    >
      <div className="d-flex">
        <div className="bg-black d-flex align-items-center rounded-5 p-2 pe-4 gap-2 fw-semibold">
          <DonutProgress percentage={percentage} />
          <span className="text-white">{title}</span>
        </div>
      </div>
    </div>
  );
};
