import { toCurrency, allCountries } from '@meniu/ui';
import { useGetSubscription, useProfile } from 'apiClient/api';
import { useTranslation } from 'react-i18next';
import { DateComponent } from 'shared/date.component';

const Element = ({
  name,
  value,
  type,
}: {
  type?: 'date';
  name: string;
  value?: any;
}) =>
  value ? (
    <div className="d-flex justify-content-between">
      <span>{name}</span>
      <span>
        {type === 'date' ? <DateComponent value={value * 1000} /> : value}
      </span>
    </div>
  ) : null;

export const PaymentInfo = () => {
  const { t } = useTranslation();
  const { data: me = {}, isLoading: isLoadingProfile } = useProfile();

  const { data: subscription, isLoading: isLoadingGetSubscriptions } =
    useGetSubscription(me.subscription?.subscriptionId || '');

  if (subscription === undefined) return null;

  return (
    <div>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-column gap-2">
          <Element name={t('Tipo de plan')} value={t(subscription.status)} />

          {/* Esconder para que el usuario no vea el precio y piense en desuscribirse */}

          {/*  <Element
            name={t('total')}
            value={toCurrency(
              (subscription.amountInCurrency || subscription.plan?.amount) /
                100,
              Object.values(allCountries).find(
                (a) =>
                  a?.currency.toLowerCase() ===
                  subscription?.currency.toLowerCase()
              )?.locale || 'es-MX',
              subscription.currency || subscription.plan?.currency
            )}
          /> */}
        </div>
      </div>
    </div>
  );
};
