import { UserActions, useApp } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import defaultImage from 'assets/default-image.jpeg';
import { usePatchUser } from 'apiClient/api';

function PromoModal() {
  const [{ modal, user }, dispatch] = useApp();

  const { t } = useTranslation();

  const { mutate } = usePatchUser();

  useEffect(() => {
    if (
      user &&
      !user.trialExpired &&
      !user.roles?.includes('user_pro') &&
      !user.viewedModals?.includes(modalOptions['promo-pro'])
    ) {
      dispatch(UserActions.setModal(modalOptions['promo-pro']));
    }
  }, [user]);

  const handleClose = () => {
    if (!user) return;
    mutate({
      viewedModals: [...(user.viewedModals || []), modalOptions['promo-pro']],
    });
    dispatch(UserActions.closeModal());
  };

  return null;
  return (
    <Modal
      show={modal.includes(modalOptions['promo-pro'])}
      onHide={handleClose}
      centered
      className="mobile-modal"
    >
      <Modal.Header
        style={{ backgroundImage: `url(${defaultImage})`, height: '300px' }}
      ></Modal.Header>
      <Modal.Body className="d-flex flex-column gap-4 p-4"></Modal.Body>
      <Modal.Footer>
        <Button className="fw-bold" variant="dark" onClick={handleClose}>
          {t('Comienza a probar')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PromoModal;
