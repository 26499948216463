import {
  ActivateAccountDto,
  Address,
  AddressDocument,
  AdminDashboardResponse,
  CampaignsDocument,
  ChangePasswordDTO,
  Client,
  Cover,
  CreateAccountDTO,
  CreateAddressDto,
  CreateClientDto,
  CreateCoverDto,
  CreateDemoDTO,
  CreateMenuDto,
  CreateOrderDto,
  CreatePaymentLinkDto,
  CreateProductDto,
  CreateProductWidthSectionDto,
  CreateReservationDto,
  CreateStripeDto,
  DeleteProductDTO,
  FeedbackDTO,
  IStripeDoc,
  Invite,
  InviteToMenuDto,
  Log,
  LoginDTO,
  LoginGoogle,
  LoginResponse,
  Menu,
  Notification,
  Order,
  OrderDocument,
  PatchUserDTO,
  PaymentLink,
  PrintJob,
  Product,
  ProductDocument,
  PublishMenuDTO,
  RecoverDTO,
  RecoverResponse,
  RegisterDTO,
  RegisterResponse,
  Reservation,
  Section,
  StripeSubscription,
  UpdateAddressDto,
  UpdateCoverDto,
  UpdateMenuDto,
  UpdateOrderStatusDto,
  UpdateReservationDto,
  User,
  CreateExtraDto,
  VerifyDTO,
  MenuOrdersRequest,
} from '@meniu/data';
import { UpdateWriteOpResult } from 'mongoose';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { toast } from 'react-toastify';
import { fetcher } from './fetcher';
import {
  useAppDispatch,
  useAppState,
  UserActions,
} from 'context/global.context';
import produce from 'immer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Stripe from 'stripe';
import { useExtrasContext } from '../pages/menu/modals/extras/extras.hooks';
import i18n from '../../i18n';
// import {i18n} from 'react-i18next'

export const ErrorCodes = {
  TooManyAttempts: i18n.t(
    'Demasiados intentos, por favor recupera tu contraseña'
  ),
  GoogleRegisteredUser: i18n.t('Usuario registrado con Google'),
  'Bad Request': i18n.t('Usuario o contraseña incorrectos'),
};

interface IRetriveResponse {
  charges_enabled?: boolean;
  details_submitted?: boolean;
}

export enum ReservationStatusOptions {
  'confirmed' = 'confirmed',
  'canceled' = 'canceled',
  'pending' = 'pending',
  'completed' = 'completed',
}

interface PaginateResult<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  page?: number | undefined;
  totalPages: number;
  offset: number;
  prevPage?: number | null | undefined;
  nextPage?: number | null | undefined;
  pagingCounter: number;
  meta?: any;
  [customLabel: string]: T[] | number | boolean | null | undefined;
}

interface SuccessResponse {
  success: true;
}

export interface DateParams {
  startDate: string;
  endDate: string;
}

interface Convertion {
  withEmailCount: number;
  withoutEmailCount: number;
  date: string;
  dayOfWeek: string;
  conversionRate: number;
  total: number;
  landingViews: number;
}

interface ICountry {
  country: string;
}

interface RegisterNewsletter {
  email: string;
}

interface DeletedResponse {
  deletedCount: number;
}

export interface ILoginError {
  message: keyof typeof ErrorCodes;
  code: number;
}

type ErrorResponse<T> = {
  message: string | string[];
  statusCode: number;
};

const toParams = (params: any) => new URLSearchParams(params).toString();

export const useQR = <T extends string>() =>
  useMutation<any, ErrorResponse<T>, any>((body) =>
    fetcher({
      url: '/qr',
      method: 'POST',
      body,
    })
  );

export const useLogin = <T extends string>() =>
  useMutation<LoginResponse, ILoginError, LoginDTO>((body) =>
    fetcher({
      url: '/auth/login',
      method: 'POST',
      body,
    })
  );

export const useRegister = <T extends string>() =>
  useMutation<RegisterResponse, ErrorResponse<T>, RegisterDTO>((body) =>
    fetcher({
      url: '/auth/register',
      method: 'POST',
      body,
    })
  );

export const useRegisterToNewsletter = <T extends string>() =>
  useMutation<RegisterResponse, ErrorResponse<T>, RegisterNewsletter>((body) =>
    fetcher({
      url: '/newsletter',
      method: 'POST',
      body,
    })
  );

export const useRecoverPassword = <T extends string>() =>
  useMutation<RecoverResponse, ErrorResponse<T>, RecoverDTO>((body) =>
    fetcher({
      url: '/auth/recoverPassword',
      method: 'POST',
      body,
    })
  );

export const useChangePassword = <T extends string>() =>
  useMutation<LoginResponse, ErrorResponse<T>, ChangePasswordDTO>((body) =>
    fetcher({
      url: '/auth/changePassword',
      method: 'POST',
      body,
    })
  );

export const useVerify = <T extends string>() =>
  useMutation<LoginResponse, ErrorResponse<T>, VerifyDTO>((body) =>
    fetcher({
      url: '/auth/verify',
      method: 'POST',
      body,
    })
  );

export const useResendVerify = <T extends string>() =>
  useMutation<SuccessResponse, ErrorResponse<T>, { email: string }>((body) =>
    fetcher({
      url: '/auth/resend_verify',
      method: 'POST',
      body,
    })
  );

export const usePatchUser = <T extends string>() => {
  const dispatch = useAppDispatch();
  return useMutation<User, ErrorResponse<T>, PatchUserDTO>(
    (body) =>
      fetcher({
        url: '/users/me',
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (user) => {
        dispatch(UserActions.setUser(user));
      },
    }
  );
};

export const useProfile = <T extends string>(
  token?: string,
  options?: UseQueryOptions<User, any>
) =>
  useQuery<User, ErrorResponse<T>>(
    ['profile-me'],
    () =>
      fetcher({
        url: '/users/me',
        method: 'GET',
      }),
    { enabled: !!token, refetchOnWindowFocus: false, ...options }
  );

export const useReferredInfo = <T extends string>(
  token?: string,
  options?: UseQueryOptions<any, ErrorResponse<T>>
) =>
  useQuery<any, ErrorResponse<T>>(
    ['referred-info'],
    () =>
      fetcher({
        url: `/users/referrals`,
        method: 'GET',
      }),
    { enabled: !!token, refetchOnWindowFocus: false, ...options }
  );

export const useProducts = <T extends string>(
  props: any,
  options?: UseQueryOptions<PaginateResult<ProductDocument>, any>
) =>
  useQuery<PaginateResult<ProductDocument>, ErrorResponse<T>>(
    ['products', props],
    () =>
      fetcher({
        url: `/products?${toParams(props)}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, ...options }
  );

export const useProduct = <T extends string>(
  props: any,
  options?: UseQueryOptions<ProductDocument, any>
) =>
  useQuery<ProductDocument, ErrorResponse<T>>(
    ['product', props._id],
    () =>
      fetcher({
        url: `/products/${props._id}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, enabled: !!props._id, ...options }
  );

export const useMyMenus = <T extends string>(
  props: any,
  options?: UseQueryOptions<PaginateResult<Menu>, any>
) =>
  useQuery<PaginateResult<Menu>, ErrorResponse<T>>(
    ['my-menus', props],
    () =>
      fetcher({
        url: `/menu/mine?${toParams(props)}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: true, ...options }
  );

export const useGetMyManagedMenus = <T extends string>(
  props: any,
  options?: UseQueryOptions<PaginateResult<Menu>, any>
) =>
  useQuery<PaginateResult<Menu>, ErrorResponse<T>>(
    ['my-managed-menus', props],
    () =>
      fetcher({
        url: `/menu/mine-as-user?${toParams(props)}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: true, ...options }
  );

// export const useCampaigns = <T extends string>(
//   props: any,
//   options?: UseQueryOptions<PaginateResult<CampaignsDocument>, any>
// ) =>
//   useQuery<PaginateResult<CampaignsDocument>, ErrorResponse<T>>(
//     ['campaigns', props],
//     () =>
//       fetcher({
//         url: `/campaigns?${toParams(props)}`,
//         method: 'GET',
//       }),
//     { refetchOnWindowFocus: false, ...options }
//   );

export const useMenu = <T extends string>(
  props: { _id: string | undefined },
  options?: UseQueryOptions<Menu, any>
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useQuery<Menu, ErrorResponse<T>>(
    ['menu', props._id],
    () =>
      fetcher({
        url: `/menu/${props._id}`,
        method: 'GET',
      }),
    {
      enabled: !!props._id,
      onError: () => {
        toast.error(t('No se ha encontrado el menú'));
        navigate('/logout');
      },
      ...options,
    }
  );
};

export const useCreateDemoMenu = <T extends string>() => {
  return useMutation<
    { access_token: string; menu: Menu },
    ErrorResponse<T>,
    CreateDemoDTO
  >(
    (body) =>
      fetcher({
        url: '/menu/demo',
        method: 'POST',
        body,
      }),
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useInviteToMenu = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<SuccessResponse, ErrorResponse<T>, InviteToMenuDto>(
    (body) =>
      fetcher({
        url: '/users/invites',
        method: 'POST',
        body,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['invites', variables.menu]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useAcceptInviteToMenu = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<
    SuccessResponse,
    ErrorResponse<T>,
    { menuId: string; _id: string }
  >(
    (body) =>
      fetcher({
        url: `/users/invites/${body.menuId}/accept`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['my-invites']);
        queryClient.invalidateQueries(['my-managed-menus']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useGetMyInvites = <T extends string>(
  options?: UseQueryOptions<Array<Invite>, any>
) => {
  return useQuery<Array<Invite>, ErrorResponse<T>>(
    ['my-invites'],
    () =>
      fetcher({
        url: `/users/invites`,
        method: 'GET',
      }),
    { ...options, refetchOnWindowFocus: false }
  );
};

export const useGetInvitationsToMenu = <T extends string>(menuId: string) => {
  return useQuery<Array<Invite>, ErrorResponse<T>>(
    ['invites', menuId],
    () =>
      fetcher({
        url: `/users/invites/${menuId}`,
        method: 'GET',
      }),
    {}
  );
};

export const useRemoveWaiter = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<
    SuccessResponse,
    ErrorResponse<T>,
    { menuId?: string; email: string }
  >(
    (body) =>
      fetcher({
        url: `/users/invites/${body.menuId}`,
        method: 'DELETE',
        body,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['menu', variables.menuId]);
        queryClient.invalidateQueries(['invites', variables.menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useCreateMenu = <T extends string>() => {
  const query = useQueryClient();
  const { user } = useAppState();
  const { t } = useTranslation();
  return useMutation<Menu, ErrorResponse<T>, CreateMenuDto>(
    (body) =>
      fetcher({
        url: '/menu',
        method: 'POST',
        body,
      }),
    {
      onSuccess: (result) => {
        toast.success(t('Menu creado'));
        query.invalidateQueries(['menu', result._id]);
        query.invalidateQueries(['my-menus']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

// export const useUpdateExtras = <T extends string>(menuId?: string) => {
//   const { t } = useTranslation();

//   return useMutation<Menu, ErrorResponse<T>, CreateExtraDto>(
//     (body) =>
//       fetcher({
//         url: `/menu/${menuId}/extras`,
//         method: 'PATCH',
//         body,
//       }),
//     {
//       onSuccess: () => {
//         toast.success(t('Extras actualizados'));
//       },
//       onError: (error) => {
//         toast.error(error.message);
//       },
//     }
//   );
// };
export const useUpdateExtras = <T extends string>() => {
  const { t } = useTranslation();
  const query = useQueryClient();

  return useMutation<
    Menu,
    ErrorResponse<T>,
    {
      menuId: string;
      sectionId: string;
      productId: string;
      extraData: CreateExtraDto;
    }
  >(
    ({ menuId, sectionId, productId, extraData }) =>
      fetcher({
        url: `/menu/${menuId}/section/${sectionId}/product/${productId}/extras`,
        method: 'PATCH',
        body: extraData,
      }),
    {
      onSuccess: (result, variables) => {
        toast.success(t('Extras actualizados'));
        query.invalidateQueries(['menu', variables.menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useRemoveExtra = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();
  return useMutation<
    Menu,
    ErrorResponse<T>,
    { menuId: string; extraId: string }
  >(
    (body) =>
      fetcher({
        url: `/menu/${body.menuId}/extras/${body.extraId}`,
        method: 'DELETE',
      }),
    {
      onSuccess: (result, variables) => {
        toast.success(t('Extra eliminado con éxito'));
        query.invalidateQueries(['menu', variables.menuId]);
        // query.invalidateQueries(['my-menus']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useAssignExtraToProduct = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();

  return useMutation<
    Menu,
    ErrorResponse<T>,
    { menuId: string; sectionId: string; productId: string; extraId: string },
    { menuId: string }
  >(
    (body) =>
      fetcher({
        url: `/menu/${body.menuId}/section/${body.sectionId}/product/${body.productId}/extra/${body.extraId}`,
        method: 'PATCH',
      }),
    {
      onSuccess: (result, variables, context) => {
        toast.success(t('Extra asignado al producto con éxito'));
        query.invalidateQueries(['menu', variables.menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useRemoveExtraFromProduct = <T extends string>() => {
  const query = useQueryClient();

  return useMutation<
    Menu,
    ErrorResponse<T>,
    { menuId: string; sectionId: string; productId: string; extraId: string }
  >(
    (body) =>
      fetcher({
        url: `/menu/${body.menuId}/section/${body.sectionId}/product/${body.productId}/extra/${body.extraId}`,
        method: 'DELETE',
      }),
    {
      onSuccess: (result, variables, context) => {
        toast.success('Extra removed from product successfully');
        query.invalidateQueries(['menu', variables.menuId]);
      },
      onError: (error) => {
        toast.error(`Error removing extra: ${error.message}`);
      },
    }
  );
};

export const useUpdateExtraFromProduct = <T extends string>() => {
  const query = useQueryClient();

  return useMutation<
    Menu,
    ErrorResponse<T>,
    {
      menuId: string;
      sectionId: string;
      productId: string;
      extraId: string;
      extraData: CreateExtraDto;
    }
  >(
    ({ menuId, sectionId, productId, extraId, extraData }) =>
      fetcher({
        url: `/menu/update-extra/${menuId}/section/${sectionId}/product/${productId}/extra/${extraId}`,
        method: 'PATCH',
        body: extraData,
      }),
    {
      onSuccess: (result, variables, context) => {
        toast.success('Extra updated successfully');
        query.invalidateQueries(['menu', variables.menuId]);
      },
      onError: (error) => {
        toast.error(`Error updating extra: ${error.message}`);
      },
    }
  );
};

export const useDuplicateMenu = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();
  return useMutation<Menu, ErrorResponse<T>, { menuId: string }>(
    (body) =>
      fetcher({
        url: '/menu/duplicate',
        method: 'POST',
        body,
      }),
    {
      onSuccess: (result) => {
        toast.success(t('Menú duplicado con éxito'));
        query.invalidateQueries(['my-menus']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useAddress = <T extends string>(
  props: any,
  options?: UseQueryOptions<AddressDocument, any>
) =>
  useQuery<AddressDocument, ErrorResponse<T>>(
    ['address', props._id],
    () =>
      fetcher({
        url: `/address/${props._id}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, enabled: !!props._id, ...options }
  );

export const useCampaigns = <T extends string>(
  props: any,
  options?: UseQueryOptions<PaginateResult<CampaignsDocument>, any>
) =>
  useQuery<PaginateResult<CampaignsDocument>, ErrorResponse<T>>(
    ['campaigns', props],
    () =>
      fetcher({
        url: `/campaigns?${toParams(props)}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, ...options }
  );

export const useCampaign = <T extends string>(
  props: any = {},
  options?: UseQueryOptions<CampaignsDocument, any>
) =>
  useQuery<CampaignsDocument, ErrorResponse<T>>(
    ['campaign', props._id],
    () =>
      fetcher({
        url: `/campaigns/${props._id}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, enabled: !!props._id, ...options }
  );

export const useInfinityProducts = <T extends string>(
  options: UseInfiniteQueryOptions<PaginateResult<ProductDocument>, any>,
  queryFilter = {}
) =>
  useInfiniteQuery<PaginateResult<ProductDocument>, ErrorResponse<T>>(
    ['infinity-products', queryFilter],
    async ({ pageParam: page = 1 }) => {
      const params: any = { page, ...queryFilter };
      const query = new URLSearchParams(params).toString();
      const datas = await fetcher({
        url: `/products?${query}`,
        method: 'GET',
      });
      return datas;
    },
    options
  );

export const useAddresses = <T extends string>(
  options?: UseQueryOptions<AddressDocument[], any>
) => {
  const { user } = useAppState();
  return useQuery<AddressDocument[], ErrorResponse<T>>(
    ['address', user.email],
    () =>
      fetcher({
        url: '/address',
        method: 'GET',
      }),
    { enabled: !!user.email, refetchOnWindowFocus: false, ...options }
  );
};

export const useCreateAddress = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();
  const { user } = useAppState();
  return useMutation<Address, ErrorResponse<T>, CreateAddressDto>(
    (body) =>
      fetcher({
        url: '/address',
        method: 'POST',
        body,
      }),
    {
      onSuccess: (result) => {
        toast.success(t('Dirección creada'));
        query.invalidateQueries(['address', user.email]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useCreateProduct = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();
  return useMutation<Product, ErrorResponse<T>, CreateProductDto>(
    (body) =>
      fetcher({
        url: '/products',
        method: 'POST',
        body,
      }),
    {
      onSuccess: (result) => {
        toast.success(t('Producto creado'));
        query.invalidateQueries(['products']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

// export const useUpdateProduct = <T extends string>(_id: string) => {
//   const query = useQueryClient();
//   return useMutation<ProductDocument, ErrorResponse<T>, UpdateProductDto>(
//     (body) =>
//       fetcher({
//         url: `/products/${_id}`,
//         method: 'PATCH',
//         body,
//       }),
//     {
//       onSuccess: (result) => {
//         toast.success(t('Producto creado'));
//         query.invalidateQueries(['products']);
//       },
//       onError: (error) => {
//         toast.error(error.message);
//       },
//     }
//   );
// };

export const useBulkCreateProducts = <T extends string>(menuId: string) => {
  const query = useQueryClient();
  return useMutation<
    any,
    ErrorResponse<T>,
    Array<CreateProductWidthSectionDto>
  >(
    (body) =>
      fetcher({
        url: `/menu/${menuId}/bulk-create-products`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: (result) => {
        query.invalidateQueries(['menu', menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useUpdateAddress = <T extends string>(_id: string) => {
  const query = useQueryClient();
  const { user } = useAppState();
  const { t } = useTranslation();
  return useMutation<UpdateWriteOpResult, ErrorResponse<T>, UpdateAddressDto>(
    (body) =>
      fetcher({
        url: `/address/${_id}`,
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (result) => {
        if (result.modifiedCount > 0) {
          toast.success(t('Dirección actualizada'));
          query.invalidateQueries(['address', user.email]);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteAddress = <T extends string>() => {
  const query = useQueryClient();
  const { user } = useAppState();
  const { t } = useTranslation();
  return useMutation<DeletedResponse, ErrorResponse<T>, string>(
    (_id) =>
      fetcher({
        url: `/address/${_id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: (result) => {
        if (result.deletedCount) {
          toast.success(t('Dirección eliminada'));
        } else {
          toast.error(t('La dirección no se ha podido eliminar'));
        }
        query.invalidateQueries(['address', user.email]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteCover = <T extends string>() => {
  const query = useQueryClient();
  const { t } = useTranslation();
  return useMutation<DeletedResponse, ErrorResponse<T>, string>(
    (_id) =>
      fetcher({
        url: `/covers/${_id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: (result) => {
        if (result.deletedCount) {
          toast.success(t('Portada eliminada'));
        } else {
          toast.error(t('La portada no se ha podido eliminar'));
        }
        query.invalidateQueries(['covers']);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteUser = () => {
  const query = useQueryClient();
  return useMutation<any, any, any>(
    (_id) =>
      fetcher({
        url: `/users/${_id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        query.invalidateQueries(['dashboard-admin']);
        query.invalidateQueries(['dashboard-admin-menus']);
      },
    }
  );
};

interface UploadedFile {
  Location: string;
}
export const useUpload = <T extends string>(options = {}) => {
  return useMutation<Array<UploadedFile>, ErrorResponse<T>, File[]>(
    (files) => {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      return fetcher({
        url: `/files`,
        method: 'POST',
        body: formData,
      });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      ...options,
    }
  );
};

export const useUploadFile = <T extends string>(options = {}) => {
  return useMutation<Array<UploadedFile>, ErrorResponse<T>, File[]>(
    (files) => {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      return fetcher({
        url: `/files/file`,
        method: 'POST',
        body: formData,
      });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      ...options,
    }
  );
};

export interface UploadProductsResponse {
  products: Array<CreateProductWidthSectionDto>;
  errors: Array<any>;
}

export const useUploadProducts = <T extends string>(
  { menuId }: { menuId: string },
  options = {}
) => {
  return useMutation<UploadProductsResponse, ErrorResponse<T>, File[]>(
    (files) => {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      return fetcher({
        url: `/menu/readxls?menuId=${menuId}`,
        method: 'POST',
        body: formData,
      });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      ...options,
    }
  );
};

const updateMenu = (menu: Menu, newMenu: Menu) =>
  produce(menu, (draft) => {
    draft.name = newMenu.name || menu.name;
    draft.paymentMethods = newMenu.paymentMethods || menu.paymentMethods;
    draft.sections = newMenu.sections || menu.sections;
    draft.schedules = newMenu.schedules || menu.schedules;
    draft.address = newMenu.address || menu.address;
    draft.isPublished = newMenu.isPublished || menu.isPublished;
    draft.image = newMenu.image || menu.image;
    draft.deliveryMethods = newMenu.deliveryMethods || menu.deliveryMethods;
    draft.enableWhatsapp = newMenu.enableWhatsapp || menu.enableWhatsapp;
    draft.theme = newMenu.theme;
  });

const addSection = (menu: Menu, newSection: Section) =>
  produce(menu, (draft) => {
    draft.sections?.push(newSection);
  });

const deleteSection = (menu: Menu, sectionId: string) =>
  produce(menu, (draft) => {
    const sectionIndex = draft.sections?.findIndex(
      (section: Section) => section._id === sectionId
    );

    if (sectionIndex === -1 || sectionIndex === undefined) return;

    draft?.sections?.splice(sectionIndex, 1);
  });

const updateSection = (menu: Menu, sectionId: string, newSection: Section) =>
  produce(menu, (draft) => {
    const section = draft.sections?.find((s) => s._id === sectionId);
    if (!section) return;
    section.name = newSection.name;
  });

const addProduct = (menu: Menu, sectionId: string, product: Product) =>
  produce(menu, (draft) => {
    draft.sections?.find((s) => s._id === sectionId)?.products?.push(product);
  });

const editProduct = (
  menu: Menu,
  sectionId: string,
  product: Product,
  oldSectionId?: string
) =>
  produce(menu, (draft) => {
    // Change section
    if (!oldSectionId) {
      const productToUpdate = draft?.sections
        ?.find((s) => s._id === sectionId)
        ?.products?.find((p) => p._id === product._id);

      if (productToUpdate) {
        productToUpdate.name = product.name;
        productToUpdate.price = product.price;
        productToUpdate.description = product.description;
        productToUpdate.image = product.image;
        productToUpdate.isDisabled = product.isDisabled;
        productToUpdate.images = product.images;
      }
    } else if (oldSectionId !== sectionId) {
      const oldSectionIndex = menu.sections?.findIndex(
        (s) => s._id === oldSectionId
      );

      if (oldSectionIndex === -1 || oldSectionIndex === undefined) return;

      const productIndex = draft.sections?.[
        oldSectionIndex
      ].products?.findIndex((p) => p._id === product._id);

      if (productIndex === -1 || productIndex === undefined) return;

      draft.sections?.[oldSectionIndex].products?.splice(productIndex, 1);

      const sectionIndex = draft.sections?.findIndex(
        (s) => s._id === sectionId
      );

      if (sectionIndex === -1 || sectionIndex === undefined) return;

      draft.sections?.[sectionIndex].products?.push(product);
    } else {
      //same section
      const sectionIndex = draft.sections?.findIndex(
        (s) => s._id === sectionId
      );

      if (sectionIndex === -1 || sectionIndex === undefined) return;

      const productIndex = draft.sections?.[sectionIndex]?.products?.findIndex(
        (p) => p._id === product._id
      );

      if (productIndex === -1 || productIndex === undefined) return;

      const productToUpdate =
        draft.sections?.[sectionIndex].products?.[productIndex];

      if (!productToUpdate) return;

      productToUpdate.name = product.name;
      productToUpdate.price = product.price;
      productToUpdate.description = product.description;
      productToUpdate.image = product.image;
      productToUpdate.images = product.images;
      productToUpdate.isDisabled = product.isDisabled;
    }
  });

const deleteProduct = (
  menu: Menu,
  sectionId: string,
  product: DeleteProductDTO
) =>
  produce(menu, (draft) => {
    const section = draft.sections?.find((s) => s._id === sectionId);
    if (!section) return;
    if (!section.products?.length) return;

    section.products.filter((p) => p._id === product._id);
  });

interface CreateSectionDTO extends Menu {
  newSection: Section;
}
export const useCreateSection = <T extends string>({
  menuId,
  sectionId,
}: {
  menuId?: string;
  sectionId?: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<CreateSectionDTO, ErrorResponse<T>, Section>(
    (body) =>
      fetcher({
        url: `/menu/${menuId}/section/${sectionId ? sectionId : ''}`,
        method: sectionId ? 'PATCH' : 'POST',
        body,
      }),
    {
      onMutate: async (newSection) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu?.sections) return {};

        if (sectionId) {
          queryClient.setQueryData(
            ['menu', menuId],
            updateSection(menu, sectionId, newSection)
          );
        } else {
          queryClient.setQueryData(
            ['menu', menuId],
            addSection(menu, newSection)
          );
          // setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 200);
        }

        return { menu, newSection };
      },
      onError: (err, newSection, context: any) => {
        queryClient.setQueryData(['menu', menuId], context.menu);
        toast.error(err.message);
      },
      onSettled: (menu) => {
        queryClient.setQueryData(['menu', menuId], menu);
        // queryClient.invalidateQueries(['menu', menuId]);
      },
    }
  );
};

export const useDeleteSection = <T extends string>({
  menuId,
  sectionId,
}: {
  menuId?: string;
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>>(
    () =>
      fetcher({
        url: `/menu/${menuId}/section/${sectionId}`,
        method: 'DELETE',
      }),
    {
      onMutate: async (newSection) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu?.sections) return {};

        queryClient.setQueryData(
          ['menu', menuId],
          deleteSection(menu, sectionId)
        );

        return { menu, newSection };
      },
      onError: (err, newSection, context: any) => {
        queryClient.setQueryData(['menu', menuId], context.menu);
        toast.error(err.message);
      },
      onSettled: (menu) => {
        queryClient.setQueryData(['menu', menuId], menu);
        // queryClient.invalidateQueries(['menu', menuId]);
      },
    }
  );
};

export const useAddProduct = <T extends string>({
  menuId,
  sectionId,
  productId,
  oldSectionId,
}: {
  menuId?: string;
  sectionId: string;
  productId?: string;
  oldSectionId?: string;
}) => {
  const queryClient = useQueryClient();
  const { menuData } = useExtrasContext({
    menuId,
  });
  const { mutate: mutateMenu } = useUpdateMenu({ menuId });
  return useMutation<Menu, ErrorResponse<T>, Product>(
    (body) =>
      fetcher({
        url: `/menu/${menuId}/section/${sectionId}/product/${
          productId ? productId : ''
        }${oldSectionId ? `?oldSectionId=${oldSectionId}` : ''}`,
        method: productId ? 'PATCH' : 'POST',
        body,
      }),
    {
      onMutate: async (newProduct) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu?.sections) return {};

        if (productId) {
          queryClient.setQueryData(
            ['menu', menuId],
            editProduct(
              menu,
              sectionId,
              { ...newProduct, _id: productId },
              oldSectionId
            )
          );
        } else {
          queryClient.setQueryData(
            ['menu', menuId],
            addProduct(menu, sectionId, newProduct)
          );
        }
        // const el = document.getElementById(
        //   `section-${oldSectionId || sectionId}`
        // );

        // const elActive = document.getElementById(
        //   `section-${oldSectionId || sectionId}-active`
        // );

        // el?.scrollIntoView();
        // el?.click();
        // elActive?.scrollIntoView();

        return { menu, newProduct };
      },
      onError: (err, newSection, context: any) => {
        queryClient.setQueryData(['menu', menuId], context.menu);
        toast.error(err.message);
      },
      onSettled: (menu) => {
        queryClient.setQueryData(['menu', menuId], menu);
        // queryClient.invalidateQueries(['menu', menuId]);
      },
      onSuccess: (menu) => {
        mutateMenu({
          _id: menuId,
          extras: menuData?.extras || [],
          // sections: menuData?.sections,
        });
      },
    }
  );
};

export const useDeleteProduct = <T extends string>({
  menuId,
  sectionId,
}: {
  menuId?: string;
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>, DeleteProductDTO>(
    (body) =>
      fetcher({
        url: `/menu/${menuId}/section/${sectionId}/product/${body._id}`,
        method: 'DELETE',
      }),
    {
      onMutate: async (newProduct) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu?.sections) return {};

        queryClient.setQueryData(
          ['menu', menuId],
          deleteProduct(menu, sectionId, newProduct)
        );

        return { menu, newProduct };
      },
      onError: (err, newSection, context: any) => {
        queryClient.setQueryData(['menu', menuId], context.menu);
        toast.error(err.message);
      },
      onSettled: (menu) => {
        queryClient.setQueryData(['menu', menuId], menu);
        // queryClient.invalidateQueries(['menu', menuId]);
      },
    }
  );
};

export const usePublishMenu = <T extends string>({ _id }: { _id?: string }) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>, PublishMenuDTO>(
    (body) =>
      fetcher({
        url: `/menu/${_id}/publish`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['menu', _id]);
      },
    }
  );
};

export const useCreatePrintJob = <T extends string>() => {
  return useMutation<SuccessResponse, ErrorResponse<T>, PrintJob>((body) =>
    fetcher({
      url: `/printer/jobs`,
      method: 'POST',
      body,
    })
  );
};

export const usePrintTicket = () => {
  return useMutation(({ url, ...rest }: any) => {
    // Verificar si la URL comienza con "http://"
    // if (!url.startsWith('http://')) {
    //   throw new Error('Solo se permiten peticiones HTTP');
    // }
    return fetch(url, {
      ...rest,
    });
  });
};

export const useCreateOrder = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Order, ErrorResponse<T>, CreateOrderDto>(
    (body) =>
      fetcher({
        url: body._id ? `/orders/${body._id}` : '/orders',
        method: body._id ? 'PATCH' : 'POST',
        body,
      }),
    {
      onSuccess: (vars) => {
        queryClient.invalidateQueries(['menu', vars.menu]);
      },
    }
  );
};

export const useMutateOrder = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Order, ErrorResponse<T>, UpdateOrderStatusDto>(
    (body) =>
      fetcher({
        url: `/orders/${body._id}`,
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (order) => {
        queryClient.invalidateQueries(['order', order._id]);
        queryClient.invalidateQueries(['menu-orders']);
        queryClient.invalidateQueries(['menu', order.menu]);
        //TODO when archiving from kitchen, it also hides the orders from orders
      },
    }
  );
};

export const useMutateRegisterPayment = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Order, ErrorResponse<T>, any>(
    (body) =>
      fetcher({
        url: `/orders/${body._id}/register-payment`,
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (order) => {
        queryClient.invalidateQueries(['order', order._id]);
        queryClient.invalidateQueries(['menu-orders']);
        queryClient.invalidateQueries(['menu', order.menu]);
      },
    }
  );
};

export const useMutateProductOrder = <T extends string>({
  orderId,
}: {
  orderId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<
    Order,
    ErrorResponse<T>,
    { _id: string; dispatched: boolean }
  >(
    (body) =>
      fetcher({
        url: `/orders/${orderId}/${body._id}`,
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (order) => {
        queryClient.invalidateQueries(['menu-orders']);
      },
    }
  );
};

export const useGetMyOpenOrders = () =>
  useQuery<Array<Order>, ErrorResponse<string>>(
    ['all-my-orders'],
    () =>
      fetcher({
        url: '/orders/mine',
        method: 'GET',
      }),
    {}
  );

export const useGetMenuOrders = (
  menuOrders: MenuOrdersRequest,
  options?: UseQueryOptions<any, any>
) => {
  return useQuery<[Order], ErrorResponse<string>>(
    ['menu-orders', menuOrders],
    () =>
      fetcher({
        url: `/orders/menu/${menuOrders.menuId}`,
        body: menuOrders,
        method: 'POST',
      }),
    {
      ...options,
      refetchOnWindowFocus: true,
    }
  );
};

export const useGetTotalOrders = (
  { menuId, date }: { menuId: string; date: Date },
  options?: UseQueryOptions<any, any>
) => {
  return useQuery<
    { day: number; month: number; year: number },
    ErrorResponse<string>
  >(
    ['menu-total', { menuId, date }],
    () =>
      fetcher({
        url: `/orders/total/${menuId}/${date.toISOString()}`,
        method: 'GET',
      }),
    {
      ...options,
    }
  );
};

function updateOrderStatus(
  orders: { _id: string; orders: [Order] }[],
  orderId: string,
  newStatus: string
): void {
  for (const key in orders) {
    const ordersOfStatus = orders[key];
    const index = ordersOfStatus.orders.findIndex(
      (order) => order._id === orderId
    );
    if (index !== -1) {
      ordersOfStatus.orders[index].status = newStatus;
      break;
    }
  }
}
export const useUpdateMenuOrder = <T extends string>(
  menuOrders: MenuOrdersRequest
) => {
  const queryClient = useQueryClient();
  return useMutation<Order, ErrorResponse<T>, Order>(
    (body: Order) =>
      fetcher({
        url: `/orders/public/${body._id}`,
        method: 'PATCH',
        body,
      }),
    {
      onMutate: async (order) => {
        await queryClient.cancelQueries(['menu-orders', menuOrders]);
        const oldorders =
          queryClient.getQueryData<[Order]>(['menu-orders', menuOrders]) || [];
        queryClient.setQueryData(
          ['menu-orders', menuOrders],
          oldorders.map((o) => ({
            ...o,
            status: o._id === order._id ? order.status : o.status,
          }))
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['menu-orders'] });
      },
    }
  );
};

export const useGetOrder = <T extends string>(
  _id: string,
  options?: UseQueryOptions<any, any>
) =>
  useQuery<OrderDocument, ErrorResponse<T>, Order>(
    ['order', _id],
    () =>
      fetcher({
        url: `/orders/${_id}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false, enabled: !!_id, ...options }
  );

export const useUpdateMenuAlias = <T extends string>({
  menuId,
}: {
  menuId?: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>, { alias: string; _id: string }>(
    (body) =>
      fetcher({
        url: `/menu/${body._id}/alias`,
        method: 'PATCH',
        body,
      }),
    {
      onMutate: async (result: any) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu) return;

        queryClient.setQueryData(['menu', menuId], updateMenu(menu, result));
      },
      onSettled: (result) => {
        queryClient.invalidateQueries(['menu', menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useUpdateMenu = <T extends string>({
  menuId,
}: {
  menuId?: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>, UpdateMenuDto>(
    (body) =>
      fetcher({
        url: `/menu/${body._id}`,
        method: 'PUT',
        body,
      }),
    {
      onMutate: async (result: any) => {
        await queryClient.cancelQueries(['menu', menuId]);

        const menu = queryClient.getQueryData<Menu>(['menu', menuId]);

        if (!menu) return;

        queryClient.setQueryData(['menu', menuId], updateMenu(menu, result));
      },
      onSettled: (result) => {
        queryClient.invalidateQueries(['menu', menuId]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteMenu = <T extends string>({
  menuId,
}: {
  menuId?: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Menu, ErrorResponse<T>, any>(
    () =>
      fetcher({
        url: `/menu/${menuId}`,
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['my-menus']);
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );
};

export const useFeedback = <T extends string>() => {
  return useMutation<SuccessResponse, ErrorResponse<T>, FeedbackDTO>((body) =>
    fetcher({
      url: '/feedback',
      method: 'POST',
      body,
    })
  );
};

export const usePrintQR = <T extends string>() => {
  return useMutation<SuccessResponse, ErrorResponse<T>, { text: string }>(
    (body) =>
      fetcher({
        url: '/qr',
        method: 'POST',
        body,
      })
  );
};

export const useLoginCanny = <T extends string>() => {
  return useMutation<{ url: string }, ErrorResponse<T>>(() =>
    fetcher({
      url: '/auth/canny',
      method: 'POST',
    })
  );
};

export const useCreateStripePayment = <T extends string>() => {
  return useMutation<{ url: string }, ErrorResponse<T>, CreateStripeDto>(
    (body) =>
      fetcher({
        url: '/stripe/subscription',
        method: 'POST',
        body,
      })
  );
};

export const useCreateStripePaymentLink = <T extends string>() => {
  return useMutation<
    {
      paymentLink: Stripe.PaymentLink;
      meniuPaymentLink: PaymentLink;
    },
    ErrorResponse<T>,
    CreatePaymentLinkDto
  >((body) =>
    fetcher({
      url: '/stripe/payment_links',
      method: 'POST',
      body,
    })
  );
};

export const useGetPaymentLinks = () =>
  useQuery<Array<PaymentLink>>(
    ['payment_links'],
    () =>
      fetcher({
        url: '/stripe/payment_links',
        method: 'GET',
      }),
    {}
  );

export const useGetPaymentLink = (paymentLinkId?: string) => {
  const queryClient = useQueryClient();
  return useQuery<{
    paymentLink: Stripe.PaymentLink;
    meniuPaymentLink: PaymentLink;
  }>(
    ['payment_link', paymentLinkId],
    () =>
      fetcher({
        url: `/stripe/payment_links/${paymentLinkId}`,
        method: 'GET',
      }),
    {
      enabled: !!paymentLinkId,
      onSuccess: () => {
        queryClient.invalidateQueries(['payment_links']);
      },
    }
  );
};
export const useGoogleLogin = <T extends string>() => {
  return useMutation<LoginResponse, ErrorResponse<T>, LoginGoogle>((body) =>
    fetcher({
      url: '/auth/google',
      method: 'POST',
      body,
    })
  );
};

export const useRefreshToken = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<LoginResponse, ErrorResponse<T>, any>(
    () =>
      fetcher({
        url: '/auth/refreshToken',
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profile-me']);
      },
    }
  );
};

export const useAdminDashboard = () =>
  useQuery<AdminDashboardResponse>(
    ['dashboard-admin'],
    () =>
      fetcher({
        url: '/admin',
        method: 'GET',
      }),
    {}
  );

export const useGetPrices = (options?: UseQueryOptions<any>) =>
  useQuery<Array<Stripe.Price>>(
    ['prices'],
    () =>
      fetcher({
        url: '/stripe/prices',
        method: 'GET',
      }),
    options
  );

export const useAdminPromoters = () =>
  useQuery<any>(
    ['promoters-with-referrals'],
    () =>
      fetcher({
        url: '/admin/promoters-with-referrals',
        method: 'GET',
      }),
    {}
  );

export const useAdminTracker = () =>
  useQuery<AdminDashboardResponse>(
    ['dashboard-tracker'],
    () =>
      fetcher({
        url: '/admin',
        method: 'GET',
      }),
    {}
  );

export const useAdminMenus = ({
  sort,
  asc,
}: {
  sort?: string;
  asc?: boolean;
}) =>
  useQuery(
    ['dashboard-admin-menus', { sort, asc }],
    () =>
      fetcher({
        url: `/admin/menus?sort=${sort}&asc=${asc}`,
        method: 'GET',
      }),
    {}
  );

export const useAdminOrders = () =>
  useQuery(
    ['dashboard-admin-orders'],
    () =>
      fetcher({
        url: `/admin/orders`,
        method: 'GET',
      }),
    {}
  );

export const useAnalytics = () =>
  useMutation(
    (params: DateParams) => {
      const searchParams = new URLSearchParams({ ...params });
      return fetcher({
        url: `/admin/aitycs?${searchParams.toString()}`,
        method: 'GET',
      });
    },

    {}
  );

export const useGetConversions = () =>
  useQuery<Array<Convertion>>(
    ['convertions'],
    () =>
      fetcher({
        url: `/admin/conversions`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false }
  );

export const useGetNotifications = () => {
  const { demo, isLogged } = useAppState();
  return useQuery<Array<Notification>>(
    ['notifications'],
    () =>
      fetcher({
        url: `/notifications`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: true, enabled: !demo && isLogged }
  );
};

export const useNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (_id: string) =>
      fetcher({
        url: `/notifications/${_id}`,
        method: 'PATCH',
        body: {
          read: true,
        },
      }),
    {
      onMutate: async (variables: any) => {
        await queryClient.cancelQueries(['notifications']);

        const notifications = queryClient.getQueryData<Array<Notification>>([
          'notifications',
        ]);
        if (!Array.isArray(notifications)) return;

        queryClient.setQueryData(
          ['notifications'],
          [
            ...notifications.map((n) =>
              n._id === variables._id ? { ...n, read: true } : n
            ),
          ]
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications']);
      },
    }
  );
};

export const useTotalViews = ({ menuId }: { menuId?: string }) =>
  useQuery(
    ['totalViewsMenu'],
    () =>
      fetcher({
        url: `/aytics/total-views/${menuId}`,
        method: 'GET',
      }),
    { enabled: !!menuId, refetchOnWindowFocus: false }
  );

export const useGetCountry = (options?: UseQueryOptions<ICountry>) =>
  useQuery<ICountry>(
    ['country'],
    () =>
      fetcher({
        url: `/aytics/get-country`,
        method: 'GET',
      }),
    options
  );

export const useUserPortal = <T extends string>(enabled = false) => {
  return useQuery<any, ErrorResponse<T>, any>(
    ['portal'],
    (body) =>
      fetcher({
        url: '/stripe/portal',
        method: 'POST',
        body,
      }),
    {
      onError: (error) => {
        toast.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
};

export const useViewsMenus = <T extends string>() => {
  return useQuery<any, ErrorResponse<T>, any>(
    ['admin-menuviews'],
    () =>
      fetcher({
        url: '/admin/menuviews',
        method: 'GET',
      }),
    {
      onError: (error) => {
        toast.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useViewsLanding = <T extends string>() => {
  return useQuery<any, ErrorResponse<T>, any>(
    ['admin-landingviews'],
    () =>
      fetcher({
        url: '/admin/landingviews',
        method: 'GET',
      }),
    {
      onError: (error) => {
        toast.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useOpenai = () =>
  useMutation((body: any) =>
    fetcher({
      url: `/openai`,
      method: 'POST',
      body,
    })
  );

export const useOpenaiAudio = () =>
  useMutation(
    (body: any) => {
      return fetcher({
        url: `/openai/audio`,
        method: 'POST',
        body,
      });
    },
    { retry: false }
  );

export const useCreateQuery = () =>
  useMutation(
    (body: any) => {
      return fetcher({
        url: `/admin/query`,
        method: 'POST',
        body,
      });
    },
    { retry: false }
  );

export const useCreateStripeAccount = <T extends string>() => {
  const queryclient = useQueryClient();
  return useMutation<{ url: string }, ErrorResponse<T>, CreateAccountDTO>(
    (body) =>
      fetcher({
        url: '/stripe/express_account',
        method: 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryclient.invalidateQueries(['profile-me']);
      },
    }
  );
};

export const useCreateExpressLogin = <T extends string>() => {
  return useMutation<{ url: string }, ErrorResponse<T>, any>((body) =>
    fetcher({
      url: '/stripe/express_login',
      method: 'POST',
      body,
    })
  );
};

export const useRetrieveStripeAccount = <T extends string>(
  options?: UseQueryOptions<any, ErrorResponse<T>>
) => {
  return useQuery<
    IRetriveResponse,
    ErrorResponse<T>,
    Stripe.Response<Stripe.Account>
  >(
    ['stripe_account'],
    () =>
      fetcher({
        url: '/stripe/retrieve_account',
        method: 'GET',
      }),
    { ...(options || {}), refetchOnWindowFocus: false }
  );
};

export const useActivatePicker = <T extends string>() => {
  return useMutation<Menu, ErrorResponse<T>, ActivateAccountDto>((body) =>
    fetcher({
      url: '/delivery/activate',
      method: 'POST',
      body,
    })
  );
};

export const useGetSubscription = <T extends string>(
  subscriptionId: string
) => {
  return useQuery<StripeSubscription, ErrorResponse<T>>(
    ['my-subscription'],
    () =>
      fetcher({
        url: `/stripe/subscription/${subscriptionId}`,
        method: 'GET',
      }),
    { enabled: !!subscriptionId, refetchOnWindowFocus: false }
  );
};

export const useGetLogs = <T extends string>({
  type,
}: {
  type: Array<string>;
}) => {
  return useInfiniteQuery<
    PaginateResult<{ date: Date; events: Array<Log> }>,
    ErrorResponse<T>
  >(
    ['logs', type],
    ({ pageParam = 1 }) =>
      fetcher({
        url: `/logs?${toParams({ page: pageParam, limit: 20, type })}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false }
  );
};

export const useGetStripeLogs = <T extends string>() => {
  return useInfiniteQuery<PaginateResult<IStripeDoc>, ErrorResponse<T>>(
    ['stripe-logs'],
    ({ pageParam = 1 }) =>
      fetcher({
        url: `/logs/stripe?${toParams({ page: pageParam, limit: 20 })}`,
        method: 'GET',
      }),
    { refetchOnWindowFocus: false }
  );
};

export const useCloseTableOrder = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<
    SuccessResponse,
    ErrorResponse<T>,
    { tableOrderId: string }
  >(
    (body) =>
      fetcher({
        url: `/orders/close`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('orders');
      },
    }
  );
};

export const useGetAllOrdersByTable = <T extends string>(tableId?: string) => {
  return useQuery<Array<Order>, ErrorResponse<T>>(
    ['tableorders-all-by-table', tableId],
    () =>
      fetcher({
        url: `/orders/all-by-table/${tableId}`,
        method: 'GET',
      }),
    { enabled: !!tableId, refetchInterval: 10000 }
  );
};

export const useGetClients = <T extends string>({
  searchTerm,
}: {
  searchTerm: string;
}) => {
  return useQuery<Array<Client>, ErrorResponse<T>>(
    ['clients'],
    () =>
      fetcher({
        url: `/clients?name=${searchTerm}`,
        method: 'GET',
      }),
    {}
  );
};

export const useGetCovers = <T extends string>() => {
  return useQuery<Array<Cover>, ErrorResponse<T>>(
    ['covers'],
    () =>
      fetcher({
        url: `/covers`,
        method: 'GET',
      }),
    {}
  );
};

export const useGetCover = <T extends string>(
  { _id }: { _id: string },
  options?: UseQueryOptions<Cover, any>
) => {
  return useQuery<Cover, ErrorResponse<T>>(
    ['cover', _id],
    () =>
      fetcher({
        url: `/covers/${_id}`,
        method: 'GET',
      }),
    options
  );
};

export const useCreateCover = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Cover, ErrorResponse<T>, CreateCoverDto>(
    (body: CreateCoverDto) =>
      fetcher({
        url: `/covers`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['covers']);
      },
    }
  );
};

export const useGetReservations = <T extends string>() => {
  return useQuery<Array<Reservation>, ErrorResponse<T>>(
    ['reservations'],
    () =>
      fetcher({
        url: `/reservations`,
        method: 'GET',
      }),
    {}
  );
};

export const useGetMenuReservations = <T extends string>({
  menuId,
}: {
  menuId: string;
}) => {
  return useQuery<Array<Reservation>, ErrorResponse<T>>(
    ['reservations', menuId],
    () =>
      fetcher({
        url: `/reservations/menu/${menuId}`,
        method: 'GET',
      }),
    {}
  );
};

export const useGetReservation = <T extends string>({
  _id,
}: {
  _id: string;
}) => {
  const queryClient = useQueryClient();
  return useQuery<Reservation, ErrorResponse<T>>(
    ['reservation', _id],
    () =>
      fetcher({
        url: `/reservations/${_id}`,
        method: 'GET',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reservations']);
      },
    }
  );
};

export const useDeleteReservation = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Reservation, ErrorResponse<T>, { _id: string }>(
    (body) =>
      fetcher({
        url: `/reservations/${body._id}`,
        method: 'DELETE',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reservations']);
      },
    }
  );
};

export const usePatchReservation = <T extends string>() => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation<Reservation, ErrorResponse<T>, UpdateReservationDto>(
    (body) =>
      fetcher({
        url: `/reservations/${body._id}`,
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: (_, args) => {
        toast.success(t('Reservación actualizada con éxito'));
        queryClient.invalidateQueries(['reservation', args._id]);
        queryClient.invalidateQueries(['reservations']);
      },
    }
  );
};

export const useCreateReservation = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<Cover, ErrorResponse<T>, CreateReservationDto>(
    (body: CreateReservationDto) =>
      fetcher({
        url: `/reservations`,
        method: 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reservations']);
      },
    }
  );
};

export const useUpdateCover = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<UpdateCoverDto, ErrorResponse<T>, Cover>(
    (body: UpdateCoverDto) =>
      fetcher({
        url: `/covers/${body._id}`,
        method: 'PUT',
        body,
      }),
    {
      onSuccess: (body) => {
        queryClient.invalidateQueries(['covers', body._id]);
      },
    }
  );
};

export const useCreateClient = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<CreateClientDto, ErrorResponse<T>, Client>(
    (body) =>
      fetcher({
        url: '/clients',
        method: body._id ? 'PATCH' : 'POST',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
      },
    }
  );
};

export const useDeleteClient = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<SuccessResponse, ErrorResponse<T>, { _id: string }>(
    (body) =>
      fetcher({
        url: `/clients/${body._id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
      },
    }
  );
};

export const useActivateTrial = <T extends string>() => {
  const queryClient = useQueryClient();
  return useMutation<any, ErrorResponse<T>, any>(
    () =>
      fetcher({
        url: `/users/trial`,
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profile-me']);
      },
    }
  );
};

// export const useCreateStripePayment = <T extends string>() => {
//   return useMutation<{ url: string }, ErrorResponse<T>, CreateStripeDto>(
//     (body) =>
//       fetcher({
//         url: '/stripe/subscription',
//         method: 'POST',
//         body,
//       })
//   );
// };
