import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Title = ({ orderId }: { orderId?: string }) => {
  const { t } = useTranslation();
  return (
    <Modal.Header closeButton>
      <Modal.Title className="d-flex flex-column gap-0 fs-6">
        <div className="lead fw-bold">{t('Registrar pago')}</div>
        <small className="small">ID: {orderId}</small>
      </Modal.Title>
    </Modal.Header>
  );
};
