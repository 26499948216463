import Form from 'react-bootstrap/Form';
import { Controller, useFormContext } from 'react-hook-form';
import { Email, domains } from '@smastrom/react-email-autocomplete';

const baseList = [
  'gmail.com',
  'hotmail.com',
  'outlook.com',
  'icloud.com',
  'yahoo.com',
  // 'live.com',
];
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export function EmailControl({
  errorText = '',
  placeholder = '',
  handleFocus,
}: {
  errorText?: string;
  placeholder?: string;
  handleFocus?: any;
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group>
      <Controller
        control={control}
        name="email"
        rules={{
          required: true,
          pattern: emailPattern,
        }}
        render={({
          field: { onChange, onBlur, value, ref, name },
          fieldState: { error, invalid },
        }) => (
          <Email
            id={name}
            name={name}
            placeholder={placeholder}
            classNames={{
              input: 'form-control',
              dropdown:
                'position-absolute bg-white zi-1055 mt-2 p-0 rounded-md rounded-2 shadow-sm border',
              suggestion: 'text-decoration-none list-unstyled m-0 p-3 small lh-1',
              domain: 'fw-semibold',
              wrapper: 'form-floating',
            }}
            baseList={baseList}
            refineList={domains}
            value={value}
            ref={ref}
            isInvalid={invalid}
            onBlur={onBlur}
            onFocus={handleFocus}
            onChange={onChange}
          >
            <label htmlFor={name}>{placeholder}</label>
          </Email>
        )}
      />
      <div>
        <small className="text-danger">{errors['email'] && errorText}</small>
      </div>
    </Form.Group>
  );
}

export default EmailControl;
