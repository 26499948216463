import { ChangePasswordDTO, LoginResponse, VerifyDTO } from '@meniu/data';
import { useEffect } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useChangePassword, useVerify } from 'apiClient/api';
import { useApp, UserActions } from 'context/global.context';
import FormContainer from './components/form-container.component';
import { PasswordInputs } from './components/password.utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Recover({ recoveryCode }: { recoveryCode: string }) {
  const navigate = useNavigate();

  const { mutate, isError } = useChangePassword();

  const [, dispatch] = useApp();

  const { t } = useTranslation();

  const { handleSubmit } = useFormContext<ChangePasswordDTO>();

  const doSubmit = (data: ChangePasswordDTO) => {
    mutate(
      { ...data, recoveryCode },
      {
        onSuccess: (data: LoginResponse) => {
          dispatch(UserActions.setToken(data));
          toast.success(t('Contraseña cambiada'));
          navigate('/');
        },
      }
    );
  };

  const goBack = () => navigate('/forget');

  return (
    <Form
      className="d-flex flex-column gap-4"
      onSubmit={handleSubmit(doSubmit)}
    >
      <h4 className="fw-bold">{t('Crea tu nueva contraseña')}</h4>
      <div className="d-flex flex-column gap-3">
        <PasswordInputs
          password="newPassword"
          confirmPassword="newPasswordConfirm"
        />
      </div>
      {isError && (
        <small className="text-danger">
          {t('Código expirado')}. {t('Intenta solicitar uno nuevo')}
        </small>
      )}
      <div className="d-grid">
        {isError ? (
          <Button variant="dark" className="fw-bold w-100" onClick={goBack}>
            {t('Regresar')}
          </Button>
        ) : (
          <Button type="submit" variant="dark" className="fw-bold w-100">
            {t('Restablecer contraseña')}
          </Button>
        )}
      </div>
    </Form>
  );
}

const Verify = ({ recoveryCode }: { recoveryCode: string }) => {
  const navigate = useNavigate();

  const handleVerify = (data: VerifyDTO) => {
    verifyMutation(data);
  };

  useEffect(() => {
    if (recoveryCode) {
      handleVerify({ validationCode: recoveryCode });
    }
  }, []);

  const handleGoToRegister = () => navigate('/register');

  const handleGoToLogin = () => navigate('/login');

  const {
    mutate: verifyMutation,
    isLoading,
    isSuccess,
    error,
    isError,
  } = useVerify();

  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center">
      {isLoading && <Spinner animation="grow" />}

      {(isSuccess || error?.message === 'AlreadyValidated') && (
        <div className="d-flex flex-column gap-3">
          <h4 className="fw-bold">
            {t('Listo, tu cuenta ha sido verificada')}
          </h4>
          <span>
            {t(
              'Da clic en el botón que se muestra a continuación para iniciar sesión'
            )}
            .
          </span>
          <div className="d-grid">
            <Button
              variant="dark"
              className="fw-bold"
              onClick={handleGoToLogin}
            >
              {t('Iniciar sesión')}
            </Button>
          </div>
        </div>
      )}

      {isError && error?.message !== 'AlreadyValidated' && (
        <div className="d-flex flex-column gap-3">
          <span className="text-center">
            {t(
              'Hubo un error al verificar tu cuenta, intenta registrarte de nuevo'
            )}
            .
          </span>
          <div className="d-grid">
            <Button
              variant="dark"
              className="fw-bold"
              onClick={handleGoToRegister}
            >
              {t('Ir a registro')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export function Callback() {
  const location = useLocation();

  const { recoveryCode } = useParams();

  const methods = useForm({ mode: 'all' });

  if (!recoveryCode) return <Navigate to="/login"></Navigate>;

  return (
    <FormContainer>
      <Card className="border-0 p-3" style={{ width: '400px' }}>
        <Card.Body>
          {location.pathname.includes('recover') ? (
            <FormProvider {...methods}>
              <Recover recoveryCode={recoveryCode} />
            </FormProvider>
          ) : (
            <Verify recoveryCode={recoveryCode} />
          )}
        </Card.Body>
      </Card>
    </FormContainer>
  );
}

export default Callback;
