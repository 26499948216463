import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetOrder } from 'apiClient/api';

import qs from 'qs';
import { PaymentTypesEnum } from '@meniu/utils';
import { OrderStatusByBill } from '../utils/OrderStatusByBill';

export const useOrderForBilling = () => {
  const [orderId, setOrderId] = useState('');
  const [params, setSearchParams] = useSearchParams();

  const paramOrderId = params.get('orderId');
  const orderInitValue = paramOrderId || 'new';
  //console.log('orderInitValue', orderInitValue);

  const { data: order, isLoading } = useGetOrder(orderInitValue, {
    // enabled: !!orderId,
  });

  useEffect(() => {
    setOrderId(paramOrderId || '');
  }, [paramOrderId]);

  const _id = order?._id;
  const id = order?.shortId || '';
  const isOrderPaid = order?.billing?.isOrderPaid;
  const amountTotalPaid = order?.billing?.amountTotalPaid || 0;
  const amountTipPaid = order?.billing?.amountTipPaid || 0;
  const amountTotalPlusTipPaid =
    order?.billing?.amountTotalPlusTipPaid ||
    amountTotalPaid + amountTipPaid ||
    0;
  const amountTotalToPay = order?.billing?.amountTotalToPay || 0;
  const amountTipToPay = order?.billing?.amountTipToPay || 0;
  const amountToDelivery = order?.billing?.amountToDelivery || 0;
  const locale = order?.locale || 'es-MX';
  const currency = order?.currency || 'MXN';

  // this help to manage if a old order has not the billing object
  // when migrate the terminal, only use the billing object
  const _orderTotal = amountTotalToPay || order?.total;
  const amountTipPercentage =
    order?.billing?.amountTipPercentage || order?.tipSelected || 0; // remove order.tipSelected when remove modal for terminal and run migration
  const amountTipTyped = order?.billing?.amountTipTyped || order?.tipTyped || 0; // remove order.tipTyped when remove modal for terminal and run migration
  const _orderTotalWithTip =
    amountTotalToPay + amountTipToPay || order?.totalWithTip;

  const amountTotalWithTipToPay =
    amountTotalToPay + amountTipToPay + amountToDelivery ||
    order?.totalWithTip ||
    0; // remove order.totalWithTip when remove modal for terminal and run migration
  const amountOwed = amountTotalToPay + amountToDelivery - amountTotalPaid;

  // order details
  const orderType = order?.orderType || '';
  const orderMenuId = order?.menu || '';
  const orderProducts = order?.products || [];
  const orderDeliveryType = order?.deliveryType || '';
  const orderDeliveryPrice =
    order?.billing?.amountToDelivery || order?.deliveryPrice || 0;
  const orderPaymentType = order?.paymentType || '';
  const orderToPayInCash =
    order?.billing?.amountToPayInCashFromDelivery || order?.toPayInCash || 0; // remove order.toPayInCash when remove modal for terminal and run migration
  const orderPayments = order?.payments || [];
  const amountChangeToReturnFromDelivery =
    order?.billing?.amountChangeToReturnFromDelivery ||
    orderToPayInCash - amountTotalWithTipToPay ||
    0;

  // order details for payment intention
  const orderPaymentIntentionDetail = order?.paymentIntentionDetail;
  const orderPaymentIntentionDetailStatus = orderPaymentIntentionDetail?.status;
  const orderPaymentIntentionDetailAmount =
    Number(orderPaymentIntentionDetail?.amount) / 100 || 0;
  const orderPaymentIntentionDetailCurrency =
    orderPaymentIntentionDetail?.currency || '';

  // client data from order
  const orderClientPhoneCode = order?.clientPhoneCode || '';
  const orderClientPhone = order?.clientPhone || '';
  const orderClient = order?.client || '';

  // tables data from order
  const orderRoomName = order?.roomName || '';
  const orderTableNumber = order?.tableNumber || '';

  // order address
  const orderAddress = order?.location?.addressText || '';
  const orderAddresDescription = order?.location?.description || '';

  // dates from order
  const orderCreatedAt = order?.createdAt || '';

  const clearParam = () => {
    const prevParams = qs.parse(params.toString());
    const { orderId, ...rest } = prevParams;
    setSearchParams(rest as any);
  };

  /**
   * Return label for payment type.
   */
  const PaymentTypeLabel = (paymentType?: PaymentTypesEnum) => {
    if (paymentType === PaymentTypesEnum.cash) {
      return 'Efectivo';
    }

    if (paymentType === PaymentTypesEnum.card) {
      return 'Tarjeta';
    }

    if (paymentType === PaymentTypesEnum.creditCard) {
      return 'Tarjeta de crédito';
    }

    if (paymentType === PaymentTypesEnum.debitCard) {
      return 'Tarjeta de débito';
    }

    if (paymentType === PaymentTypesEnum.transfer) {
      return 'Transferencia';
    }

    return '';
  };

  const orderCurrentStatus = (
    <OrderStatusByBill
      isOrderPaid={isOrderPaid}
      amountTotalPaid={amountTotalPaid}
      amountTotalToPay={amountTotalToPay}
    />
  );

  return {
    _id,
    id,
    isLoading,
    // keys to delete.
    _orderTotal,
    _orderTotalWithTip,
    // key for order billing
    isOrderPaid,
    amountTotalPaid,
    amountTipPaid,
    amountTotalPlusTipPaid,
    amountTotalToPay,
    amountTipToPay,
    amountTipPercentage,
    amountTipTyped,
    amountOwed,
    amountChangeToReturnFromDelivery,
    amountTotalWithTipToPay,
    locale,
    currency,
    // keys for order details
    orderCurrentStatus,
    orderType,
    orderMenuId,
    orderProducts,
    orderDeliveryType,
    orderDeliveryPrice,
    orderPaymentType,
    orderToPayInCash,
    orderPayments,
    // keys for payment intention
    orderPaymentIntentionDetail,
    orderPaymentIntentionDetailStatus,
    orderPaymentIntentionDetailAmount,
    orderPaymentIntentionDetailCurrency,
    // keys for client data
    orderClientPhoneCode,
    orderClientPhone,
    orderClient,
    // keys for tables data
    orderRoomName,
    orderTableNumber,
    // keys for order address
    orderAddress,
    orderAddresDescription,
    // keys for dates
    orderCreatedAt,
    clearParam,
    PaymentTypeLabel,
    order,
    orderInitValue,
    menuId: order?.menu,
  };
};
