import cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { allCountries } from '../countriesInfo';
import { defaultCountry } from '../consts';


export const useSetCountry = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [currentCountry, setCurrentCountry] =
    useState<keyof typeof allCountries>(defaultCountry);

  useEffect(() => {
    async function getCountry() {
      try {
        const countries = await fetch(
          `${
            process.env.NX_BACKEND_URL_ || process.env.GATSBY_BACKEND_URL_
          }/aytics/get-country`
        );
        const countryJson = await countries.json();
        if (countryJson.country) {
          setCurrentCountry(countryJson.country);
          cookies.set('country', countryJson.country, {
            domain: '.meniuapp.com',
          });
        } else {
          setCurrentCountry(defaultCountry);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    const storedCountry = cookies.get('country') as keyof typeof allCountries;
    if (!storedCountry) {
      getCountry();
    } else {
      setCurrentCountry(storedCountry);
      setIsLoading(false);
    }
  }, []);

  return { currentCountry, isLoading };
};
