import { useApp, UserActions } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { Button, Modal } from 'react-bootstrap';
import miImagen from '../../../../assets/users-modal-2.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trialDays } from '@meniu/ui';
import { totalRestaurants } from '@meniu/ui';
// import { PurchaseButton } from '../PurchaseButton';

export function BuyPro() {
  const [{ modal, demo, message }, dispatch] = useApp();

  const handleClose = () => dispatch(UserActions.closeModal());

  const handleCreateAccount = () =>
    dispatch(UserActions.setModal(modalOptions.register));

  const { t } = useTranslation();

  return (
    <Modal
      show={modal.includes(modalOptions['buy-pro'])}
      onHide={handleClose}
      centered={true}
      backdropClassName="zi-1055"
      contentClassName="p-2"
      className="mobile-modal"
    >
      <Modal.Body className="d-flex flex-column gap-3">
        {/* <img
          src={BuyProImage}
          alt="Comprar plan"
          className="img-fluid w-100 rounded-3"
        ></img> */}
        <div className="bg-primary  rounded-4 p-3">
          <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-0 justify-content-between align-items-center">
            <img src={miImagen} style={{ width: 180 }} alt="please-dont-go" />
            <div className="d-flex flex-column">
              <span className="d-block px-3 shadow-sm bg-white py-1 fw-semibold">
                +{`${totalRestaurants} ${t('restaurantes ya')}`}
              </span>
              <span
                className="d-inline-block px-3 shadow-lg bg-white py-1 fw-semibold align-self-end"
                style={{ transform: 'rotate(-3deg)' }}
              >
                {t('confían en nosotros')}
              </span>
            </div>
          </div>
          <p className="text-white fw-semibold mt-3 text-center text-sm-start small">
            "
            {t(
              'Excelente app para crear tu menú digital. Fácil, práctica e intuitiva'
            )}
            ."
          </p>
        </div>

        <p className="fw-bold">
          {t('Prueba el Plan Business gratis por {{trialDays}} días', {
            trialDays: trialDays,
          })}
        </p>

        <small>
          {message ||
            t('Funcionalidad exclusiva del Plan Business', {
              trialDays: trialDays,
            })}
          .
        </small>

        <div className="d-grid gap-2">
          {demo ? (
            <Button
              className="w-100 fw-semibold btn-animation"
              variant="dark"
              onClick={handleCreateAccount}
            >
              {t('Crea tu cuenta gratis')}
            </Button>
          ) : (
            <div>
              <Link
                onClick={handleClose}
                to="/profile/subscriptions"
                className="w-100 fw-semibold btn btn-dark"
              >
                {t('Pruébalo gratis {{trialDays}} días', {
                  trialDays: trialDays,
                })}
              </Link>
            </div>
          )}
          <Button
            className="w-100 fw-semibold border-0"
            variant="default"
            onClick={handleClose}
          >
            {t('No quiero probarlo')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default BuyPro;
