import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const CloseModal = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <Modal.Footer className="px-4">
      <Button
        className="text-white btn-outline btn fw-bold w-100"
        onClick={closeModal}
        variant="dark"
      >
        {t('Mantener mi suscripción')}
      </Button>
      <Button
        type="submit"
        variant="link"
        className="fw-bold w-100 text-dark text-decoration-none"
      >
        {t('Continuar cancelación')}
      </Button>
    </Modal.Footer>
  );
};
