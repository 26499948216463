import { UserActions, useApp } from 'context/global.context';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { usePatchUser } from 'apiClient/api';
import { useForm } from 'react-hook-form';

interface IUpdateRelevantFeatures {
  relevantFeatures: Array<string>;
  otherRelevantFeature: string;
}

function PromoModal() {
  const [{ user }, dispatch] = useApp();

  const { t } = useTranslation();

  const options = [
    t('Recibir reservaciones en línea'),
    t('Recibir pedidos en línea'),
    t('Crear mi catálogo en línea'),
    t('Registrar mis ventas'),
    t('Manejar mi inventario'),
  ];

  const { mutate } = usePatchUser();

  const handleClose = () => {
    dispatch(UserActions.closeModal());
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(user && !user.relevantFeatures?.length);
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<IUpdateRelevantFeatures>();

  const doSubmit = (values: IUpdateRelevantFeatures) => {
    mutate(values);
  };

  return (
    <Modal
      show={false}
      onHide={handleClose}
      centered
      className="mobile-modal"
      contentClassName="p-2"
    >
      <form onSubmit={handleSubmit(doSubmit)}>
        <Modal.Header>
          <p className="lead fw-semibold">
            {t('Elige las 2 funcionalidades más importantes para ti')}
          </p>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-4">
          {options.map((option) => (
            <Form.Check
              type="checkbox"
              value={option}
              label={option}
              {...register('relevantFeatures', { required: true })}
            />
          ))}
          <Form.Control
            placeholder={t('Otra opción').toString()}
            {...register('otherRelevantFeature')}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer className="">
          <Button
            className="fw-bold w-100"
            variant="dark"
            type="submit"
            disabled={!isValid}
          >
            {t('Continuar')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default PromoModal;
