import { Form } from 'react-bootstrap';
import { PasswordInputs } from './password.utils';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

export const CreatePassword = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <PasswordInputs />

      <Form.Group className="d-flex gap-2 p-1 align-items-center">
        <small>
          {t('Al dar clic en “Crear cuenta” estas aceptando nuestro')}{' '}
          <a
            href={`${process.env.NX_LANDING_URL_}/legal/privacy-policy`}
            className="text-dark"
            target="_blank"
            rel="noreferrer"
          >
            {t('Aviso de privacidad')}
          </a>{' '}
          {t('y los')}{' '}
          <a
            href={`${process.env.NX_LANDING_URL_}/legal/terms-and-conditions`}
            className="text-dark"
            target="_blank"
            rel="noreferrer"
          >
            {t('Términos y condiciones')}
          </a>
          .
        </small>
        <Form.Control.Feedback type="invalid">
          {errors.checked && 'Falta '}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
