import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const OpenModal = ({ openModal }: { openModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button
      className="fw-bold w-100 text-dark text-decoration-none"
      onClick={openModal}
      variant="link"
    >
      {t('Cancelar suscripción')}
    </Button>
  );
};
