import { forwardRef } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { ReactComponent as EyeIcon } from './assets/eye-outline.svg';
import { ReactComponent as EyeOffIcon } from './assets/eye-off-outline.svg';
import { FormControlProps } from 'react-bootstrap/esm/FormControl';
import CurrencyInput from 'react-currency-input-field';
import { Controller } from 'react-hook-form';
import { IntlConfig } from 'react-currency-input-field/dist/components/CurrencyInputProps';

interface FormTextFloatingProps extends FormControlProps {
  error?: string | null;
  feedback?: string;
  wrap?: string;
  haseye?: boolean;
  handlechangeeye?: any;
  min?: number | string;
  maxLength?: number | string;
  control?: any;
  intlConfig?: IntlConfig;
  placeholder: string;
  name?: string;
}
const eyeStyle = {
  position: 'absolute',
  top: '16px',
  right: '15px',
  cursor: 'pointer',
} as any;

const ViewEye = (props: any) =>
  props.type === 'password' ? (
    <EyeIcon style={eyeStyle} onClick={props.handlechangeeye} />
  ) : (
    <EyeOffIcon style={eyeStyle} onClick={props.handlechangeeye} />
  );

export const FormTextFloating = forwardRef<any, FormTextFloatingProps>(
  (
    {
      haseye,
      handlechangeeye,
      control,
      intlConfig,
      ...props
    }: FormTextFloatingProps,
    ref
  ) => (
    <FloatingLabel label={props.placeholder} hidden={props.hidden}>
      {props.type !== 'currency' ? (
        <Form.Control data-test={props.id} {...props} ref={ref}></Form.Control>
      ) : (
        <Controller
          control={control}
          name={props.name || 'price'}
          defaultValue={props.defaultValue}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { invalid, error },
            formState: { isValid },
          }) => {
            return (
              <CurrencyInput
                data-test={name}
                id={name}
                name={name}
                placeholder={props.placeholder}
                decimalsLimit={2}
                onValueChange={onChange}
                intlConfig={intlConfig}
                value={value}
                className={`form-control ${invalid && 'is-invalid'}`}
              />
            );
          }}
        />
      )}
      {haseye && <ViewEye handlechangeeye={handlechangeeye} {...props} />}
      <Form.Control.Feedback type="invalid">
        {props.error}
      </Form.Control.Feedback>
      <small className="text-muted" hidden={!props.feedback}>
        {props.feedback}
      </small>
    </FloatingLabel>
  )
);

export default FormTextFloating;
