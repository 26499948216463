import { MenuProvider } from 'context/menu.context';
import { MenuTabs } from './components/MenuTabs';
import { ModalOrderPaymentsGlobal } from './modals/modal-order-payments-global/modal-order-payments-global';
import { ModalCtaUseTerminal } from './modals/modal-cta-use-terminal/modal-cta-use-terminal';

export function MenuEditor() {
  return (
    <MenuProvider>
      <MenuTabs />
      <ModalOrderPaymentsGlobal />
      <ModalCtaUseTerminal />
    </MenuProvider>
  );
}

export default MenuEditor;
