import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * This function is used to determine the status of the order based on the billing object
 * @param isOrderPaid - Boolean that indicates if the order is paid
 * @param amountTotalPaid - Total amount paid
 * @param amountTotalToPay - Total amount to pay
 * @returns {Badge} - Return a badge with the status of the payment
 */
export const OrderStatusByBill = ({
  isOrderPaid,
  amountTotalPaid,
  amountTotalToPay,
}: {
  isOrderPaid?: boolean;
  amountTotalPaid: number;
  amountTotalToPay: number;
}) => {
  const { t } = useTranslation();

  if (isOrderPaid) {
    return (
      <Badge className="fw-semibold badge-success "> {t('Pagado')} </Badge>
    );
  }

  if (amountTotalPaid >= amountTotalToPay) {
    return (
      <Badge className="fw-semibold badge-success "> {t('Pagado')} </Badge>
    );
  }

  if (amountTotalPaid === 0) {
    return (
      <Badge className="fw-semibold badge-danger ">
        {' '}
        {t('Pago pendiente')}
      </Badge>
    );
  }

  if (amountTotalPaid < amountTotalToPay) {
    return (
      <Badge className="fw-semibold badge-warning ">
        {' '}
        {t('Pago parcial')}{' '}
      </Badge>
    );
  }

  return (
    <Badge className="fw-semibold badge-danger "> {t('Pago pendiente')}</Badge>
  );
};
