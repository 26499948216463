import styled from 'styled-components';

export const DonutProgress = styled.div<{ percentage: number }>`
  display: block;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 20%);
  ${(props) =>
    props.percentage === 25
      ? `border-top: 5px solid #ff4f11;`
      : props.percentage === 50
      ? `border-top: 5px solid #ff4f11; border-right: 5px solid #ff4f11;`
      : props.percentage === 75
      ? `border-top: 5px solid #ff4f11; border-right: 5px solid #ff4f11; border-bottom: 5px solid #ff4f11;`
      : props.percentage === 100
      ? `border: 5px solid #ff4f11;`
      : ''}

  &:after {
    content: '${(props) => props.percentage}%';
    color: white;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 50%;
  }
`;
