// Libraries.
import { Form } from 'react-bootstrap';
import { FormTextFloating } from '@meniu/ui';
import { ReactComponent as PasswordBullet } from '../assets/bullet-password.svg';
import { ReactComponent as PasswordCheck } from 'assets/checked-password.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const PasswordMessage = ({ errors, typeError, message, value }: any) => {
  return (
    <div
      className={`d-flex align-items-center ${
        !value
          ? 'text-muted'
          : errors?.includes(typeError) || !errors
          ? 'text-muted'
          : 'text-success'
      }`}
    >
      {!value || errors?.includes(typeError) || !errors ? (
        <PasswordBullet />
      ) : (
        <PasswordCheck />
      )}{' '}
      <span className="ms-1">{message}</span>
    </div>
  );
};

export const PasswordInputs = ({
  password = 'password',
  confirmPassword = 'confirmPassword',
}: {
  password?: string;
  confirmPassword?: string;
}) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [passwordInput, setPasswordInput] = useState('password');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('password');
  return (
    <>
      <Form.Group>
        <FormTextFloating
          placeholder={t('Crea tu contraseña')}
          type={passwordInput}
          id="create-password-input"
          {...register(password, {
            required: true,
            validate: (value: any) => {
              let validatePassword = '';
              const passwordAux = getValues()[password];

              const len = new RegExp('(?=.{8,})');
              if (!len.test(passwordAux)) validatePassword += '1';

              const upper = new RegExp('(?=.*[A-Z])');
              if (!upper.test(passwordAux)) validatePassword += '2';

              const number = new RegExp('(?=.*[0-9])');
              if (!number.test(passwordAux)) validatePassword += '3';

              if (validatePassword) return validatePassword;

              return true;
            },
          })}
          haseye={true}
          handlechangeeye={() =>
            passwordInput === 'password'
              ? setPasswordInput('text')
              : setPasswordInput('password')
          }
          // isInvalid={!!errors[password]}
        />
        <div className="d-flex flex-column gap-2 mt-3 small">
          <PasswordMessage
            errors={errors[password]?.message || 'success'}
            typeError={'1'}
            message={t('Mínimo 8 caracteres')}
            value={getValues()[password]}
          />
          <PasswordMessage
            errors={errors[password]?.message || 'success'}
            typeError={'2'}
            message={t('Mínimo 1 mayúscula')}
            value={getValues()[password]}
          />
          <PasswordMessage
            errors={errors[password]?.message || 'success'}
            typeError={'3'}
            message={t('Mínimo 1 número')}
            value={getValues()[password]}
          />
        </div>
      </Form.Group>

      {/* <Form.Group>
        <FormTextFloating
          id="verify-password-input"
          placeholder={t('Verifica tu contraseña')}
          type={confirmPasswordInput}
          {...register(confirmPassword, {
            required: true,
            validate: (value: any) => {
              const passwordAux = getValues()[password];
              return passwordAux === value;
            },
          })}
          error={"a"}
          // isInvalid={!!errors[confirmPassword]}
          haseye={true}
          handlechangeeye={() =>
            confirmPasswordInput === 'password'
              ? setConfirmPasswordInput('text')
              : setConfirmPasswordInput('password')
          }
        />
        {!!errors[confirmPassword] && (
          <small className="text-danger">
            {t('No coinciden las contraseñas')}
          </small>
        )}
      </Form.Group> */}
    </>
  );
};
