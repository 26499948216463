import { useTranslation } from 'react-i18next';

export const Protected = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('No tienes permiso para ver esta página')}</h2>
    </div>
  );
};
