import { useTranslation } from 'react-i18next';

export const Paid = ({
  amountTotalPaid,
}: {
  amountTotalPaid: string | number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between">
      <div>{t('Abonado')}</div>

      <div className="fw-bold">{amountTotalPaid}</div>
    </div>
  );
};
