export const spanishSpeakingcountries = {
  MX: {
    name: 'Mexico',
    code: '+52',
    currency: 'MXN',
    locale: 'es-MX',
    flagIcon: '🇲🇽',
    rate: 1,
  },
  BR: {
    name: 'Brazil',
    code: '+55',
    currency: 'BRL',
    locale: 'pt-BR',
    flagIcon: '🇧🇷',
    rate: 0.28,
  },
  US: {
    name: 'United States',
    code: '+1',
    currency: 'USD',
    locale: 'en-US',
    flagIcon: '🇺🇸',
    rate: 0.055,
  },
  AR: {
    name: 'Argentina',
    code: '+54',
    currency: 'ARS',
    locale: 'es-AR',
    flagIcon: '🇦🇷',
    rate: 19.2,
  },
  BO: {
    name: 'Bolivia',
    code: '+591',
    currency: 'BOB',
    locale: 'es-BO',
    flagIcon: '🇧🇴',
    rate: 0.38,
  },
  CL: {
    name: 'Chile',
    code: '+56',
    currency: 'CLP',
    locale: 'es-CL',
    flagIcon: '🇨🇱',
    rate: 51.66,
  },
  CO: {
    name: 'Colombia',
    code: '+57',
    currency: 'COP',
    locale: 'es-CO',
    flagIcon: '🇨🇴',
    rate: 231.89,
  },
  CR: {
    name: 'Costa Rica',
    code: '+506',
    currency: 'CRC',
    locale: 'es-CR',
    flagIcon: '🇨🇷',
    rate: 29.11,
  },
  CU: {
    name: 'Cuba',
    code: '+53',
    currency: 'CUP',
    locale: 'es-CU',
    flagIcon: '🇨🇺',
    rate: 1.32,
  },
  DO: {
    name: 'Dominican Republic',
    code: '+1-809, 1-829, 1-849',
    currency: 'DOP',
    locale: 'es-DO',
    flagIcon: '🇩🇴',
    rate: 3.12,
  },
  EC: {
    name: 'Ecuador',
    code: '+593',
    currency: 'USD',
    locale: 'es-EC',
    flagIcon: '🇪🇨',
    rate: 0.055,
  },
  SV: {
    name: 'El Salvador',
    code: '+503',
    currency: 'USD',
    locale: 'es-SV',
    flagIcon: '🇸🇻',
    rate: 0.055,
  },
  GT: {
    name: 'Guatemala',
    code: '+502',
    currency: 'GTQ',
    locale: 'es-GT',
    flagIcon: '🇬🇹',
    rate: 0.43,
  },
  HN: {
    name: 'Honduras',
    code: '+504',
    currency: 'HNL',
    locale: 'es-HN',
    flagIcon: '🇭🇳',
    rate: 1.36,
  },
  NI: {
    name: 'Nicaragua',
    code: '+505',
    currency: 'NIO',
    locale: 'es-NI',
    flagIcon: '🇳🇮',
    rate: 2.01,
  },
  PA: {
    name: 'Panama',
    code: '+507',
    currency: 'PAB',
    locale: 'es-PA',
    flagIcon: '🇵🇦',
    rate: 0.055,
  },
  PY: {
    name: 'Paraguay',
    code: '+595',
    currency: 'PYG',
    locale: 'es-PY',
    flagIcon: '🇵🇾',
    rate: 407.05,
  },
  PE: {
    name: 'Peru',
    code: '+51',
    currency: 'PEN',
    locale: 'es-PE',
    flagIcon: '🇵🇪',
    rate: 0.21,
  },
  PR: {
    name: 'Puerto Rico',
    code: '+1-787, 1-939',
    currency: 'USD',
    locale: 'es-PR',
    flagIcon: '🇵🇷',
    rate: 0.055,
  },
  ES: {
    name: 'Spain',
    code: '+34',
    currency: 'EUR',
    locale: 'es-ES',
    flagIcon: '🇪🇸',
    rate: 0.052,
  },
  UY: {
    name: 'Uruguay',
    code: '+598',
    currency: 'UYU',
    locale: 'es-UY',
    flagIcon: '🇺🇾',
    rate: 2.19,
  },
  VE: {
    name: 'Venezuela',
    code: '+58',
    currency: 'VES',
    locale: 'es-VE',
    flagIcon: '🇻🇪',
    rate: 1913,
  },
};

export const countries = {
  MX: {
    name: 'Mexico',
    code: '+52',
    currency: 'MXN',
    locale: 'es-MX',
    flagIcon: '🇲🇽',
    rate: 1,
  },
  AF: {
    name: 'Afghanistan',
    code: '+93',
    currency: 'AFN',
    locale: 'ps-AF',
    flagIcon: '🇦🇫',
    rate: 1,
  },
  AL: {
    name: 'Albania',
    code: '+355',
    currency: 'ALL',
    locale: 'sq-AL',
    flagIcon: '🇦🇱',
    rate: 1,
  },
  DZ: {
    name: 'Algeria',
    code: '+213',
    currency: 'DZD',
    locale: 'ar-DZ',
    flagIcon: '🇩🇿',
    rate: 1,
  },
  AS: {
    name: 'American Samoa',
    code: '+1-684',
    currency: 'USD',
    locale: 'en-AS',
    flagIcon: '🇦🇸',
    rate: 1,
  },
  AD: {
    name: 'Andorra',
    code: '+376',
    currency: 'EUR',
    locale: 'ca-AD',
    flagIcon: '🇦🇩',
    rate: 1,
  },
  AO: {
    name: 'Angola',
    code: '+244',
    currency: 'AOA',
    locale: 'pt-AO',
    flagIcon: '🇦🇴',
    rate: 1,
  },
  AI: {
    name: 'Anguilla',
    code: '+1-264',
    currency: 'XCD',
    locale: 'en-AI',
    flagIcon: '🇦🇮',
    rate: 1,
  },
  AQ: {
    name: 'Antarctica',
    code: '+672',
    currency: '',
    locale: '',
    flagIcon: '🇦🇶',
    rate: 1,
  },
  AG: {
    name: 'Antigua and Barbuda',
    code: '+1-268',
    currency: 'XCD',
    locale: 'en-AG',
    flagIcon: '🇦🇬',
    rate: 1,
  },
  AR: {
    name: 'Argentina',
    code: '+54',
    currency: 'ARS',
    locale: 'es-AR',
    flagIcon: '🇦🇷',
    rate: 1,
  },
  AM: {
    name: 'Armenia',
    code: '+374',
    currency: 'AMD',
    locale: 'hy-AM',
    flagIcon: '🇦🇲',
    rate: 1,
  },
  AW: {
    name: 'Aruba',
    code: '+297',
    currency: 'AWG',
    locale: 'nl-AW',
    flagIcon: '🇦🇼',
    rate: 1,
  },
  AU: {
    name: 'Australia',
    code: '+61',
    currency: 'AUD',
    locale: 'en-AU',
    flagIcon: '🇦🇺',
    rate: 1,
  },
  AT: {
    name: 'Austria',
    code: '+43',
    currency: 'EUR',
    locale: 'de-AT',
    flagIcon: '🇦🇹',
    rate: 1,
  },
  AZ: {
    name: 'Azerbaijan',
    code: '+994',
    currency: 'AZN',
    locale: 'az-Latn-AZ',
    flagIcon: '🇦🇿',
    rate: 1,
  },
  BS: {
    name: 'Bahamas',
    code: '+1-242',
    currency: 'BSD',
    locale: 'en-BS',
    flagIcon: '🇧🇸',
    rate: 1,
  },
  BH: {
    name: 'Bahrain',
    code: '+973',
    currency: 'BHD',
    locale: 'ar-BH',
    flagIcon: '🇧🇭',
    rate: 1,
  },
  BD: {
    name: 'Bangladesh',
    code: '+880',
    currency: 'BDT',
    locale: 'bn-BD',
    flagIcon: '🇧🇩',
    rate: 1,
  },
  BB: {
    name: 'Barbados',
    code: '+1-246',
    currency: 'BBD',
    locale: 'en-BB',
    flagIcon: '🇧🇧',
    rate: 1,
  },
  BY: {
    name: 'Belarus',
    code: '+375',
    currency: 'BYN',
    locale: 'be-BY',
    flagIcon: '🇧🇾',
    rate: 1,
  },
  BE: {
    name: 'Belgium',
    code: '+32',
    currency: 'EUR',
    locale: 'nl-BE',
    flagIcon: '🇧🇪',
    rate: 1,
  },
  BZ: {
    name: 'Belize',
    code: '+501',
    currency: 'BZD',
    locale: 'en-BZ',
    flagIcon: '🇧🇿',
    rate: 1,
  },
  BJ: {
    name: 'Benin',
    code: '+229',
    currency: 'XOF',
    locale: 'fr-BJ',
    flagIcon: '🇧🇯',
    rate: 1,
  },
  BM: {
    name: 'Bermuda',
    code: '+1-441',
    currency: 'BMD',
    locale: 'en-BM',
    flagIcon: '🇧🇲',
    rate: 1,
  },
  BT: {
    name: 'Bhutan',
    code: '+975',
    currency: 'BTN',
    locale: 'dz-BT',
    flagIcon: '🇧🇹',
    rate: 1,
  },
  BO: {
    name: 'Bolivia',
    code: '+591',
    currency: 'BOB',
    locale: 'es-BO',
    flagIcon: '🇧🇴',
    rate: 1,
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    code: '+387',
    currency: 'BAM',
    locale: 'bs-Latn-BA',
    flagIcon: '🇧🇦',
    rate: 1,
  },
  BW: {
    name: 'Botswana',
    code: '+267',
    currency: 'BWP',
    locale: 'en-BW',
    flagIcon: '🇧🇼',
    rate: 1,
  },
  BR: {
    name: 'Brazil',
    code: '+55',
    currency: 'BRL',
    locale: 'pt-BR',
    flagIcon: '🇧🇷',
    rate: 0.28,
  },
  IO: {
    name: 'British Indian Ocean Territory',
    code: '+246',
    currency: 'USD',
    locale: 'en-IO',
    flagIcon: '🇮🇴',
    rate: 1,
  },
  VG: {
    name: 'British Virgin Islands',
    code: '+1-284',
    currency: 'USD',
    locale: 'en-VG',
    flagIcon: '🇻🇬',
    rate: 1,
  },
  BN: {
    name: 'Brunei',
    code: '+673',
    currency: 'BND',
    locale: 'ms-BN',
    flagIcon: '🇧🇳',
    rate: 1,
  },
  BG: {
    name: 'Bulgaria',
    code: '+359',
    currency: 'BGN',
    locale: 'bg-BG',
    flagIcon: '🇧🇬',
    rate: 1,
  },
  BF: {
    name: 'Burkina Faso',
    code: '+226',
    currency: 'XOF',
    locale: 'fr-BF',
    flagIcon: '🇧🇫',
    rate: 1,
  },
  BI: {
    name: 'Burundi',
    code: '+257',
    currency: 'BIF',
    locale: 'fr-BI',
    flagIcon: '🇧🇮',
    rate: 1,
  },
  KH: {
    name: 'Cambodia',
    code: '+855',
    currency: 'KHR',
    locale: 'km-KH',
    flagIcon: '🇰🇭',
    rate: 1,
  },
  CM: {
    name: 'Cameroon',
    code: '+237',
    currency: 'XAF',
    locale: 'fr-CM',
    flagIcon: '🇨🇲',
    rate: 1,
  },
  CA: {
    name: 'Canada',
    code: '+1',
    currency: 'CAD',
    locale: 'en-CA',
    flagIcon: '🇨🇦',
    rate: 1,
  },
  CV: {
    name: 'Cape Verde',
    code: '+238',
    currency: 'CVE',
    locale: 'pt-CV',
    flagIcon: '🇨🇻',
    rate: 1,
  },

  KY: {
    name: 'Cayman Islands',
    code: '+1-345',
    currency: 'KYD',
    locale: 'en-KY',
    flagIcon: '🇰🇾',
    rate: 1,
  },
  CF: {
    name: 'Central African Republic',
    code: '+236',
    currency: 'XAF',
    locale: 'fr-CF',
    flagIcon: '🇨🇫',
    rate: 1,
  },
  TD: {
    name: 'Chad',
    code: '+235',
    currency: 'XAF',
    locale: 'fr-TD',
    flagIcon: '🇹🇩',
    rate: 1,
  },
  CL: {
    name: 'Chile',
    code: '+56',
    currency: 'CLP',
    locale: 'es-CL',
    flagIcon: '🇨🇱',
    rate: 1,
  },
  CN: {
    name: 'China',
    code: '+86',
    currency: 'CNY',
    locale: 'zh-CN',
    flagIcon: '🇨🇳',
    rate: 1,
  },
  CX: {
    name: 'Christmas Island',
    code: '+61',
    currency: 'AUD',
    locale: 'en-CX',
    flagIcon: '🇨🇽',
    rate: 1,
  },
  CC: {
    name: 'Cocos Islands',
    code: '+61',
    currency: 'AUD',
    locale: 'en-CC',
    flagIcon: '🇨🇨',
    rate: 1,
  },
  CO: {
    name: 'Colombia',
    code: '+57',
    currency: 'COP',
    locale: 'es-CO',
    flagIcon: '🇨🇴',
    rate: 230,
  },
  KM: {
    name: 'Comoros',
    code: '+269',
    currency: 'KMF',
    locale: 'ar-KM',
    flagIcon: '🇰🇲',
    rate: 1,
  },
  CK: {
    name: 'Cook Islands',
    code: '+682',
    currency: 'NZD',
    locale: 'en-CK',
    flagIcon: '🇨🇰',
    rate: 1,
  },
  CR: {
    name: 'Costa Rica',
    code: '+506',
    currency: 'CRC',
    locale: 'es-CR',
    flagIcon: '🇨🇷',
    rate: 1,
  },
  HR: {
    name: 'Croatia',
    code: '+385',
    currency: 'HRK',
    locale: 'hr-HR',
    flagIcon: '🇭🇷',
    rate: 1,
  },
  CU: {
    name: 'Cuba',
    code: '+53',
    currency: 'CUP',
    locale: 'es-CU',
    flagIcon: '🇨🇺',
    rate: 1,
  },
  CW: {
    name: 'Curacao',
    code: '+599',
    currency: 'ANG',
    locale: 'nl-CW',
    flagIcon: '🇨🇼',
    rate: 1,
  },
  CY: {
    name: 'Cyprus',
    code: '+357',
    currency: 'EUR',
    locale: 'el-CY',
    flagIcon: '🇨🇾',
    rate: 1,
  },
  CZ: {
    name: 'Czech Republic',
    code: '+420',
    currency: 'CZK',
    locale: 'cs-CZ',
    flagIcon: '🇨🇿',
    rate: 1,
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    code: '+243',
    currency: 'CDF',
    locale: 'fr-CD',
    flagIcon: '🇨🇩',
    rate: 1,
  },
  DK: {
    name: 'Denmark',
    code: '+45',
    currency: 'DKK',
    locale: 'da-DK',
    flagIcon: '🇩🇰',
    rate: 1,
  },
  DJ: {
    name: 'Djibouti',
    code: '+253',
    currency: 'DJF',
    locale: 'fr-DJ',
    flagIcon: '🇩🇯',
    rate: 1,
  },
  DM: {
    name: 'Dominica',
    code: '+1-767',
    currency: 'XCD',
    locale: 'en-DM',
    flagIcon: '🇩🇲',
    rate: 1,
  },
  DO: {
    name: 'Dominican Republic',
    code: '+1-809, 1-829, 1-849',
    currency: 'DOP',
    locale: 'es-DO',
    flagIcon: '🇩🇴',
    rate: 1,
  },
  TL: {
    name: 'East Timor',
    code: '+670',
    currency: 'USD',
    locale: 'pt-TL',
    flagIcon: '🇹🇱',
    rate: 1,
  },
  EC: {
    name: 'Ecuador',
    code: '+593',
    currency: 'USD',
    locale: 'es-EC',
    flagIcon: '🇪🇨',
    rate: 1,
  },
  EG: {
    name: 'Egypt',
    code: '+20',
    currency: 'EGP',
    locale: 'ar-EG',
    flagIcon: '🇪🇬',
    rate: 1,
  },
  SV: {
    name: 'El Salvador',
    code: '+503',
    currency: 'USD',
    locale: 'es-SV',
    flagIcon: '🇸🇻',
    rate: 1,
  },
  GQ: {
    name: 'Equatorial Guinea',
    code: '+240',
    currency: 'XAF',
    locale: 'es-GQ',
    flagIcon: '🇬🇶',
    rate: 1,
  },
  ER: {
    name: 'Eritrea',
    code: '+291',
    currency: 'ERN',
    locale: 'ti-ER',
    flagIcon: '🇪🇷',
    rate: 1,
  },
  EE: {
    name: 'Estonia',
    code: '+372',
    currency: 'EUR',
    locale: 'et-EE',
    flagIcon: '🇪🇪',
    rate: 1,
  },
  ET: {
    name: 'Ethiopia',
    code: '+251',
    currency: 'ETB',
    locale: 'am-ET',
    flagIcon: '🇪🇹',
    rate: 1,
  },
  FK: {
    name: 'Falkland Islands',
    code: '+500',
    currency: 'FKP',
    locale: 'en-FK',
    flagIcon: '🇫🇰',
    rate: 1,
  },
  FO: {
    name: 'Faroe Islands',
    code: '+298',
    currency: 'DKK',
    locale: 'fo-FO',
    flagIcon: '🇫🇴',
    rate: 1,
  },
  FJ: {
    name: 'Fiji',
    code: '+679',
    currency: 'FJD',
    locale: 'en-FJ',
    flagIcon: '🇫🇯',
    rate: 1,
  },
  FI: {
    name: 'Finland',
    code: '+358',
    currency: 'EUR',
    locale: 'fi-FI',
    flagIcon: '🇫🇮',
    rate: 1,
  },
  FR: {
    name: 'France',
    code: '+33',
    currency: 'EUR',
    locale: 'fr-FR',
    flagIcon: '🇫🇷',
    rate: 1,
  },
  PF: {
    name: 'French Polynesia',
    code: '+689',
    currency: 'XPF',
    locale: 'fr-PF',
    flagIcon: '🇵🇫',
    rate: 1,
  },
  GA: {
    name: 'Gabon',
    code: '+241',
    currency: 'XAF',
    locale: 'fr-GA',
    flagIcon: '🇬🇦',
    rate: 1,
  },
  GM: {
    name: 'Gambia',
    code: '+220',
    currency: 'GMD',
    locale: 'en-GM',
    flagIcon: '🇬🇲',
    rate: 1,
  },
  GE: {
    name: 'Georgia',
    code: '+995',
    currency: 'GEL',
    locale: 'ka-GE',
    flagIcon: '🇬🇪',
    rate: 1,
  },
  DE: {
    name: 'Germany',
    code: '+49',
    currency: 'EUR',
    locale: 'de-DE',
    flagIcon: '🇩🇪',
    rate: 1,
  },
  GH: {
    name: 'Ghana',
    code: '+233',
    currency: 'GHS',
    locale: 'en-GH',
    flagIcon: '🇬🇭',
    rate: 1,
  },
  GI: {
    name: 'Gibraltar',
    code: '+350',
    currency: 'GIP',
    locale: 'en-GI',
    flagIcon: '🇬🇮',
    rate: 1,
  },
  GR: {
    name: 'Greece',
    code: '+30',
    currency: 'EUR',
    locale: 'el-GR',
    flagIcon: '🇬🇷',
    rate: 1,
  },
  GL: {
    name: 'Greenland',
    code: '+299',
    currency: 'DKK',
    locale: 'kl-GL',
    flagIcon: '🇬🇱',
    rate: 1,
  },
  GD: {
    name: 'Grenada',
    code: '+1-473',
    currency: 'XCD',
    locale: 'en-GD',
    flagIcon: '🇬🇩',
    rate: 1,
  },
  GU: {
    name: 'Guam',
    code: '+1-671',
    currency: 'USD',
    locale: 'en-GU',
    flagIcon: '🇬🇺',
    rate: 1,
  },
};

export const allCountries = { ...countries, ...spanishSpeakingcountries };
