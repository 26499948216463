import qs from 'qs';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryState = (query: any) => {
  const location = useLocation();
  const history = useNavigate();

  const setQuery = useCallback(
    (value: any) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const queryString = qs.stringify(
        { ...existingQueries, [query]: value },
        { skipNulls: true }
      );

      if (queryString) {
        history(`${location.pathname}?${queryString}`);
      }
    },
    [history, location, query]
  );

  return [
    qs.parse(location.search, { ignoreQueryPrefix: true })[query] as any,
    setQuery,
  ];
};
