// Libraries.
import { Form, Button } from 'react-bootstrap';
import { EmailControl, FormTextFloating } from '@meniu/ui';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

// Styles.
import {
  Container,
  WrapperLogin,
  WrapperLoginHero,
} from './styles/login.styles';
import {
  useLogin,
  useGoogleLogin,
  ErrorCodes,
  ILoginError,
} from 'apiClient/api';
import { LoginDTO, LoginResponse } from '@meniu/data';
import { Link } from 'react-router-dom';
import { useApp, UserActions } from 'context/global.context';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from 'shared/changeLang';
import { useListenLogin } from 'hooks/useListenLogin';
import { useRef } from 'react';

const LoginForm = ({
  handleSave,
  isError,
  error,
  isLoading,
  isLoadingGoogle,
}: {
  handleSave: any;
  isError: boolean;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  error?: ILoginError | null;
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<LoginDTO>();
  const ref = useRef<HTMLFormElement>(null);
  const handleFocus = () => {
    if (ref.current?.offsetTop) {
      window.scrollTo(0, ref.current?.offsetTop);
    }
  };

  return (
    <Form
      noValidate={true}
      className="d-flex gap-3 flex-column mb-4"
      onSubmit={handleSubmit(handleSave)}
      ref={ref}
    >
      <EmailControl
        handleFocus={handleFocus}
        errorText={t('Ingresa tu email').toString()}
        placeholder={t('Email').toString()}
      />

      <div>
        <FormTextFloating
          data-test="password"
          type="password"
          placeholder={t('Contraseña')}
          error={errors.password && t('Ingresa tu contraseña')}
          {...register('password', {
            required: true,
          })}
        />
        {errors.password && (
          <small className="text-danger ps-1">
            {t('Ingresa tu contraseña')}
          </small>
        )}
      </div>
      <div className="d-flex justify-content-end mb-0">
        <small>
          <Link className="text-decoration-none text-dark fw-bold" to="/forget">
            {t('¿Olvidaste la contraseña?')}
          </Link>
        </small>
      </div>
      <Button
        variant="dark"
        className="fw-bold"
        type="submit"
        data-test="signin-button"
        disabled={isLoadingGoogle || isLoading}
      >
        {t('Iniciar sesión')}
      </Button>
      {isError && (
        <small className="text-danger">
          {(error?.message && ErrorCodes[error?.message]) || error?.message}
        </small>
      )}
    </Form>
  );
};
export function LoginPage() {
  const { mutate, isError, isLoading, error } = useLogin();
  const { i18n } = useTranslation();

  const [, dispatch] = useApp();

  const { mutate: mutateGoogleLogin, isLoading: isLoadingGoogle } =
    useGoogleLogin();

  const handleMultiLogin = () => {
    const message = {
      type: 'signIn',
      data: 'googleSignIn',
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  const { t } = useTranslation();

  const methods = useForm<LoginDTO>();

  const handleSave = (data: LoginDTO) => {
    mutate(data, {
      onSuccess: (response: LoginResponse) => {
        dispatch(UserActions.setToken(response));
      },
      onError: () => {
        methods.setValue('password', '');
        methods.setFocus('password');
      },
    });
  };

  const handleLoginButton = (googleResponse: CredentialResponse) => {
    mutateGoogleLogin(
      { googleResponse },
      {
        onSuccess: (response) => {
          dispatch(UserActions.setToken(response));
        },
      }
    );
  };

  useListenLogin();

  return (
    <div className="vh-100 bg-white ">
      <Container className="vh-100">
        <WrapperLogin className="vh-100 d-flex flex-column">
          <div className="d-flex flex-row justify-content-between align-items-center p-3 px-sm-0">
            <h4 className="fw-semibold">
              Meniu<span className="text-primary">.</span>
            </h4>
            <div>
              <ChangeLanguage />
            </div>
          </div>
          <div className="px-3 px-md-0 w-100 mt-5">
            <h4 className="fw-bold mb-0">{t('Inicia sesión')}</h4>
            <p className="mb-4">{t('Hola, bienvenido de regreso')}</p>
            <div className="w-100">
              {window?.ReactNativeWebView ? (
                <Button
                  variant="dark"
                  className="fw-bold d-block w-100"
                  onClick={handleMultiLogin}
                  disabled={isLoadingGoogle || isLoading}
                >
                  <div className="d-flex gap-3 align-items-center justify-content-center">
                    <GoogleIcon height="20px" width={'20px'} />
                    <span>{t('Iniciar sesión con Google')}</span>
                  </div>
                </Button>
              ) : (
                <GoogleLogin
                  size="large"
                  type="standard"
                  ux_mode="popup"
                  context="signin"
                  locale={i18n.language}
                  theme="filled_black"
                  state_cookie_domain=".meniuapp.com"
                  onSuccess={handleLoginButton}
                />
              )}
            </div>
            <div className="py-3 d-flex align-items-center w-100">
              <hr className="flex-grow-1 border" />
              <div className="px-3 text-muted">
                <small>{t('ó')}</small>
              </div>
              <hr className="flex-grow-1 border" />
            </div>
            <FormProvider {...methods}>
              <LoginForm
                handleSave={handleSave}
                error={error}
                isError={isError}
                isLoading={isLoading}
                isLoadingGoogle={isLoadingGoogle}
              />
            </FormProvider>
            <div className="d-flex justify-content-center">
              <p>
                {t('¿No tienes cuenta?')}{' '}
                <Link
                  className="fw-bold text-decoration-none text-dark"
                  to="/register"
                >
                  {t('Crea tu cuenta')}
                </Link>
              </p>
            </div>
          </div>
        </WrapperLogin>
        <WrapperLoginHero className=" d-none d-md-block" />
      </Container>
    </div>
  );
}

export default LoginPage;
