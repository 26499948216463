// libraries.
import { Modal } from 'react-bootstrap';

// hooks.
import { useTranslation } from 'react-i18next';
import { useAppState } from 'context/global.context';
import { useEffect, useState } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import useVerifyPro from 'hooks/useVerifyPro';

export function NotificationModal() {
  const [permission, setPermission] = useState('default');
  const { t } = useTranslation();

  const { isPro } = useVerifyPro();
  const {
    user: { _id },
  } = useAppState();

  useEffect(() => {
    async function start() {
      if (!isPro) return;
      try {
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.NX_PUSHER_BEAM_ID || '',
        });

        await beamsClient.start();

        await beamsClient.addDeviceInterest(`order-${_id}`);
      } catch (e) {
        console.log(e);
      }
    }
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
      setPermission('denied');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      setPermission('granted');
      start();
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          start();
        }
        setPermission(permission);
      });
    }

    // Denied
    else if (Notification.permission === 'denied') {
      setPermission('denied');
    }
  }, []);

  return (
    <Modal
      show={isPro && permission !== 'granted' && permission !== 'denied'}
      centered
      className="mobile-modal"
    >
      <Modal.Header className="d-flex align-items-center lead fw-bold px-4 pt-4 pb-0">
        {t('Acepta las notificaciones')}
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column gap-3">
        <p className="mb-3">
          {t(
            'Hola! Para poder notificarte cuando te llegue un pedido nuevo, es necesario que habilites las notificaciones en tu navegar, da clic en aceptar en la parte superior izquierda'
          )}
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default NotificationModal;
