// libraries.
import { Card } from 'react-bootstrap';

// shared components.
// import { PurchaseButton } from '../../../../plans/PurchaseButton';
import { RoleProtectedComponent } from 'shared/protected-component';

// assets.
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as RocketIcon } from 'assets/rocket-outline.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trialDays } from '@meniu/ui';

const Check = ({ text }: { text: string }) => {
  return (
    <small className="gap-2 d-flex">
      <CheckIcon /> {text}
    </small>
  );
};

export function BuyWhatsapp() {
  const { t } = useTranslation();
  return (
    <RoleProtectedComponent requiredRole="user" blackList={['user_pro']}>
      <Card className="border-0 shadow-sm p-3 p-sm-4 w-100 d-flex gap-4 rounded-4">
        <Card.Header className="border-0 bg-white p-0">
          <div className="bg-light p-3 rounded-4 d-inline-block">
            <RocketIcon width="32px" height="32px" />
          </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column gap-2 p-0 text-start">
          <div className="flex-grow-1">
            <h3 className="fw-bold text-break mb-2">
              {t('Plan Business gratis por {{trialDays}} días', {
                trialDays: trialDays,
              })}
              {/* {t('Prueba nuestro Plan Business gratis por {{trialDays}} días')}. */}
            </h3>
            <p className="text-muted mb-3">
              {t(
                'Disfruta de todos los nuevos beneficios por {{trialDays}} días gratis, puedes cancelar en cualquier momento',
                {
                  trialDays: trialDays,
                }
              )}
              .{/* {t('Obtén los siguientes beneficios y más')}. */}
            </p>
            {/*  <ul className="list-unstyled mb-3">
              <li className="mb-1">
                {' '}
                <Check text={t('Personalización de catálogo digital')} />
              </li>
              <li className="mb-1">
                {' '}
                <Check text={t('Sin branding de Meniu')} />
              </li>
              <li className="mb-1">
                {' '}
                <Check text={t('Vende en línea por Whatsapp')} />
              </li>
            </ul> */}
          </div>
          <div className="border-bottom mt-3 border-light"></div>
        </Card.Body>
        <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3">
          <Link
            to="/profile/subscriptions"
            className="w-100 fw-semibold btn btn-light rounded-4"
          >
            {t('Prueba gratis por {{trialDays}} días', {
              trialDays: trialDays,
            })}
          </Link>
        </Card.Footer>
      </Card>
    </RoleProtectedComponent>
  );
}

export function VideoWhatsapp() {
  return (
    <RoleProtectedComponent requiredRole="user" blackList={['user_pro']}>
      <Card className="embed-responsive embed-responsive-16by9 d-flex justify-content-center bg-dark border-0">
        <iframe
          className="mx-auto embed-responsive-item w-100"
          height="400"
          src="https://www.youtube.com/embed/xZVSZ2sFzjo"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Card>
    </RoleProtectedComponent>
  );
}
