import { FeedbackDTO } from '@meniu/data';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useFeedback } from 'apiClient/api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function ContactUs({
  openContactUs,
  setOpenContactUs,
}: {
  openContactUs: boolean;
  setOpenContactUs: any;
}) {
  const { mutate, isLoading } = useFeedback();
  const handleCloseContactUs = () => setOpenContactUs(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FeedbackDTO>({ mode: 'all' });

  const submitFeedback = (data: FeedbackDTO) => {
    mutate(data, {
      onSuccess: () => {
        toast.info(
          t(
            'Tu mensaje se ha enviado, te contactaremos por correo para seguir con tu solicitud'
          )
        );
      },
    });
    handleCloseContactUs();
    reset();
  };

  const { t } = useTranslation();

  return (
    <Modal show={openContactUs} onHide={handleCloseContactUs} centered>
      <Form onSubmit={handleSubmit(submitFeedback)}>
        <Modal.Body className="d-flex flex-column gap-4 p-4">
          <div className="d-flex flex-column gap-2">
            <h4 className="fw-bold">{t('Contáctanos')}</h4>
            <span>
              {t(
                'Cuéntanos como podemos ayudarte, a continuación ingresa detalladamente tu mensaje'
              )}
              .
            </span>
          </div>

          <FloatingLabel label={t('Escribe tu mensaje')}>
            <textarea
              className="form-control"
              style={{ minHeight: '160px' }}
              wrap="hard"
              placeholder={t('Escribe tu mensaje') || ''}
              {...register('feedback', { required: true })}
            />
          </FloatingLabel>
          <div className="d-grid">
            <Button
              className="fw-bold"
              variant="dark"
              type="submit"
              disabled={!isValid || isLoading}
            >
              {t('Enviar mensaje')}
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default ContactUs;
