import { Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FormContainer from './components/form-container.component';
import { sendAnalytics } from '@utils';
import { VerifyForm } from './components/VerifyForm';
import { RegisterForm } from './components/RegisterForm';
import { ForgotPassword } from './components/ForgotPassword';
import { useTranslation } from 'react-i18next';

export enum loginOptions {
  user,
  passwords,
  verify,
  forget,
}

export function AuthForm() {
  const location = useLocation();

  const [nameRestaurant, setNameRestaurant] = useState<string>('');

  const navigate = useNavigate();
  const renderCount = useRef<number>(0);
  const [step, setStep] = useState<loginOptions>(
    location.pathname === '/forget' ? loginOptions.forget : loginOptions.user
  );

  const [email, setEmail] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (
      location.pathname === '/register/user' ||
      location.pathname === '/register/new_password' ||
      location.pathname === '/register/verify'
    ) {
      navigate('/register');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search) {
      setNameRestaurant(location.search.split('=')[1]);
    }
  }, [location.search]);

  useEffect(() => {
    switch (step) {
      case loginOptions.user:
        sendAnalytics('view_user');
        window.history.replaceState(null, '', '/register/user');
        break;
      case loginOptions.forget:
        sendAnalytics('view_forget');
        break;
      case loginOptions.passwords:
        sendAnalytics('view_passwords');
        window.history.replaceState(null, '', '/register/password');
        break;
      case loginOptions.verify:
        sendAnalytics('view_verify');
        window.history.replaceState(null, '', '/register/verify');
        break;
    }

    renderCount.current = renderCount.current + 1;
  }, [step]);

  return (
    <FormContainer>
      <Card
        className="border-0 p-3 shadow-sm w-100"
        style={{ maxWidth: '400px' }}
      >
        <Card.Body className="p-2">
          {step === loginOptions.forget && <ForgotPassword />}
          {step === loginOptions.verify && <VerifyForm email={email} />}
          {step >= loginOptions.user && step <= loginOptions.passwords && (
            <RegisterForm
              step={step}
              setStep={setStep}
              setEmail={setEmail}
              nameRestaurant={nameRestaurant}
            />
          )}
        </Card.Body>
      </Card>

      {step === loginOptions.user && (
        <div className="mt-4">
          <span>{t('¿Ya tienes una cuenta?')}</span>{' '}
          <Link to="/login" className="text-decoration-none text-dark fw-bold">
            {t('Inicia sesión')}
          </Link>
        </div>
      )}
    </FormContainer>
  );
}

export default AuthForm;
