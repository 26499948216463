// libraries.
import { useForm } from 'react-hook-form';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

// libs.

// hooks.
import { useFeedback, useLoginCanny } from 'apiClient/api';
import { useTranslation } from 'react-i18next';
import { FormTextFloating } from '@meniu/ui';
import { UserActions, useApp, useAppState } from 'context/global.context';
import { modalOptions } from 'context/menu.context';

// assets.

interface Form {
  best?: number;
  requested?: string;
  worst?: boolean;
}
export function FeedBackModal() {
  const [{ modal }, dispatch] = useApp();
  const handleClose = () => {
    dispatch(UserActions.closeModal());
    reset();
    resetCall();
  };

  const { mutate, isLoading, data, reset: resetCall } = useFeedback();

  const { t } = useTranslation();

  const {
    user: { email },
  } = useAppState();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<Form>({
    mode: 'all',
  });

  const { mutate: loginCanny, isLoading: isLoadingCanny } = useLoginCanny();
  const doSubmit = (data: Form) => {
    mutate(
      {
        feedback: JSON.stringify({ email, ...(data || {}) }),
      },
      {
        onError: (error) => {
          toast.error(error.message);
        },
      }
    );
  };

  const submit = () => {
    loginCanny(undefined, {
      onSuccess: (data) => {
        window.open(data.url, '_blank');
      },
    });
  };

  return (
    <Modal
      show={modal.includes(modalOptions['send-feedback'])}
      onHide={handleClose}
      centered
      className="mobile-modal"
    >
      <Form onSubmit={handleSubmit(doSubmit)}>
        <Modal.Header className="d-flex align-items-center lead fw-bold px-4 pt-4 pb-0">
          {data?.success ? t('Muchas gracias') : t('Ayúdanos a mejorar')}
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column gap-3">
          {data?.success ? (
            <>
              <p>
                {t(
                  'Hemos recibido tus comentarios, los revisaremos y haremos todo lo posible para implementar tus sugerencias cuanto antes'
                )}
                .
              </p>
              <p>
                {t('Si tienes más comentarios puedes enviarnos un mensaje a')}{' '}
                <a href="mailto:hola@meniuapp.com">hola@meniuapp.com</a>
              </p>
            </>
          ) : (
            <>
              <p className="mb-3">
                {t('Tus comentarios nos ayudan a mejorar tu experiencia')}.
              </p>

              <Form.Group>
                <FormTextFloating
                  placeholder={t('¿Qué es lo que más te gusta?')}
                  {...register('best')}
                />
              </Form.Group>
              {/* <Form.Group>
                <FormTextFloating
                  placeholder={t('¿Qué te gustaría que agregaramos?')}
                  {...register('requested')}
                />
              </Form.Group> */}
              <Form.Group>
                <FormTextFloating
                  placeholder={t('¿Qué es lo que menos te gusta?')}
                  {...register('worst')}
                />
              </Form.Group>
            </>
          )}
          <div className="d-flex gap-2 flex-column">
            <Button
              type="submit"
              hidden={data?.success}
              className="w-100 fw-bold"
              variant="dark"
              disabled={isLoading || !isValid}
            >
              {t('Enviar')}
            </Button>

            <div className="py-2 d-flex align-items-center w-100">
              <hr className="flex-grow-1 border" />
              <div className="px-3">
                <small className="small-xs">
                  {t('Compártenos que te gustaría agregar')}
                </small>
              </div>
              <hr className="flex-grow-1 border" />
            </div>

            <Button
              variant="light"
              className="w-100 fw-semibold bg-white border shadow-sm"
              onClick={submit}
              disabled={isLoadingCanny}
            >
              {t('Solicita una funcionalidad')}
            </Button>

            {data?.success ? (
              <Button
                className="fw-bold text-dark"
                variant="link"
                onClick={handleClose}
                disabled={isLoading}
              >
                {t('Cerrar')}
              </Button>
            ) : (
              <Button
                className="fw-bold text-dark"
                variant="link"
                onClick={handleClose}
                disabled={isLoading}
              >
                {t('Cancelar')}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default FeedBackModal;
