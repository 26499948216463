// Libraries
import styled from 'styled-components';

// Assets.
import HeroLogin from '../assets/hero-login.jpg';

export const Container = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, [col] 1fr);
  grid-column-gap: 0;
  @media (min-width: 768px) {
    grid-column-gap: 40px;
  }
  @media (min-width: 1200px) {
  }
`;

export const WrapperLogin = styled.div`
  grid-column: 1/13;
  @media (min-width: 768px) {
    grid-column: 1/6;
    padding-left: 40px;
  }
`;

export const WrapperLoginHero = styled.div`
  grid-column: 6 / 13;
  background-image: url(${HeroLogin});
  background-size: cover;
  background-position: center;
`;


