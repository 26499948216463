import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useRecoverPassword } from 'apiClient/api';
import { useAppState } from 'context/global.context';
import { useTranslation } from 'react-i18next';

export function ChangePassword({
  openChangePassword,
  setOpenChangePassword,
}: {
  openChangePassword: boolean;
  setOpenChangePassword: any;
}) {
  const { user } = useAppState();
  const { mutate } = useRecoverPassword();
  const handleCloseContactUs = () => setOpenChangePassword(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (user.email && openChangePassword) {
      mutate({ email: user.email });
    }
  }, [user.email, openChangePassword]);

  return (
    <Modal show={openChangePassword} onHide={handleCloseContactUs} centered>
      <Form>
        <Modal.Body className="d-flex flex-column gap-4 p-4">
          <div className="d-flex flex-column gap-2">
            <h4 className="fw-bold">{t('Revisa tu correo')}</h4>
            <span>
              {t(
                'Te hemos mandado un link a tu correo para cambiar tu contraseña'
              )}
              .
            </span>
          </div>

          <div className="d-grid">
            <Button
              className="fw-bold"
              variant="dark"
              onClick={handleCloseContactUs}
            >
              {t('Cerrar')}
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default ChangePassword;
