import { useCallback, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FormTextFloating } from './formFloating';
import { UseFormRegister } from 'react-hook-form';
import { spanishSpeakingcountries } from './countriesInfo';

export const CurrencySelector = ({
  register,
  errors,
  setValue,
}: {
  register: UseFormRegister<any>;
  errors?: any;
  setValue?: any;
}) => {
  return (
    <>
      <Form.Select
        {...register('currency')}
        onChange={(event) => {
          setValue(
            'locale',
            (spanishSpeakingcountries as any)[
              event.target.selectedOptions[0].id
            ].locale
          );
        }}
      >
        {Object.keys(spanishSpeakingcountries).map((country: any, key) => {
          return (
            <option
              key={key}
              value={(spanishSpeakingcountries as any)[country]?.currency}
              id={country}
            >
              {(spanishSpeakingcountries as any)[country]?.currency}
            </option>
          );
        })}
      </Form.Select>
      <input hidden {...register('locale')} />
    </>
  );
};

export default CurrencySelector;
