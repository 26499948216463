import { Menu } from '@meniu/data';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeleteMenu } from 'apiClient/api';
import {
  useMenuContext,
  MenuActions,
  modalOptions,
} from 'context/menu.context';
import { FormTextFloating } from '@meniu/ui';
import { useTranslation } from 'react-i18next';

export function DeleteMenuModal({
  show,
  close,
  openEdit,
  menu,
}: {
  show: boolean;
  close: any;
  openEdit: any;
  menu: Menu;
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleCancel = () => {
    reset();
    openEdit();
    close();
  };

  const { mutate, isLoading } = useDeleteMenu({ menuId: menu._id });

  const handleDelete = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          handleCancel();
          navigate('/');
        },
      }
    );
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  return (
    <Modal
      show={show}
      centered
      contentClassName="p-1"
      backdropClassName="zi-1055"
    >
      <Modal.Header>
        <h4 className="fw-bold">{t('Borrar menú')}</h4>
      </Modal.Header>
      <Modal.Body className="">
        <Form
          className="d-flex flex-column gap-4"
          onSubmit={handleSubmit(handleDelete)}
        >
          <div>
            <span>
              {t(
                '¿Estás seguro que quieres borrar tu menú? No podrás recuperarlo'
              )}
              .
            </span>
          </div>
          <div>
            <FormTextFloating
              placeholder={t("Ingresa el nombre para confirmar")}
              {...register('name', {
                required: true,
                validate: () => {
                  return getValues('name') === menu.name;
                },
              })}
            />
            <small className="text-muted">
              <b>{t('Nombre')}:</b> {menu.name}
            </small>
          </div>
          <div className="d-flex gap-2 flex-column">
            <Button
              className="fw-bold"
              variant="danger"
              type="submit"
              disabled={!isValid || isLoading}
            >
              {t('Borrar menú')}
            </Button>
            <Button
              className="fw-bold text-dark"
              variant="link"
              onClick={handleCancel}
              disabled={isLoading}
            >
              {t('Cancelar')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function DeleteMenu({ menu }: { menu: Menu }) {
  const [{ modal }, dispatch] = useMenuContext();

  const handleOpenEdit = () =>
    dispatch(MenuActions.setModal(modalOptions['edit-menu']));

  const handleClose = () => dispatch(MenuActions.closeModal());

  return (
    <DeleteMenuModal
      show={modal.includes(modalOptions['delete-menu'])}
      close={handleClose}
      openEdit={handleOpenEdit}
      menu={menu}
    />
  );
}

export default DeleteMenu;
