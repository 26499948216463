// libraries.
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Title } from './components/Title';
import { OpenModal } from './components/OpenModal';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormToCancel } from './components/FormToCancel';
import { CloseModal } from './components/CloseModal';

interface IFormToCancel {
  cancelOption: string;
  cancelReason: string;
}

export interface CancelSubscriptionOnSubmitArgs {
  cancelOption: string;
  cancelReason: string;
}

interface Props {
  // onChange: CancelOnSubmitArgs;
  onChange: (args: CancelSubscriptionOnSubmitArgs) => void;
  optionsForCancel?: { id: string; label: string }[];
}

export const UiCancelSubscription = ({ onChange, optionsForCancel }: Props) => {
  const [show, setShow] = useState(false);

  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormToCancel>();

  const onSubmit = (data: IFormToCancel) => {
    onChange &&
      onChange({
        cancelOption: data.cancelOption,
        cancelReason: data.cancelReason,
      });
    closeModal();
  };

  return (
    <div className="d-flex flex-column flex-sm-row gap-3 w-100">
      <OpenModal openModal={openModal} />
      <Modal
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
        className="mobile-modal"
      >
        <Title />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormToCancel
            register={register}
            errors={errors}
            onSubmit={onSubmit}
            optionsForCancel={optionsForCancel}
          />
          <CloseModal closeModal={closeModal} />
        </Form>
      </Modal>
    </div>
  );
};
