import { ReactElement } from 'react';
import useVerifyPro from 'hooks/useVerifyPro';
import { ReactComponent as Crown } from 'assets/crown.svg';

export const PlanBadge = ({ children }: { children?: any }) => (
  // <LockIcon height="20px" stroke="#6C757D" />
  <span className="badge rounded-2 shadow-sm bg-white text-secondary border fw-semibold d-flex align-items-center gap-1">
    {children || (
      <>
        <Crown height="14px" width="14px"></Crown>
        <small>Business</small>
        {/* <LockIcon height="12px" stroke="#6C757D" /> */}
        {/* <small>Business</small> */}
      </>
    )}
  </span>
);

export const PlanTag = ({ children }: { children?: ReactElement }) => {
  const { isPro } = useVerifyPro();
  return isPro ? null : (
    <div className="position-absolute top-0 start-50 translate-middle">
      <PlanBadge>{children}</PlanBadge>
    </div>
  );
};
export default PlanTag;
