import { useEffect } from 'react';
import { useGoogleLogin } from 'apiClient/api';
import Cookies from 'js-cookie';
import { UserActions, useApp } from 'context/global.context';

export const useListenLogin = () => {
  const { mutate: mutateGoogleLogin } = useGoogleLogin();
  const [{ auth, demo }, dispatch] = useApp();

  useEffect(() => {
    function login(credential: string) {
      mutateGoogleLogin(
        {
          googleResponse: {
            clientId: credential,
            credential: credential,
          },
          demo: demo,
          utm: Cookies.get('utm'),
          sessionId: Cookies.get('sessionId'),
          variant: Cookies.get('variant'),
        },
        {
          onSuccess: (response) => {
            dispatch(UserActions.setToken(response));
          },
          onError: (err) => {
            console.error(err);
          },
        }
      );
    }

    const handleEvent = (event: any) => {
      if (event.data?.type === 'login') {
        const credential = event.data.idToken;
        login(credential);
      }
    };

    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
  }, []);
};
