import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useAppState,
  UserActions,
} from 'context/global.context';
import { useQueryClient } from 'react-query';

function Logout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isLogged } = useAppState();

  useEffect(() => {
    const handleLogout = () => {
      try {
        queryClient.clear();
        dispatch(UserActions.logout());
      } catch (e) {
        console.error(e);
        navigate('/', { replace: true });
      }
    };
    if (isLogged) {
      handleLogout();
    } else {
      navigate('/', { replace: true });
    }
  }, []);
  return null;
}
export default Logout;
