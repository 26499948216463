// libraries.
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PaymentInfo } from './components/PaymentInfo';
import { useTranslation } from 'react-i18next';

import {
  MenuActions,
  modalOptions,
  useMenuContext,
} from 'context/menu.context';
import { useMyMenus } from 'apiClient/api';

export const ModalPaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: menus, isLoading } = useMyMenus({ page: 1 });

  const menuId = menus?.docs?.[0]?._id;

  const [showIsPaymentSuccess, setShowIsPaymentSuccess] = useState(false);

  useEffect(() => {
    const isPaymentSuccess = location.pathname.includes('payment-success');

    if (isPaymentSuccess) {
      setShowIsPaymentSuccess(true);
    }
  }, [location]);

  const { _menuId } = useParams();
  const [{ modal }, dispatch] = useMenuContext();
  const closeModal = () => dispatch(MenuActions.closeModal());

  const { t } = useTranslation();

  return (
    <Modal show={showIsPaymentSuccess} centered>
      <Modal.Header className="d-flex flex-column align-items-start">
        <Modal.Title className="fw-semibold">
          {t('Ahora eres Premium')}
        </Modal.Title>
        <p>
          {t(
            'Bienvenido a Meniu Business, ahora tienes acceso a todas nuestras funcionalidades exclusivas'
          )}
          .
        </p>
      </Modal.Header>
      <Modal.Body>
        <PaymentInfo />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="dark"
          className="w-100 fw-bold bg-dark"
          onClick={() => {
            setShowIsPaymentSuccess(false);
            if (menuId) {
              navigate(`/menu-editor/${menuId}?tab=editor`);
            }
          }}
        >
          {t('Continuar')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
