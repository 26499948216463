// Libs
import { Suspense, lazy, useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  Navigate,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { UserActions, useApp, useAppState } from './context/global.context';
import ReactGA from 'react-ga4';

// Pages
import AuthForm from './pages/auth/auth.page';
import Forgot from './pages/auth/forgot.page';
import Logout from './pages/auth/logout.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import MenuEditor from './pages/menu/menu-editor.page';

import Payment from './pages/payment/payment.page';
import Plans from './pages/plans/plans.page';
import Profile from './pages/profile/profile.page';
import NavBarComponent from 'shared/navbar.component';
import Recover from './pages/auth/recover.page';
import Demo from './pages/menu/demo.page';
import Login from './pages/auth/login.page';
import * as Sentry from '@sentry/react';

import Loading from 'shared/loading.component';

// Modals
import RegisterModal from './pages/menu/modals/register.modal';
import WarningLoginModal from './pages/menu/modals/warning-login.modal';
import BuyProModal from './pages/plans/modals/buy-pro.modal';
import FeedBackModal from 'shared/feedback.modal';
import { NotificationModal } from 'shared/notifications.modal';
import { PlanInfo } from 'shared/planinfo';
import { useExitIntent } from 'use-exit-intent';
import { modalOptions } from 'context/menu.context';
import { Ambassadors } from './pages/admin/components/ambassadors/Ambassadors';
import PromoModal from 'shared/modals/Promo.modal';
import { Sidebar } from './Sidebar';
import { sideBarWidth, topNavBarHeight } from '@constants';
import { Logo } from './Logo';
import { NotFound } from './NotFound';
import { Protected } from './Protected';
import useMediaQuery from 'hooks/useMediaQuery';
import ManagedMenus from './pages/managed-menus/managed-menus.page';
import { RolesEnum } from '@meniu/utils';
import RelevantFeatures from 'shared/modals/RelevantFeatures.modal';

// const Terminal = lazy(() => import('./pages/terminal/terminal.page'));
const AdminDashboard = lazy(() => import('./pages/admin/admin.page'));
const UsersTable = lazy(() => import('./pages/admin/components/UsersTable'));
const Analytics = lazy(() => import('./pages/admin/components/Analytics'));
const Convertions = lazy(() => import('./pages/admin/components/Convertions'));
// const Queries = lazy(() => import('./pages/admin/components/Queries'));
const StripeLogs = lazy(() => import('./pages/admin/components/StripeLogs'));
const CoversPage = lazy(() => import('./pages/covers/covers.page'));
const CoverPage = lazy(() => import('./pages/covers/cover.page'));
const Events = lazy(() => import('./pages/admin/components/Events'));
const ReservationsPage = lazy(
  () => import('./pages/reservations/reservations.page')
);
const Referals = lazy(
  () => import('./pages/menu/components/promoters/promoters')
);
const Clients = lazy(() => import('./pages/menu/components/clients/clients'));
const MenuTable = lazy(() => import('./pages/admin/components/MenuTable'));
const ViewsDashboard = lazy(
  () => import('./pages/admin/components/ViewsDashboard')
);
const OrdersPage = lazy(() => import('./pages/orders/orders.page'));
const Subscriptions = lazy(
  () => import('./pages/profile/subscriptions/subscriptions.page')
);
const Tutorials = lazy(() => import('./pages/tutorials/tutorials.page'));
const ReservationPage = lazy(
  () => import('./pages/reservations/reservation.page')
);
const PaymentLinks = lazy(
  () => import('./pages/payment-links/payment-links.page')
);

const Layout = () => <Outlet />;

const Modals = () => (
  <>
    <BuyProModal />
    <RegisterModal />
    <WarningLoginModal />
    <FeedBackModal />
    <PromoModal />
    <NotificationModal />
    <RelevantFeatures />
  </>
);

const MainLayout = () => {
  const isXS = useMediaQuery('(max-width:991.9px)');
  return (
    <>
      <NavBarComponent />
      <div className="h-100 " style={{ paddingTop: topNavBarHeight }}>
        <Sidebar />
        <PlanInfo />
        <div
          style={
            isXS
              ? {}
              : {
                  width: `calc(100% - ${sideBarWidth})`,
                  marginLeft: sideBarWidth,
                  height: '100%',
                }
          }
        >
          <Outlet />
        </div>
        <Modals />
        <Logo />
      </div>
    </>
  );
};

function RequireAuth({
  children,
  userRole,
}: {
  children: JSX.Element;
  userRole?: string;
}) {
  const app = useAppState();

  const location = useLocation();

  if (!app.auth?.access_token) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }

  if (userRole && !app.user.roles?.includes(userRole)) {
    return <Navigate to="/protected" replace={true} />;
  }

  return children;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AllRoutes = () => {
  const location = useLocation();

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  useEffect(() => {
    ReactGA.event('page_view', location.pathname + location.search);
  }, [location.pathname, location.search]);
  // const { registerHandler } = useExitIntent();
  const navigate = useNavigate();

  const [{ demo, user }, dispatch] = useApp();

  useEffect(() => {
    if (!user) return;

    if (location.pathname === '/payment-success') {
      setIsPaymentSuccess(true);
      return;
    }
    if (
      location.pathname !== '/buy' &&
      user.email?.includes('@') &&
      !user.viewedPlan &&
      !user.roles?.includes('user_pro') &&
      !user.trialExpired &&
      user.isVerified &&
      !user.isManagedUser &&
      !isPaymentSuccess
    ) {
      navigate('/buy');
    }
  }, [location.pathname, user?.email, user.isVerified]);

  /* registerHandler({
    id: 'openModal',
    handler: () =>
      demo && dispatch(UserActions.setModal(modalOptions.register)),
  }); */

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path="/payment-success"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route path="/menu-editor/:_menuId" element={<MenuEditor />} />

          <Route
            path="/managed-menus"
            element={
              <RequireAuth>
                <ManagedMenus />
              </RequireAuth>
            }
          />

          <Route
            path="/payment-links"
            element={
              <Suspense fallback={<Loading />}>
                <RequireAuth>
                  <PaymentLinks />
                </RequireAuth>
              </Suspense>
            }
          />

          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/covers/:coverId"
            element={
              <RequireAuth
              // userRole="user_pro"
              >
                <Suspense fallback={<Loading />}>
                  <CoverPage />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/profile/subscriptions"
            element={
              <Suspense fallback={<Loading />}>
                <RequireAuth>
                  <Subscriptions />
                </RequireAuth>
              </Suspense>
            }
          />

          <Route
            path="/reservations"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <ReservationsPage />
                </Suspense>
              </RequireAuth>
            }
          />

          <Route
            path="/reservations/:menuId"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <ReservationPage />
                </Suspense>
              </RequireAuth>
            }
          />

          <Route
            path="/covers"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <CoversPage />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/plans"
            element={
              <RequireAuth>
                <Plans />
              </RequireAuth>
            }
          />

          <Route
            path="/referals"
            element={
              <Suspense fallback={<Loading />}>
                <RequireAuth>
                  <Referals />
                </RequireAuth>
              </Suspense>
            }
          />
          <Route
            path="/clients"
            element={
              <Suspense fallback={<Loading />}>
                <RequireAuth>
                  <Clients />
                </RequireAuth>
              </Suspense>
            }
          />
          <Route
            path="/tutorial"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <Tutorials />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <AdminDashboard />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/views"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <ViewsDashboard />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <UsersTable />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/analytics"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <Analytics />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/convertions"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <Convertions />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/logs"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <StripeLogs />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/events"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <Events />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/menus"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <MenuTable />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/admin/ambassadors"
            element={
              <RequireAuth userRole={RolesEnum.Admin}>
                <Suspense fallback={<Loading />}>
                  <Ambassadors />
                </Suspense>
              </RequireAuth>
            }
          />

          {/* <Route
            path="/terminal/:_menuId"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <Terminal />
                </Suspense>
              </RequireAuth>
            }
          /> */}
        </Route>

        <Route
          path="/orders"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <OrdersPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/buy"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <div className="pt-5">
                  <NavBarComponent hideButtons />
                  <Subscriptions asPage />
                </div>
              </Suspense>
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/forget" element={<AuthForm />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/recover/:recoveryCode" element={<Recover />} />
        <Route path="/verify/:recoveryCode" element={<Recover />} />
        <Route path="/protected" element={<Protected />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/register/:query" element={<AuthForm />}></Route>
        <Route path="/register" element={<AuthForm />}>
          <Route path=":uuid/:email" element={<AuthForm />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </SentryRoutes>
  );
};

export default AllRoutes;
