// context.
import { toCurrency } from '../../../utils';
import { useTipContext } from '../context';
import { useTranslation } from 'react-i18next';

/**
 * Show the amount of the tip
 * @returns component
 */
export const FinalTip = () => {
  const [{ tipAmount, locale, currency }] = useTipContext();
  const tipAmountWithCurrency = toCurrency(tipAmount, locale, currency);
  const { t } = useTranslation();

  return (
    <>
      <div className="border-bottom border-light pt-2"></div>
      <div className="d-flex justify-content-between my-2">
        <p className="fw-semibold ">{t('Propina')}</p>
        <p className="fw-semibold">{tipAmountWithCurrency}</p>
      </div>
    </>
  );
};
