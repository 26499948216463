import { format } from 'date-fns';
import { es, enUS as en, pt } from 'date-fns/locale';

export const localeSelector = (locale = '') => {
  switch (locale) {
    case 'es':
      return es;
    case 'en':
      return en;
    case 'pt':
      return pt;
    default:
      return es;
  }
};

type DayInfo = {
  isOpen: boolean;
  openingTime: string;
  closingTime: string;
};

export const BusinessHours = ({
  schedule,
  onlyOpen,
  labels = {
    nonAvailable: 'Horario no disponible',
    closed: 'Cerrado',
    from: 'desde las',
    open: 'Abierto',
  },
  locale,
}: {
  schedule: any;
  locale?: string;
  onlyOpen?: boolean;
  labels?: {
    nonAvailable: string;
    closed: string;
    open: string;
    from: string;
  };
}) => {
  if (!schedule || Object.keys(schedule).length === 0) {
    return null;
  }

  const convertTo24HourFormat = (time: any) => {
    if (!time) return;
    const [hours, minutes] = time.split(':');
    const isPM = time.endsWith('PM');
    const date = new Date();
    date.setHours(parseInt(hours) + (isPM && hours !== '12' ? 12 : 0));
    date.setMinutes(parseInt(minutes));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const isOpenNow = (dayInfo: DayInfo): boolean => {
    if (!dayInfo?.isOpen) return false;

    if (!dayInfo.openingTime || !dayInfo.closingTime) return false;

    const currentTime = new Date();

    const openingTime24 = convertTo24HourFormat(dayInfo.openingTime);
    const closingTime24 = convertTo24HourFormat(dayInfo.closingTime);

    if (openingTime24 === closingTime24) return true;
    if (!openingTime24 || !closingTime24) return false;

    const isOpen = currentTime >= openingTime24 && currentTime <= closingTime24;
    return isOpen;
  };

  const currentDayName = new Date()
    .toLocaleString('en-US', { weekday: 'long' })
    .toLowerCase();
  const currentDayInfo = schedule[currentDayName];

  if (!currentDayInfo) {
    return <p>{labels.nonAvailable}</p>;
  }

  const isOpen = isOpenNow(currentDayInfo);

  if (onlyOpen)
    return (
      <small className="text-muted d-flex gap-2">
        {isOpen ? 'Abierto' : 'Cerrado'}
      </small>
    );

  if (!currentDayInfo.openingTime || !currentDayInfo.closingTime) {
    return (
      <div className="d-flex gap-1">
        <p className="fw-bold text-capitalize">
          {format(new Date(), 'iiii', { locale: localeSelector(locale) })}
        </p>
        <p>{labels.nonAvailable}</p>
      </div>
    );
  }

  return (
    <>
      {!isOpen && <small className="fw-bold">{labels.closed}</small>}
      {isOpen && (
        <small className="d-flex align-items-center">
          <span className="text-md-start text-capitalize d-inline-block d-md-inline me-1">
            {labels.open}
          </span>
          {labels.from}
          <span className="fw-bold mx-1"> {currentDayInfo.openingTime} </span>a
          <span className="fw-bold mx-1">{currentDayInfo.closingTime}</span>
        </small>
      )}
    </>
  );
};

export default BusinessHours;
