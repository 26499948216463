export const toMXN = (number: number) =>
  new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(
    number
  );

export const calculateDelivery = (
  deliveryType?: string,
  deliveryPrice?: number
) => (deliveryType === 'delivery' ? deliveryPrice || 0 : 0);

export const calculateTip = (
  total: number,
  tipSelected: number,
  tipTyped: number
) => total + calculateTipOnly(tipSelected, tipTyped, total);

export const calculateTipOnly = (
  total: number,
  tipSelected: number,
  tipTyped: number
) => (tipSelected === -1 ? tipTyped : (total * tipSelected) / 100);

export const truncateText = (text = '', maxLength = 100) => {
  return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
};

export const createMongoId = () => {
  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s: any) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

  return ObjectId();
};

export const getRandom = () => Math.floor(Math.random() * 10000 + 1);

export function humanizeDistance(distance: number) {
  if (distance < 1000) {
    return `${distance.toFixed(0)} m`;
  } else {
    return `${(distance / 1000).toFixed(2)} kms`;
  }
}
