import { DeliveryTypesEnum, PaymentTypesEnum } from '@meniu/utils';

export const DeliveryTypes: Array<{ label: string; name: DeliveryTypesEnum }> =
  [
    {
      label: 'Entrega a domicilio',
      name: DeliveryTypesEnum.delivery,
    },
    {
      label: 'Recoger en sucursal',
      name: DeliveryTypesEnum.pickup,
    },
    {
      label: 'Ordenar en sitio',
      name: DeliveryTypesEnum.restaurant,
    },
  ];

export const PaymentTypes: Array<{
  label: string;
  name: PaymentTypesEnum;
  method: string;
}> = [
  { method: 'in_person', label: 'Efectivo', name: PaymentTypesEnum.cash },
  {
    method: 'in_person',
    label: 'Tarjeta débito / crédito (terminal)',
    name: PaymentTypesEnum.card,
  },
  {
    method: 'in_person',
    label: 'Transferencia',
    name: PaymentTypesEnum.transfer,
  },
  {
    method: 'online',
    label: 'Pago en línea (Stripe)',
    name: PaymentTypesEnum.stripe,
  },
  {
    method: 'many',
    label: 'many',
    name: PaymentTypesEnum.many,
  },
];

export const sideBarWidth = '72px';

export const topNavBarHeight = '56px';
