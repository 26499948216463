import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface CancelOption {
  id: string;
  label: string;
}

export const FormToCancel = ({ register, errors, optionsForCancel }: any) => {
  const { t } = useTranslation();

  const cancelOptions: CancelOption[] = [
    {
      id: 'i-am-not-satisfied',
      label: t('No estoy satisfecho con el servicio'),
    },
    {
      id: 'i-found-a-better-offer',
      label: t('Encontré una mejor oferta'),
    },
    {
      id: 'i-dont-need-the-service',
      label: t('No necesito el servicio'),
    },
    {
      id: 'other',
      label: t('Otro'),
    },
  ];

  const options = (optionsForCancel || cancelOptions) as CancelOption[];

  return (
    <Modal.Body className="pb-0 px-4">
      <p className="mb-3 fw-semibold">
        {t('¿Cuál es el motivo de la cancelación?')}
      </p>
      <div className="mb-3 d-flex flex-column gap-2">
        {options.map((option) => (
          <Form.Check
            key={option.id}
            type="radio"
            id={`cancelOption-${option.id}`}
            {...register('cancelOption', { required: true })}
            value={option.id}
            label={option.label}
          />
        ))}
        {errors.cancelOption && <span>{t('Este campo es requerido')}</span>}
      </div>
      <div className="mb-3">
        <div className="d-flex flex-column mb-3">
          <label htmlFor="cancelReason" className="form-label fw-semibold">
            {t('Podrías contarnos a detalle por qué deseas cancelar')}
          </label>
          <small>
            {t('Tus comentarios son valiosos y nos ayudan a mejorar')}
            <span className="ms-2">🙏</span>
          </small>
        </div>
        <textarea
          placeholder={t('Agrega aquí tus comentarios')}
          className="form-control"
          {...register('cancelReason', { required: true })}
          id="cancelReason"
          rows={3}
        ></textarea>
        {errors.cancelReason && (
          <span className="text-danger">{t('Este campo es requerido')}</span>
        )}
      </div>
    </Modal.Body>
  );
};
