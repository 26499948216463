import React from 'react';
import { useSetCountry } from './hooks/useSetCountry';
import { allCountries } from './countriesInfo';

export const CurrencyConvertor = ({ valueInMxn }: { valueInMxn: number }) => {
  const { currentCountry, isLoading } = useSetCountry();

  if (isLoading) return null;

  const country = allCountries[currentCountry];
  return (
    <>
      {new Intl.NumberFormat(allCountries[currentCountry]?.locale || 'es-MX', {
        style: 'currency',
        currency: country?.currency,
      }).format(valueInMxn * country?.rate || 1)}
    </>
  );
};
