import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormContainer from './pages/auth/components/form-container.component';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <FormContainer>
      <Card className="border-0 p-3 shadow-sm" style={{ width: '400px' }}>
        <Card.Body className="d-flex flex-column gap-3 align-items-center">
          <h3 className="text-center">{t('Página no encontrada')}</h3>
          <p>
            <Link to="/" className="fw-bold text-decoration-none text-dark">
              {t('Ir a inicio')}
            </Link>
          </p>
        </Card.Body>
      </Card>
    </FormContainer>
  );
};
