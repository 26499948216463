import { Form } from 'react-bootstrap';
import { EmailControl, FormTextFloating } from '@meniu/ui';
import { PhoneControl } from '@meniu/ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { use } from 'passport';

export const CreateUser = ({ nameRestaurant }: { nameRestaurant?: string }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const ref = useRef<HTMLDivElement>(null);
  const handleFocus = () => {
    if (ref.current?.offsetTop) {
      window.scrollTo(0, ref.current?.offsetTop + 100);
    }
  };

  useEffect(() => {
    if (nameRestaurant) {
      setValue('company', nameRestaurant);
    }
  }, [nameRestaurant]);

  return (
    <>
      <Form.Group>
        <div ref={ref}>
          <FormTextFloating
            placeholder={t('Nombre de tu negocio*')}
            type="text"
            isInvalid={!!errors.company}
            id="company_name_input"
            error={errors.company && t('Ingresa el nombre del negocio')}
            {...register('company', { required: true })}
            disabled={!!nameRestaurant}
          />
        </div>
      </Form.Group>
      <div className="d-flex flex-column gap-1">
        <EmailControl
          handleFocus={handleFocus}
          errorText={t('Ingresa tu correo').toString()}
          placeholder={t('Email').toString()}
        />
        <small className="text-muted small-xs">
          {t('Enviaremos un código de verificación a tu email')}
        </small>
      </div>
      {/* </Form.Group> */}
      <PhoneControl
        text={{
          loading: t('Cargando'),
          placeholder: t('Teléfono*'),
          error: t('Ingresa tu teléfono'),
        }}
      />
    </>
  );
};
