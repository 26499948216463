import { useAppState } from 'context/global.context';

export function ProtectedComponent({
  children,
  rol,
}: {
  children: JSX.Element;
  rol: string;
}) {
  const { user } = useAppState();
  if (!user.roles?.includes(rol)) return null;
  return children;
}
export default ProtectedComponent;

export function RoleProtectedComponent({
  children,
  requiredRole,
  blackList,
}: {
  children: JSX.Element;
  requiredRole: string;
  blackList: string[];
}) {
  const { user } = useAppState();

  if (!user) return null;

  const isBlacklisted = blackList.some((blacklistedRole) =>
    user.roles?.includes(blacklistedRole)
  );

  const hasRole = user.roles?.includes(requiredRole);

  if (!hasRole || isBlacklisted) return null;

  return children;
}
