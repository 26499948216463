export const trialDays = 7;
export const monthPrice = 99;
export const semesterPrice = 399;
export const anualPrice = 499;
export const semesterDiscount = 33;
export const anualDiscount = 58;
export const semesterDiscountAmount = 195;
export const anualDiscountAmount = 689;
export const totalRestaurants = 4000;
export const defaultCountry = 'MX';
