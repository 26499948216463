import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Title = () => {
  const { t } = useTranslation();
  return (
    <Modal.Header closeButton className="pt-4 px-4 pb-0">
      <Modal.Title className="lead fw-bold">
        {t('Cancelar suscripción')}
      </Modal.Title>
    </Modal.Header>
  );
};
