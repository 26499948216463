export enum DeliveryTypesEnum {
  delivery = 'delivery',
  pickup = 'pickup',
  restaurant = 'restaurant',
}

export enum PaymentTypesEnum {
  cash = 'cash',
  card = 'card',
  creditCard = 'credit-card',
  debitCard = 'debit-card',
  transfer = 'transfer',
  stripe = 'stripe',
  many = 'many',
}

export enum ErrorCodesEnum {
  OutOfStock = 'OutOfStock',
}

export enum RolesEnum {
  User = 'user',
  Admin = 'admin',
  UserPro = 'user_pro',
  Waiter = 'waiter',
}

export enum OrderTypeEnum {
  terminal = 'terminal',
  restaurant = 'restaurant',
  whatsapp = 'whatsapp',
  table = 'table',
}
