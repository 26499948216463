import { Modal } from 'react-bootstrap';
import { modalOptions } from 'context/menu.context';
import { RegisterForm } from '../../auth/components/RegisterForm';
import { VerifyForm } from '../../auth/components/VerifyForm';
import { useEffect, useState } from 'react';
import { loginOptions } from '../../auth/auth.page';
import { UserActions, useApp } from 'context/global.context';
import { sendAnalytics } from '@utils';

export function RegisterModal() {
  const [{ modal }, dispatch] = useApp();
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);

  const handleClose = () => {
    setStep(0);
    dispatch(UserActions.closeModal());
  };

  useEffect(() => {
    if (modal?.includes(modalOptions['register'])) {
      sendAnalytics('view_register_modal');
    }
  }, [modal]);

  return (
    <Modal
      show={modal?.includes(modalOptions['register'])}
      onHide={handleClose}
      centered={true}
      backdropClassName="zi-1055"
      contentClassName="p-2 "
      className="mobile-modal modal-lg"
    >
      <Modal.Header closeButton className="d-flex pt-3 pb-0">
        <span className="lead fw-bold"></span>
      </Modal.Header>
      <Modal.Body>
        {step === loginOptions.verify && <VerifyForm email={email} />}
        {step >= loginOptions.user && step <= loginOptions.passwords && (
          <RegisterForm
            step={step}
            setStep={setStep}
            setEmail={setEmail}
            isModal
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default RegisterModal;
