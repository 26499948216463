import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { RecoverDTO } from '@meniu/data';
import { useRecoverPassword } from 'apiClient/api';
import { EmailControl } from '@meniu/ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ForgotPasswordWrapper = () => {
  const {
    mutate: mutateRecover,
    error: loadingError,
    isError,
    isSuccess,
    isLoading,
  } = useRecoverPassword();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<RecoverDTO>();

  const resetPassword = (forgot: RecoverDTO) => {
    mutateRecover(forgot);
  };

  const email = watch('email');
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="mb-2 fw-bold">
        {isSuccess
          ? t('Necesitamos verificar tu identidad')
          : t('Restablecer contraseña')}
      </h4>
      {isSuccess ? (
        <span>
          {t(
            'Si tienes una cuenta registrada con nosotros, te llegará un email a {{email}} con el link para que puedas restablecer tu contraseña',
            { email }
          )}
        </span>
      ) : (
        <span>
          {t(
            'Ingresa tu email y te enviaremos un correo para que puedas cambiar tu contraseña'
          )}
        </span>
      )}
      {!isSuccess && (
        <Form className="d-flex mt-4 " onSubmit={handleSubmit(resetPassword)}>
          <div className="d-flex flex-column w-100">
            <EmailControl
              errorText={t('El correo es requerido').toString()}
              placeholder={t('Email*').toString()}
            />

            {isError && (
              <small className="text-danger">
                {t('Hubo un error con tu código de verificación')}
                {loadingError.message}
              </small>
            )}
            <div className="mt-3 d-grid">
              <Button
                variant="dark"
                className="fw-bold mt-3 "
                type="submit"
                disabled={isLoading}
              >
                {t('Restablecer contraseña')}
              </Button>
              <Link
                className="text-dark fw-bold text-center text-decoration-none mt-4"
                to="/login"
              >
                {t('Regresar')}
              </Link>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export const ForgotPassword = () => {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <ForgotPasswordWrapper></ForgotPasswordWrapper>
    </FormProvider>
  );
};
