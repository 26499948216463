import './instrumentation';
import App from './app/app';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

const TRACKING_ID = 'G-1EXCNRMPTF';
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: 'GTM-TTML9RN',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <StrictMode>
  <App />
  // </StrictMode>
);
