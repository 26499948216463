import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as GlobeIcon } from 'assets/globe.svg';

export const ChangeLanguage = ({
  className,
  asDropDown,
  showText = true,
}: {
  className?: string;
  asDropDown?: boolean;
  showText?: boolean;
}) => {
  const {
    i18n: {
      language,
      changeLanguage,
      options: { resources },
    },
    t,
  } = useTranslation();
  const handleChangeLanguage = (e: string) => {
    changeLanguage(e);
  };

  const lang = useMemo<any>(
    () => ({
      es: t('Español'),
      en: t('Inglés'),
      pt: t('Portugués'),
    }),
    [t]
  );

  return asDropDown ? (
    <div className="dropdown-no-arrow">
      <Dropdown drop="start">
        <Dropdown.Toggle
          variant="default"
          size="sm"
          id="dropdown-basic"
          className="d-flex align-items-center gap-1 border-0 p-0"
        >
          <GlobeIcon
            style={{ width: 18, height: 18 }}
            className="d-sm-none d-block"
          />
          <GlobeIcon
            style={{ width: 24, height: 24 }}
            className="d-none d-sm-block"
          />
          {!showText && (
            <span className="fw-semibold text-uppercase d-block d-lg-none">
              {language}
            </span>
          )}
          {showText && (
            <>
              <p className="fs-5 fs-lg-6 text-dark d-block d-lg-none">
                {t('Cambiar idioma')}
              </p>
              <p className="fs-5 fs-lg-6 text-dark d-none d-lg-block fw-semibold">
                {t('Idioma')}
              </p>
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Header className="fw-semibold text-dark d-none d-lg-block">
            {t('Elegir idioma')}
          </Dropdown.Header>
          {Object.keys(resources || {}).map((k) => (
            <Dropdown.Item
              onClick={() => handleChangeLanguage(k)}
              key={k}
              className={`fs-4 fs-lg-6 py-2 py-lg-1 ${
                language === k ? 'fw-semibold' : null
              }`}
            >
              {lang[k]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    <select
      className={
        className ||
        'border-0 bg-transparent p-0 m-0 small fw-semibold me-2 me-sm-0 text-dark'
      }
      defaultValue={language.substring(0, 2)}
      onChange={(e) => handleChangeLanguage(e.target.value)}
      style={{ outline: 'none' }}
    >
      {Object.keys(resources || {}).map((k) => (
        <option
          value={k}
          key={k}
          className={`text-uppercase p-0 m-0 ${
            language === k ? 'fw-semibold' : null
          }`}
        >
          {lang[k]}
        </option>
      ))}
    </select>
  );
};

export default ChangeLanguage;
