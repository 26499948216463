import { Suspense, useEffect, lazy, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { WrapperTab } from './styles';
import Loading from 'shared/loading.component';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import EnvironmentIndicator, { ShowIn } from 'shared/environment-indicator';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { UserActions, useApp, useAppState } from 'context/global.context';
import { useMenu } from 'apiClient/api';
import { ReactComponent as GridIcon } from 'assets/list-plus.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron-forward.svg';
import { User } from '@meniu/data';
import qs from 'qs';

const MenuEditorWrapper = lazy(() => import('./editor/MenuEditorWrapper'));
const Orders = lazy(() => import('./orders/orders'));
const TerminalWrapper = lazy(() => import('../../terminal/terminal.page'));
const Configuration = lazy(() => import('./configuration/configuration'));
const Analytics = lazy(() => import('./analytics/analytics'));
const Payments = lazy(() => import('./payments/payments'));
const Delivery = lazy(() => import('./delivery/delivery'));
const Rooms = lazy(() => import('./tables/rooms'));
const Kitchen = lazy(() => import('./kitchen/kitchen'));
const ManagedUsers = lazy(() => import('./managed-users/managed-users'));

export enum TabEnum {
  'editor' = 'editor',
  'terminal' = 'terminal',
  'orders' = 'orders',
  'configuration' = 'configuration',
  'delivery' = 'delivery',
  'payments' = 'payments',
  'analytics' = 'analytics',
  'tables' = 'tables',
  'kitchen' = 'kitchen',
  'users' = 'users',
}

const Tab = ({
  name,
  currentTab,
  title,
  showIn,
  isNew,
  handleCloseLateral,
  isMobile,
  setCurrentTab,
}: {
  handleCloseLateral: () => void;
  name: TabEnum;
  currentTab: string;
  title: string;
  showIn?: ShowIn;
  isNew?: boolean;
  isMobile?: boolean;
  setCurrentTab: (a: TabEnum) => void;
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    handleCloseLateral();
    setCurrentTab(name);
  };
  const [{ hasSeen }, dispatch] = useApp();

  useEffect(() => {
    if (currentTab && isNew) {
      dispatch(UserActions.hasSeenNew(currentTab));
    }
  }, [currentTab]);

  return (
    <EnvironmentIndicator showIn={showIn}>
      <div
        className={clsx(
          'position-relative',
          name === currentTab
            ? 'tab-active fw-bold pointer text-nowrap rounded-2 p-2 lh-sm'
            : 'btn-link text-decoration-none text-dark hover-bg-dark-gray pointer text-nowrap p-1',
          isMobile &&
            'px-3 py-2 d-flex justify-content-between align-items-center'
        )}
        data-test={`${name}-btn`}
        onClick={handleClick}
      >
        <small>{t(title)}</small>
        {isNew && name !== currentTab && !hasSeen?.includes(currentTab) && (
          <div
            className="rounded-pill text-black border position-absolute top-0 end-0 fw-semibold bg-primary border-0 shadow-sm"
            style={{
              marginRight: '-4px',
              height: '10px',
              width: '10px',
            }}
          />
        )}

        <div>
          {isMobile && (
            <ChevronRightIcon style={{ minWidth: 24, height: 24 }} />
          )}
        </div>
      </div>
    </EnvironmentIndicator>
  );
};

export const tabs = [
  { name: TabEnum.editor, title: 'Menú' },
  {
    name: TabEnum.terminal,
    title: 'Vender',
  },
  { name: TabEnum.orders, title: 'Ordenes' },
  {
    name: TabEnum.configuration,
    title: 'Configuración',
  },
  // {
  //   name: TabEnum.delivery,
  //   title: 'Envíos'),
  //   showIn: 'development',
  // },
  { name: TabEnum.payments, title: 'Cobros' },
  { name: TabEnum.analytics, title: 'Reportes' },
  {
    name: TabEnum.tables,
    title: 'Mesas',
  },
  { name: TabEnum.kitchen, title: 'Cocina' },
  {
    name: TabEnum.users,
    title: 'Usuarios',
    isNew: true,
  },
];

export function MenuTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<TabEnum>(
    searchParams.get('tab') as TabEnum
  );

  const handleSetCurrentTab = (tab: TabEnum) => {
    const prevQuery = qs.parse(searchParams.toString()) as any;
    if (['terminal'].includes(tab)) {
      setSearchParams({ ...prevQuery, tab });
    } else {
      setSearchParams({ tab });
    }
    setCurrentTab(tab);
  };

  useEffect(() => {
    const spTab = searchParams.get('tab') as TabEnum;
    if (spTab && currentTab && spTab !== currentTab) {
      handleSetCurrentTab(spTab);
    }
  }, [searchParams.get('tab')]);

  const { t } = useTranslation();

  const { _menuId } = useParams();

  const [showLateral, setShowLateral] = useState(false);
  const handleToggleLateral = () => setShowLateral(!showLateral);
  const handleCloseLateral = () => setShowLateral(false);

  const { data: menu } = useMenu(
    { _id: _menuId },
    {
      enabled: !!_menuId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const lateralButton = document.getElementById(
        'lateralToggleButton'
      ) as HTMLElement | null;
      const lateralMenu = document.getElementById(
        'lateralMenu'
      ) as HTMLElement | null;

      if (
        lateralButton &&
        lateralMenu &&
        !lateralButton.contains(event.target as Node) &&
        !lateralMenu.contains(event.target as Node)
      ) {
        setShowLateral(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showLateral]);

  const { user } = useAppState();

  const menuUser = menu?.user as User;
  const isOwner = user?._id === menuUser?._id || user?._id === menuUser;

  const userRoles = (menu?.users?.find((u) => u._id === user._id)?.roles ||
    []) as Array<TabEnum>;

  const filteredTabs = isOwner
    ? tabs
    : tabs.filter((t) => userRoles.includes(t.name));

  const navigate = useNavigate();

  useEffect(() => {
    if (menu?._id && user?._id) {
      if (isOwner) {
        handleSetCurrentTab(currentTab || tabs[0].name);
      } else {
        const toChangeTab = currentTab || userRoles?.[0];
        if (filteredTabs.some((f) => f.name === toChangeTab)) {
          handleSetCurrentTab(toChangeTab);
        } else {
          navigate('/managed-menus', { replace: true });
        }
      }
    }
  }, [menu, user]);

  if (!menu) return null;

  return (
    <div
      className="border-top border-start bg-white h-100"
      style={{ borderTopLeftRadius: 16, minHeight: '100%' }}
    >
      <WrapperTab className="text-decoration-none">
        {menu?.blocked && (
          <Container
            className="bg-warning w-100  d-flex align-items-center justify-content-center gap-2"
            style={{ height: '40px' }}
            fluid
          >
            <span>
              {t('Este menú está suspendido porque no cumple con nuestros')}
            </span>
            <a
              href={`${process.env.NX_LANDING_URL_}/legal/terms-and-conditions`}
              target="_blank"
              className="text-dark fw-bold"
              rel="noreferrer"
            >
              {t('Términos y condiciones')}
            </a>
          </Container>
        )}
        <Container
          fluid
          className="position-relative d-flex align-items-center"
        >
          <Button
            // className="p-2 rounded-2 bg-white custom-shadow d-lg-none position-absolute border-0"
            id="lateralToggleButton"
            className="rounded-2 bg-white d-lg-none position-absolute border shadow-sm p-0"
            onClick={handleToggleLateral}
            style={{ zIndex: 999, width: 36, height: 36 }}
          >
            <GridIcon style={{ minWidth: 24, height: 24 }} />
          </Button>
          <div className="ms-4 ms-lg-0 d-flex align-items-center gap-2 overflow-scroll p-2 ps-4 p-lg-2 pt-lg-4">
            {filteredTabs.map((props) => (
              <Tab
                key={props.name}
                handleCloseLateral={handleCloseLateral}
                {...props}
                currentTab={currentTab}
                setCurrentTab={handleSetCurrentTab}
              />
            ))}
          </div>
        </Container>
      </WrapperTab>
      <div>
        {showLateral && (
          <div
            id="lateralMenu"
            className="bg-white position-fixed d-sm-none custom-shadow rounded-3 bottom-0 w-100 border-top pb-3"
            style={{ zIndex: 10000 }}
          >
            <div className="d-flex flex-column gap-2 px-3 pt-3 pb-2 fw-bold">
              {t('Navega por las secciones')}
              <div className="border-bottom w-100"></div>
            </div>
            {filteredTabs.map((props) => (
              <Tab
                isMobile={true}
                key={props.name}
                handleCloseLateral={handleCloseLateral}
                {...props}
                currentTab={currentTab}
                setCurrentTab={handleSetCurrentTab}
              />
            ))}
          </div>
        )}
        {
          {
            [TabEnum.editor]: (
              <Suspense fallback={<Loading />}>
                <MenuEditorWrapper menu={menu} />
              </Suspense>
            ),
            [TabEnum.configuration]: (
              <Suspense fallback={<Loading />}>
                <Configuration menu={menu} />
              </Suspense>
            ),
            [TabEnum.terminal]: (
              <Suspense fallback={<Loading />}>
                <TerminalWrapper menu={menu} />
              </Suspense>
            ),
            [TabEnum.orders]: (
              <Suspense fallback={<Loading />}>
                <Orders menu={menu} />
              </Suspense>
            ),
            [TabEnum.analytics]: (
              <Suspense fallback={<Loading />}>
                <Analytics menu={menu} />
              </Suspense>
            ),
            [TabEnum.payments]: (
              <Suspense fallback={<Loading />}>
                <Payments menu={menu} />
              </Suspense>
            ),
            [TabEnum.delivery]: (
              <Suspense fallback={<Loading />}>
                <Delivery />
              </Suspense>
            ),
            [TabEnum.tables]: (
              <Suspense fallback={<Loading />}>
                <Rooms menu={menu} />
              </Suspense>
            ),
            [TabEnum.kitchen]: (
              <Suspense fallback={<Loading />}>
                <Kitchen menu={menu} />
              </Suspense>
            ),
            [TabEnum.users]: (
              <Suspense fallback={<Loading />}>
                <ManagedUsers menu={menu} />
              </Suspense>
            ),
            // clients: (
            //   <Suspense>
            //     <Clients menuId={_menuId || ''} />
            //   </Suspense>
            // ),
          }[currentTab]
        }
      </div>
    </div>
  );
}
